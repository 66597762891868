import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../service/api-server.service';
import { DataService } from '../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as myapi from '../../service/baseLink';
import { Helper } from '../../_model/helper';
import { LoaderService } from '../../service/loader.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-competition-final',
  templateUrl: './competition-final.component.html',
  styleUrls: ['./competition-final.component.css']
})
export class CompetitionFinalComponent implements OnInit {
  serverLink: any = myapi.BaseUrl;
  myres: any;
  state: any;
  examCentre: any;
  examType = ''
  fulldata: any;
  CandidateData = [];
  class: any;
  page = 1;
  file = '../../../assets/img/student.png';
  rForm: FormGroup;
  regionId: any; // region id which is going to pass in state
  countryId: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      state: ['', Validators.required],
      examCentre: ['', Validators.required]
    });
    this.getExamType()
    this.regionId = localStorage.getItem('RegionId');
    this.countryId = localStorage.getItem('CountryId');
    this.getState();
    this.data.currentMessage.subscribe(message => (this.class = message));
    this.data.currentMessage2.subscribe(message => (this.fulldata = message));
    if (this.fulldata !== undefined) {
      this.fulldata.forEach(element => {
        element.image = this.serverLink + element.Picture;
        element.image = this.sanitizer.bypassSecurityTrustStyle(`url(${element.image})`);
      });
      for (const item of this.fulldata) {
        const data = {
          Id: item.Id,
          ClassId: item.ClassId,
          Gender: item.Gender
        };
        this.CandidateData.push(data);
      }
    } else {
      this.router.navigate(['/school/Competition-initial']);
    }
  }

  getState() {
    this.loginService.getStateSchool().subscribe(
      (res: any) => {
        this.state = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  getExamCentre() {
    if (this.state !== undefined) {
      const a = this.rForm.get('state').value;
      const data = `${a}`;
      this.loginService.getExamCentre(data).subscribe(
        (res: any) => {
          if (res.length === 0) {
            this.alert.error('No Exam-Centre Found');
          } else {
            this.alert.success('Select Exam Centre');
          }
          this.examCentre = res;
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
  getDevice(){
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
  			return 'Mobile';
		} else {
			return 'Desktop';
		}
  }

  SendData(data) {
    if (!data.valid) {
      this.alert.error('fill the form correctly', true);
    } else {
      let examCentreId = this.rForm.get('examCentre').value;
      // var url = `${examCentreId}&clas=${this.class}`;
      let CaptureDevice = this.getDevice();
      this.loaderService.show();
      debugger;
      if(this.examType === 'Online'){
        examCentreId=0;
      }
      this.loginService.registerForCompetition(this.CandidateData, examCentreId, CaptureDevice).subscribe(
          (res: any) => {
            this.loaderService.hide();
            this.alert.success('Successfully Register', true);
            this.router.navigate(['/school/examination-slip']);
          },
          error => {
            this.loaderService.hide();
            this.alert.error(Helper.getMessageFromError(error), true);
          }
        );
    }
  }

  getExamType(){
    this.loginService.getExamTypeApi().subscribe((res: any) => {
      this.examType = res
      if(this.examType === 'Online'){
        this.rForm.get('state').clearValidators()
        this.rForm.get('state').updateValueAndValidity()
        this.rForm.get('examCentre').clearValidators()
        this.rForm.get('examCentre').updateValueAndValidity()
      }else {
        this.rForm.get('state').setValidators([Validators.required])
        this.rForm.get('state').updateValueAndValidity()
        this.rForm.get('examCentre').setValidators([Validators.required])
        this.rForm.get('examCentre').updateValueAndValidity()
      }
    },
    
    (err: any) => {

    })
  }
}
