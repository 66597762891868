import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AlertService } from 'src/app/_service/alert.service';
import { ApiServerService } from 'src/app/service/api-server.service';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';
import { Helper } from '../../_model/helper';

@Component({
  selector: 'app-state-drop-down',
  templateUrl: './state-drop-down.component.html',
  styleUrls: ['./state-drop-down.component.css']
})
export class StateDropDownComponent implements OnInit, OnChanges {
  @Input() countrySelected: any;
  public state: any;
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<any> = new EventEmitter();
  val = '';
  constructor(
    private loginService: ApiServerService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.LoadStates();
  }

  LoadStates(): any {
    this.state = [];
    if (this.field.Filter) {
      this.loginService.getState(this.field.Filter).subscribe(
        (res: any) => {
          this.state = res;
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    } else {
      this.state = [];
    }
  }

  stateChange(event, type) {
    const value = {
      FieldName: this.field.FieldName,
      Value: event.target.value,
      EventType: type
    };
    this.Changed.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.LoadStates();
  }

  Reset() {
    this.val = '';
    this.field.Filter = null;
    this.LoadStates();
  }
}
