import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../../service/baseLink";
import { Helper } from "../../../_model/helper";
import { LoaderService } from "../../../service/loader.service";
@Component({
  selector: "app-partial-migrate",
  templateUrl: "./partial-migrate.component.html",
  styleUrls: ["./partial-migrate.component.css"]
})
export class PartialMigrateComponent implements OnInit {
  list: any;
  array = [];
  centre: any;
  toMigrate: any = undefined;
  state: any;
  selectStateId: any;
  rForm: FormGroup;
  examCentreSelected: boolean = false;
  countryId: any; //sore the coutry id value
  regionId: any; //store region id value
  Country: any; //list of country
  Region: any; //list of region
  //For pagination purpose
  Skip: number = 0;
  Take: number = 10;
  TotalRecords: number;
  TotalPages: number;
  totalRowPerPage: number = 10;
  FromMigrate : any;

  recordStarting: number;
  recordEnding: number;
  candidate: any;
  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      CountryId: ["", Validators.required],
      RegionId: ["", Validators.required],
      state: ["", Validators.required],
      fromCenter: ["", Validators.required],
      toCenter: ["", Validators.required]

      
    });

    // var dataPage = { Skip: this.Skip, Take: this.Take ,Register:true};
    // this.loginService.getAllCandidate(dataPage).subscribe(
    //   (res:any)=>{
    //     if(res ==null) 
    //     {
    //       this.alert.error("No record found",true);
    //     return;
    //     }
    //     this.list = res;
    //     this.TotalRecords = res.TotalRecords;
    //     this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
    //     this.recordStarting = 1;
    //     if (this.TotalRecords < this.Take) {
    //       this.recordEnding = this.TotalRecords;
    //     } else {
    //       this.recordEnding = this.Take;
    //     }
    //   },
    //   error => {
    //     this.alert.error(Helper.getMessageFromError(error), true);
    //   }
    // );
    this.getAllRegion();
  }
  // refresh() {
  //   var dataPage = { Skip: this.Skip, Take: this.Take,Register:true };
  //   this.loginService.getAllCandidate(dataPage).subscribe(
  //     (res:any)=>{
  //       this.list = res;
  //       this.TotalRecords = res.TotalRecords;
  //       this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
  //     },
  //     error => {
  //       this.alert.error(Helper.getMessageFromError(error), true);
  //     }
  //   );
  // }

  //called after each migration time
  // allCandidateList() {
  //   var dataPage = { Skip: 0, Take: this.Take};
  //   this.loginService.getAllCandidate(dataPage).subscribe(
  //     (res:any)=>{

  //       if(res== null) 
  //       return 0;
  //       this.list = res;
  //       this.TotalRecords = res.TotalRecords;
  //       this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
  //       this.recordStarting = 1;
  //       this.recordEnding = this.Take;
  //       if (this.TotalRecords < this.Take) {
  //         this.recordEnding = this.TotalRecords;
  //       } else {
  //         this.recordEnding = this.Skip;
  //       }
  //     },
  //     error => {
  //       this.alert.error(Helper.getMessageFromError(error), true);
  //     }
  //   );
  // }

  addCandidate(e, id) {
    if (e.target.checked) {
      this.array.push(id);
    } else {
      var j = this.array.indexOf(id);
      if (j > -1) {
        this.array.splice(j, 1);
      }
    }
  }

  //to migrate
  migrated() {
    if (
      this.toMigrate == "centre" ||
      this.toMigrate == undefined ||
      this.migrated == null
    ) {
      this.alert.error("Select the exam centre");
    } else if (this.array.length == 0) {
      this.alert.error("Selecte the candidate", true);
    } else {
      this.performMigration();
    }
  }

  //get all exam centre
  examCentre(id) {
    this.loginService.examCentreIdwise(id).subscribe(
      (res:any)=>{
        if (res.length == 0) {
          this.examCentreSelected = false;
          this.alert.error("No Exam Centre Found", true);
          this.centre = undefined;
        } else {
          this.centre = res;
          this.examCentreSelected = true;
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  //exam centre event handler
  FromcentreSelected(value) {
    this.FromMigrate = value;
    this.rForm.get("toCenter").setValue("");
    this.getRegisteredCandidate(this.FromMigrate);
  }

  centreSelected(value) {
    this.toMigrate = value;
  }

  getRegisteredCandidate(value){
    var dataPage = { Skip: 0, Take: this.Take};
    this.loginService.getRegisteredCandidate(dataPage,value).subscribe(
      (res:any)=>{
        this.candidate = res.List;
        this.TotalRecords = res.TotalRecords;
        this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
        this.recordStarting = 1;
        this.recordEnding = this.Take;
        if (this.TotalRecords < this.Take) {
          this.recordEnding = this.TotalRecords;
        } else {
          this.recordEnding = this.Skip;
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  //get all state
  getState(countryId) {
    this.loginService.getallState(countryId).subscribe(
      (res:any)=>{
        this.state = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  // state select event handler
  stateSelected(e) {
    this.selectStateId = e;
    this.rForm.get("fromCenter").setValue("");
    this.examCentre(this.selectStateId);
    // this.refresh();
  }

  performMigration() {
    this.loaderService.show();
    this.loginService.partiallyMigrate(this.array, this.toMigrate).subscribe(
      (res:any)=>{
        this.alert.success("Migrated", true);
    this.loaderService.hide();

        // this.refresh();
      },
      error => {
    this.loaderService.hide();

        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

 
  //country selected event listener
  countrySelected(e) {
    this.countryId = e.target.value;
    this.getState(this.countryId);
  }
  //region selected event listener
  regionSelected(e) {
    this.regionId = e.target.value;
    this.getAllCountry(this.regionId);
  }
  //country list
  getAllCountry(regionId) {
    this.loginService.getallCountry(regionId).subscribe(
      (res:any)=>{
        this.Country = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  //region list
  getAllRegion() {
    this.loginService.getAllRegions().subscribe(
      (res:any)=>{
        this.Region = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  RowPerPage(e) {
    this.totalRowPerPage = Number(e.target.value);
    this.Take = this.totalRowPerPage;
    this.Skip = 0;
    this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
    //page number
   // this.recordStarting = this.Skip;
      if (this.Skip == 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }
    var totalrecord = this.Skip + this.Take;
    if (totalrecord == this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
  }

  firstPage() {
    this.Skip = 0;
    this.Take = this.totalRowPerPage;
    this.recordStarting = 1;
    if (this.TotalRecords < this.Take) {
      this.recordEnding = this.TotalRecords;
    } else {
      this.recordEnding = this.Take;
    }
    this.getRegisteredCandidate(this.FromMigrate);
  }

  nextPage() {
    
    if (this.recordEnding < this.TotalRecords) {
      this.Skip += this.totalRowPerPage;
      this.Take = this.totalRowPerPage;
      //for page number
      if (this.Skip == 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip +1;
      }
      var totalrecord = this.Skip + this.Take;

      if (totalrecord == this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }
      this.getRegisteredCandidate(this.FromMigrate);

    }
  }

  priviousPage() {
    if (this.recordStarting > 1) {
      this.Skip -= this.totalRowPerPage;
      this.Take = this.totalRowPerPage;

      //for page number
      //for page number
      if (this.Skip == 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip +1;
      }

      var totalrecord = this.Skip + this.Take;

      if (totalrecord == this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }
      this.getRegisteredCandidate(this.FromMigrate);

    }
  }
  lastPage() {
    var lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
    this.Skip = lastPage;
    this.Take = this.totalRowPerPage;

    //for the page number
    if (this.Skip == 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip +1;
    }

    var totalrecord = this.Skip + this.Take;

    if (totalrecord == this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.getRegisteredCandidate(this.FromMigrate);
  }
}


