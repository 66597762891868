import { Component, OnInit, Input } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as myapi from '../../../service/baseLink';
import { Helper } from '../../../_model/helper';
import { FilterChangeModel } from '../../../_model/filter-change-model';
import {
  FilterFieldModel,
  FieldType
} from '../../../_model/filter-field-model';
import {
  SchoolFilterModel,
  SchoolFilterResultModel
} from '../../../_model/school-models';
import {
  GridColumnType,
  PropertyMetaModel,
  PaginationModel
} from 'src/app/_model/pagination-model';
import { LoaderService } from '../../../service/loader.service';
@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.css']
})
export class SchoolListComponent implements OnInit {
  filter: SchoolFilterModel;
  schools: SchoolFilterResultModel = new SchoolFilterResultModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public filterData: FilterChangeModel[] = new Array<FilterChangeModel>();
  public displayColumns: PropertyMetaModel[];
  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    this.Fields = [
      {
        FieldName: 'Name',
        Label: 'Name',
        Placeholder: 'Please enter school name',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'Email',
        Label: 'Email',
        Placeholder: 'Please enter school email',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'Type',
        Label: 'School Type',
        Placeholder: 'Select School Type',
        FieldType: FieldType.SchoolTypeDropdown
      },
      {
        FieldName: 'Category',
        Label: 'Category',
        Placeholder: 'Select Category',
        FieldType: FieldType.SchoolCategoryDropdown
      },
      {
        FieldName: 'RegionId',
        Label: 'Regions',
        Placeholder: 'Select Region',
        FieldType: FieldType.RegionDropdown
      },
      {
        FieldName: 'CountryId',
        Label: 'Country',
        Placeholder: 'Select Country',
        FieldType: FieldType.CountryDropdown,
        Filter: null
      },
      {
        FieldName: 'StateId',
        Label: 'State',
        Placeholder: 'Select State',
        FieldType: FieldType.StateDropdown,
        Filter: null
      }
    ];
    this.displayColumns = [
      // { PropertyName: "link", ColumnName: "Name",
      // PropertyType: GridColumnType.DisplayLink, DisplayText: "Name", IsSortable: true, ClassName: "mylink mypointer" },
      {
        PropertyName: 'Name',
        ColumnName: 'Name',
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: 'Email',
        ColumnName: 'Email',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'PhoneNumber',
        ColumnName: 'Mobile Number',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'PrimaryContactName',
        ColumnName: 'Principal Name',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'PrimaryContactNumber',
        ColumnName: 'Principal Number',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Type',
        ColumnName: 'Type',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Category',
        ColumnName: 'Category',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Region',
        ColumnName: 'Region',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Country',
        ColumnName: 'Country',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'State',
        ColumnName: 'State',
        PropertyType: GridColumnType.Text
      },
      // { PropertyName: "Id", ColumnName: "Delete",
      // PropertyType: GridColumnType.Icon, DisplayText: "Delete", ClassName: "mypointer" },
      {
        PropertyName: 'Id',
        ColumnName: 'Edit',
        PropertyType: GridColumnType.Icon,
        DisplayText: 'Edit',
        ClassName: 'mypointer'
      }
      // { PropertyName: "id", ColumnName: "",
      //  PropertyType: GridColumnType.Action, DisplayText:  }
    ];
  }

  ngOnInit() {
    this.filter = new SchoolFilterModel();
    this.loadSchools();
  }

  filterChange(event: any) {
    this.filter = new SchoolFilterModel();
    event.forEach(element => {
      this.filter[element.FieldName] = element.Value || null;
    });
    this.loadSchools();
  }

  loadSchools(): any {
    this.loaderService.show();
    this.schools = new SchoolFilterResultModel();
    this.loginService.getAllSchoolData(this.filter).subscribe(
      (res: any) => {
        this.schools.List = res.List;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.filter.Take;
        this.loaderService.hide();
      },
      error => {
        this.loaderService.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  pageInfoChange(event) {
    this.filter.OrderBy = event.SortBy;
    this.filter.Order = event.SortOrder;
    this.filter.Skip = (event.CurrentPage - 1) * event.PageSize;
    this.filter.Take = event.PageSize;
    this.loadSchools();
  }

  action(event) {
    if (event.ColumnName === 'Edit') {
      const cUrl = document.location.href;
      let urlaparam = '';
      if (cUrl.includes('super')) {
        urlaparam = 'super-admin';
      } else {
        urlaparam = 'admin';
      }
     this.data.changeMessage4(this.schools.List[event.index]);
     const url = '/' + urlaparam + `/edit-School?uid=${event.value.Id}`;
     this.router.navigateByUrl(url);
    }
  }
}
