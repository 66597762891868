import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ExcelService } from '../../../_model/exceldownload.helper';
import { Helper } from '../../../_model/helper';
import { PropertyMetaModel, PaginationModel, GridColumnType } from 'src/app/_model/pagination-model';
import { FilterFieldModel, FieldType } from 'src/app/_model/filter-field-model';
import { LoaderService } from '../../../service/loader.service';
import { pdfPotrait } from '../../../_model/pdfDownloadHelper';
@Component({
  selector: 'app-centre-candidate-schedule',
  templateUrl: './centre-candidate-schedule.component.html',
  styleUrls: ['./centre-candidate-schedule.component.css']
})
export class CentreCandidateScheduleComponent implements OnInit {
  public displayColumns: PropertyMetaModel[];
  public filter: any = {};
  public pageInfo = new PaginationModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public gridData: any[] = [];
  public pdfColumns = [
    { title: "Name", dataKey: "Name" },
    { title: "Gender", dataKey: "Gender" },
    { title: "Exam Center Name", dataKey: "ExamCenterName" },
    { title: "School Name", dataKey: "SchoolName" },
    { title: "Registration Number", dataKey: "RegistrationNumber" },
    { title: "Attendance", dataKey: "Attendance" }

  ]

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService,
    private loaderService: LoaderService) {
      this.displayColumns = [
        {
          PropertyName: 'Name',
          ColumnName: 'Candidate Name',
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: 'SchoolName',
          ColumnName: 'School Name',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'Gender',
          ColumnName: 'Gender',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'ExamCenterName',
          ColumnName: 'Exam Center Name',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'RegistrationNumber',
          ColumnName: 'Registration Number',
          PropertyType: GridColumnType.Text
        }
      ];
    this.Fields = [
      {
        FieldName: 'ExamCenterId',
        Label: 'Exam Center',
        Placeholder: 'Please select a region',
        FieldType: FieldType.ExamCenterDropdown
      },
      {
        FieldName: 'Name',
        Label: 'Candidate Name',
        Placeholder: 'Candidate Name',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'SchoolName',
        Label: 'School Name',
        Placeholder: 'Please school name',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'RegistrationNumber',
        Label: 'Registration Number',
        Placeholder: 'Registration number',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'Gender',
        Label: 'Gender',
        Placeholder: 'Please select a region',
        FieldType: FieldType.SchoolTypeDropdown
      }
      // {
      //   FieldName: 'Email',
      //   Label: 'Email',
      //   Placeholder: 'Please enter email',
      //   FieldType: FieldType.Text
      // }
    ];
  }

  ngOnInit() {
    this.getAllCadidateReport();
  }

  getAllCadidateReport(): any {
    this.gridData = [];
    const page: any = {};
    page.Skip = (this.pageInfo.CurrentPage - 1) * this.pageInfo.PageSize;
    page.Take = this.pageInfo.PageSize;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loaderService.show()
    this.loginService.getExamCenterCandidateReport(page, this.filter).subscribe(
      (res: any) => {
        this.gridData = res.List;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.pageInfo.PageSize;
        this.loaderService.hide();
      },
      error => {
        this.loaderService.hide();
       }
    );
  }

  action(event: any) {
  }

  pageInfoChange(event: PaginationModel) {    
    this.pageInfo.CurrentPage = event.CurrentPage;
    this.pageInfo.PageSize = event.PageSize;
    this.pageInfo.SortOrder = event.SortOrder;
    this.pageInfo.SortOrder = event.SortOrder;
    this.getAllCadidateReport();
  }

  filterChange(event: any) {
   
    if (event && event.length > 0) {
      event.forEach(element => {
        this.filter[element.FieldName] = element.Value;
      });
    } else {
      this.filter = {};
    }
    this.pageInfo.CurrentPage = 1;
    this.getAllCadidateReport();
  }

  download() {
    const page: any = {};
    page.Skip = 0;
    page.Take = 1000;
    this.filter.Take = 1000;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loginService.getExamCenterCandidateReport(page, this.filter).subscribe(
      (res: any) => {
        this.downloadFile(res.List);
      },
      error => { }
    );
  }

  downloadFile(data: any) {
    this.excelService.exportAsExcelFile(data, 'Exam Center Candidate Report:');
  }

  downloadPDF() {
    const page: any = {};
    page.Skip = 0;
    page.Take = 1000000;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loginService.getExamCenterCandidateReport(page, this.filter).subscribe(
      (res: any) => {
        if(res){
          pdfPotrait.exportPdfportrait(res, "ExamCenterCandidateReport:", this.pdfColumns, 'Exam Center Candidate Report:', 'landscape');
        }
      },
      error => { }
    );
  }

}
