import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router } from "@angular/router";
import { Helper } from "../../_model/helper";
@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.css"]
})
export class SettingComponent implements OnInit {
  rForm: FormGroup;
  adminData: any;
  patt: string = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$";
  pattphone: string = "[0-9]*";
  pattname: string = "[a-zA-Z ]*";
  disabled: boolean = true;
  SettingChanged: boolean = false;
  loader: boolean = false;

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      Smtp: [{ value: "", disabled: this.disabled }, Validators.required],

      Email: [
        { value: "", disabled: this.disabled },
        Validators.compose([Validators.required, Validators.pattern(this.patt)])
      ],

      Password: [{ value: "", disabled: this.disabled }, Validators.required],
      PhonePrefix: [{ value: "", disabled: this.disabled }, Validators.required],
      PhoneVerificationUrl: [{ value: "", disabled: this.disabled }, Validators.required],
      PhoneAccessKey: [{ value: "", disabled: this.disabled }, Validators.required],
      PhoneCountryCode: [{ value: "", disabled: this.disabled }, Validators.required],

      Port: [
        { value: "", disabled: this.disabled },
        Validators.compose([
          Validators.required,
          Validators.pattern(this.pattphone)
        ])
      ]
    });
     this.getSmtpSettingData();
  }
  getSmtpSettingData() {
    this.loginService.getSetting().subscribe(
      (res:any)=>{
        this.adminData = res;
        this.rForm.get("Smtp").setValue(this.adminData.Smtp);
        this.rForm.get("Email").setValue(this.adminData.Email);
        this.rForm.get("Password").setValue(this.adminData.Password);
        this.rForm.get("Port").setValue(this.adminData.Port);
        this.rForm.get("Smtp").disable();
        this.rForm.get("Email").disable();
        this.rForm.get("Password").disable();
        this.rForm.get("Port").disable();
        this.rForm.get("PhonePrefix").setValue(this.adminData.PhonePrefix);
        this.rForm.get("PhoneVerificationUrl").setValue(this.adminData.PhoneVerificationUrl);
        this.rForm.get("PhoneAccessKey").setValue(this.adminData.PhoneAccessKey);
        this.rForm.get("PhoneCountryCode").setValue(this.adminData.PhoneCountryCode);
        this.rForm.get("PhonePrefix").disable();
        this.rForm.get("PhoneVerificationUrl").disable();
        this.rForm.get("PhoneAccessKey").disable();
        this.rForm.get("PhoneCountryCode").disable();
        this.disabled = true;
        this.loader = false;
        this.adminData = Array.of(this.adminData); //convert the objects into the array that can read by *ngFor
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  SendData(form) {
    if (!form.valid) {
      this.alert.error("fill the form correctly", true);
    } else {
      this.disabled = true;
      this.changeSmtpSettingData(form.value);
      this.SettingChanged = true;
    }
  }

  //to change the setting
  changeSmtpSettingData(data) {
    this.loginService.changeSetting(data).subscribe(
      (res:any)=>{
        this.loader = true;
        this.alert.success("Setting changed succesfully", true);
        this.getSmtpSettingData();
        this.rForm.reset();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  change() {
    this.disabled = false;
    this.rForm.get("Smtp").enable();
    this.rForm.get("Email").enable();
    this.rForm.get("Password").enable();
    this.rForm.get("Port").enable();
    this.rForm.get("PhonePrefix").enable();
    this.rForm.get("PhoneVerificationUrl").enable();
    this.rForm.get("PhoneAccessKey").enable();
    this.rForm.get("PhoneCountryCode").enable();
  }
}
