import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DataService {
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  // and have to display to the 2nd step
  private messageSource2 = new BehaviorSubject(undefined);
  currentMessage2 = this.messageSource2.asObservable();
   // for the school profile data.
  private messageSource3 = new BehaviorSubject(undefined);
  currentMessage3 = this.messageSource3.asObservable();
  // for the candidate profile data.
  private messageSource4 = new BehaviorSubject(undefined);
  currentMessage4 = this.messageSource4.asObservable();
  // for the authority profile data in super admin.
  private messageSource5 = new BehaviorSubject(undefined);
  currentMessage5 = this.messageSource5.asObservable();

  private messageSource6 = new Subject<any>();
  currentMessage6 = this.messageSource6.asObservable();

  constructor() {}

  sendMessage(message: any) {
    this.messageSource6.next({countryId: message});
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeMessage2(message: any) {
    this.messageSource2.next(message);
  }
   changeMessage3(message: any) {
    this.messageSource3.next(message);
  }

  changeMessage4(message: any) {
    this.messageSource4.next(message);
  }

  changeMessage5(message: any) {
    this.messageSource5.next(message);
  }
}

// 1) for configuring the message service just copy this file and put in provide in module.

// 2) for using it just import it to any component and use that in constructor

// 3)for sending message
//  this.data.changeMessage(res);

//  4) for reciving message
//   this.data.currentMessage.subscribe(message => this.message = message);
