import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './home/login/login.component';
import { RegistrationComponent } from './home/registration/registration.component';
import { ForgetPasswordComponent } from './home/forget-password/forget-password.component';
import { ChangePasswordComponent } from './home/change-password/change-password.component';
import { ExaminerComponent } from './examiner/examiner/examiner.component';
import { HomePageComponent } from './home/homeScreen/home-page/home-page.component';
import { GuidelinesComponent } from './home/homeScreen/guidelines/guidelines.component';
import { FaqComponent } from './home/homeScreen/faq/faq.component';
import { HomeContactUsComponent } from './home/homeScreen/home-contact-us/home-contact-us.component';
import { SyllabusComponent } from 'src/app/home/homeScreen/syllabus/syllabus.component';
import { PageNotFoundComponent } from 'src/app/home/page-not-found/page-not-found.component';
import { GuardExaminerService } from 'src/app/examiner/authguard/guard-examiner.service';
import { CandidateResultComponent } from 'src/app/home/result/candidate-result/candidate-result.component';
import { SchoolResultComponent } from 'src/app/home/result/school-result/school-result.component';
import { ResultReportComponent } from 'src/app/home/result/result-report/result-report.component';
import { FiltersComponent } from './_shared/filters/filters.component';
import { CountryComponent } from './home/country/country.component';
import { TnCComponent } from './home/homeScreen/tn-c/tn-c.component';
import { RegSchoolsComponent } from './home/reg-schools/reg-schools.component';
import { NewsComponent } from './home/homeScreen/news/news.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/country?cid=NDA0Mg==&rId=22', pathMatch: 'full' },
  { path: '', component: HomePageComponent },
  { path: 'login', component: LoginComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'modify-password/:id', component: ForgetPasswordComponent },
  { path: 'change-password/:id', component: ChangePasswordComponent },
  { path: 'country?cid=NDA0Mg%3D%3D', component: HomePageComponent },
  { path: 'guidelines', component: GuidelinesComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact-us', component: HomeContactUsComponent },
  { path: 'syllabus', component: SyllabusComponent },
  { path: 'result', component: CandidateResultComponent },
  { path: 'top-candidates', component: SchoolResultComponent },
  { path: 'result-report', component: ResultReportComponent},
  { path: 'filter', component: FiltersComponent},
  { path: 'country', component: CountryComponent},
  { path: 'terms-n-conditions', component: TnCComponent },
  { path: 'news', component: NewsComponent },
  { path: 'registered-schools', component: RegSchoolsComponent},
  //  go to School
  { path: 'school', loadChildren: 'src/app/school/school.module#SchoolModule' },
  // go to super-admin
  {
    path: 'super-admin',
    loadChildren: 'src/app/superAdmin/super-admin.module#SuperAdminModule'
  },
  // go to admin
  { path: 'admin', loadChildren: 'src/app/admin/admin.module#AdminModule' },
  // examiner link
  {
    path: 'examiner',
    component: ExaminerComponent,
    canActivate: [GuardExaminerService]
  },
  { path: '**', component: PageNotFoundComponent }
];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(
  routes
);
