import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as myapi from '../../../service/baseLink';
import { Helper } from '../../../_model/helper';
import { GridColumnType, PropertyMetaModel, PaginationModel } from 'src/app/_model/pagination-model';
import { FilterChangeModel } from '../../../_model/filter-change-model';
import { FilterFieldModel, FieldType } from '../../../_model/filter-field-model';
import { candidateFilterModel, candidateFilterResultModel } from '../../../_model/candidate-models';
import { LoaderService } from '../../../service/loader.service';
declare var $: any;

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.css']
})
export class CandidateListComponent implements OnInit {
  filter: candidateFilterModel;
  candidates: candidateFilterResultModel = new candidateFilterResultModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public filterData: FilterChangeModel[] = new Array<FilterChangeModel>();
  public displayColumns: PropertyMetaModel[];
  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };
  deleteCandidateId: any;

  // For pagination purpose
  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    this.Fields = [
      { FieldName: 'Name', Label: 'Name', Placeholder: 'Please enter Candidate name', FieldType: FieldType.Text },
      { FieldName: 'Email', Label: 'Email', Placeholder: 'Please enter Candidate email', FieldType: FieldType.Text },
      { FieldName: 'Gender', Label: 'Gender', Placeholder: 'Select Gender', FieldType: FieldType.SchoolTypeDropdown },
      // { FieldName: 'Category', Label: 'Category', Placeholder: 'Select Gender', FieldType: FieldType.SchoolCategoryDropdown },
      { FieldName: 'RegionId', Label: 'Regions', Placeholder: 'Select Region', FieldType: FieldType.RegionDropdown },
      { FieldName: 'CountryId', Label: 'Country', Placeholder: 'Select Country', FieldType: FieldType.CountryDropdown, Filter: null },
      { FieldName: 'StateId', Label: 'State', Placeholder: 'Select State', FieldType: FieldType.StateDropdown, Filter: null },
      
    ];
    this.displayColumns = [
      { PropertyName: 'Name', ColumnName: 'Name', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'Gender', ColumnName: 'Gender', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'School', ColumnName: 'School Name', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'DOB', ColumnName: 'Date Of Birth', PropertyType: GridColumnType.Text },
      { PropertyName: 'GuardianEmail', ColumnName: 'Guardian Email', PropertyType: GridColumnType.Text},
      { PropertyName: 'State', ColumnName: 'State', PropertyType: GridColumnType.Text, IsSortable: true },
      // { PropertyName: "Category", ColumnName: "Category", PropertyType: GridColumnType.Text, IsSortable: true },
      // { PropertyName: "Region", ColumnName: "Region", PropertyType: GridColumnType.Text, IsSortable: true },
      // { PropertyName: "Country", ColumnName: "Country", PropertyType: GridColumnType.Text, IsSortable: true },
      // { PropertyName: "State", ColumnName: "State", PropertyType: GridColumnType.Text, IsSortable: true },
       { PropertyName: 'Id', ColumnName: 'Delete', PropertyType: GridColumnType.Icon, DisplayText: 'Delete', ClassName: 'mypointer' },
      { PropertyName: 'Id', ColumnName: 'Edit', PropertyType: GridColumnType.Icon, DisplayText: 'Edit', ClassName: 'mypointer' },
    ];
  }

  ngOnInit() {
    this.filter = new candidateFilterModel();
    this.getAllCandidate();
  }

  filterChange(event: any) {
    this.filter = new candidateFilterModel();
    event.forEach(element => {
      this.filter[element.FieldName] = element.Value || null;
    });
    this.getAllCandidate();
  }

  getAllCandidate() {
    this.loaderService.show();
    this.loginService.getAllCandidateAsAdmin(this.filter).subscribe(
      (res: any) => {
        this.candidates.List = res.List;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.filter.Take;
        this.loaderService.hide();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );

  }

  pageInfoChange(event) {
    this.filter.OrderBy = event.SortBy;
    this.filter.Order = event.SortOrder;
    this.filter.Skip = (event.CurrentPage - 1) * 10;
    this.filter.Take = event.PageSize;
    this.getAllCandidate();
  }

  action(event) {
    if (event.ColumnName === 'Edit') {
      this.data.changeMessage4(this.candidates.List[event.index]);
        var cUrl = document.location.href;
        var urlaparam = '';
        if(cUrl.includes('super')){
          urlaparam = 'super-admin';
        }else{
          urlaparam = 'admin'
        }
        const url = "/" + urlaparam +`/candidates-update?uid=${event.value.Id}`;
         this.router.navigateByUrl(url);
    }
    if (event.ColumnName === 'Delete') {
      this.deleteCandidateId = event.value.Id;
      $('#deleteModel').modal('show');
    }
  }
  deleteCandidate(): any {
    this.loginService.deleteCandidateAsAdmin(this.deleteCandidateId).subscribe(res => {
      this.alert.success('Deleted Successfully');
      $('#deleteModel').modal('hide');
      this.getAllCandidate();
    }, err => {
      this.alert.error(Helper.getMessageFromError(err), true);
    });
  }
}

  // refresh() {
  //   var dataPage = { Skip: this.Skip, Take: this.Take,Gender: this.categoryType,
  // Filter: this.keyword, RegistrationDate: this.registrationFrom, RegDateFilter:
  // this.registrationTo ,SortOrder: this.sortOrder, SortBy: this.sortByName};
  //   this.loginService.getAllCandidate(dataPage).subscribe(
  //     (res:any)=>{
  //       this.list = res;
  //       this.TotalRecords = res.TotalRecords;
  //       this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
  //     },
  //     error => {
  //       this.alert.error(Helper.getMessageFromError(error), true);
  //     }
  //   );
  // }
  // isNumberKey(evt)
  //     {
  //        var charCode = (evt.which) ? evt.which : evt.keyCode;
  //        if (charCode > 31 && (charCode < 48 || charCode > 57))
  //           return false;

  //        return true;
  //     }
  // //for deleteing the candidtate
  // DeleteCandidate(i, uId) {
  // this.uId = uId;
  //  $("#deleteModel").modal("show");
  // }

  // deleteConfirm()
  // {  this.loginService.candidatedelete(this.uId).subscribe(
  //     (res:any)=>{
  //       this.alert.success("Succesfully Deleted", true);
  //        $("#deleteModel").modal("hide");
  //       this.refresh();
  //     },
  //     error => {
  //       this.alert.error(Helper.getMessageFromError(error), true);
  //     }
  //   );

  // }

  // //for updating the candidate
  // updateCandidate(id, uid) {
  //   //'CandidateUpdate/:id/:uid

  //   if (this.localData.UserType === "Admin") {
  //     this.data.changeMessage4(this.list.List[id]);
  //     this.url = `admin/candidates-update/${id}/${uid}`;
  //     this.router.navigateByUrl(this.url);
  //   } else if (this.localData.UserType === "SuperAdmin") {
  //     this.data.changeMessage4(this.list.List[id]);
  //     this.url = `super-admin/candidates-update/${id}/${uid}`;
  //     this.router.navigateByUrl(this.url);
  //   }
  // }
  //  RowPerPage(e) {
  //   this.totalRowPerPage = Number(e.target.value);
  //   this.Take = this.totalRowPerPage;
  //   this.Skip = 0;
  //    if (this.Skip == 0) {
  //       this.recordStarting = 1;
  //     } else {
  //       this.recordStarting = this.Skip + 1;
  //     }
  //   this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
  //   //page number
  //   // this.recordStarting = this.Skip;
  //   var totalrecord = this.Skip + this.Take;
  //   if (totalrecord == this.TotalRecords) {
  //     this.recordEnding = totalrecord;
  //   }
  //   else if (totalrecord > this.TotalRecords) {
  //     this.recordEnding = this.TotalRecords;
  //   }
  //   else if (totalrecord < this.TotalRecords) {
  //     this.recordEnding = totalrecord;
  //   }
  //   this.refresh();
  // }

  // firstPage() {
  //   this.Skip = 0;
  //   this.Take = this.totalRowPerPage;
  //   this.recordStarting = 1;
  //   if (this.TotalRecords < this.Take) {
  //     this.recordEnding = this.TotalRecords;
  //   }
  //   else {
  //     this.recordEnding = this.Take;
  //   }

  //   this.refresh()
  // }
  // nextPage() {
  //   if (this.recordEnding < this.TotalRecords) {
  //     this.Skip += this.totalRowPerPage
  //     this.Take = this.totalRowPerPage

  //     //for page number
  //     if(this.Skip ==0)
  //     {
  //       this.recordStarting = 1;
  //     }
  //     else
  //     {
  //     this.recordStarting = this.Skip+1;
  //     }
  //     var totalrecord = this.Skip + this.Take;

  //     if (totalrecord == this.TotalRecords) {
  //       this.recordEnding = totalrecord;
  //     }
  //     else if (totalrecord > this.TotalRecords) {
  //       this.recordEnding = this.TotalRecords;
  //     }
  //     else if (totalrecord < this.TotalRecords) {
  //       this.recordEnding = totalrecord;
  //     }

  //     this.refresh()
  //   }
  // }
  // priviousPage() {
  //   if (this.recordStarting > 1) {
  //     this.Skip -= this.totalRowPerPage;
  //     this.Take = this.totalRowPerPage;

  //     //for page number
  //     //for page number
  //      if(this.Skip ==0)
  //     {
  //       this.recordStarting = 1;
  //     }
  //     else
  //     {
  //     this.recordStarting = this.Skip+1;
  //     }

  //     var totalrecord = this.Skip + this.Take;

  //     if (totalrecord == this.TotalRecords) {
  //       this.recordEnding = totalrecord;
  //     }
  //     else if (totalrecord > this.TotalRecords) {
  //       this.recordEnding = this.TotalRecords;
  //     }
  //     else if (totalrecord < this.TotalRecords) {
  //       this.recordEnding = totalrecord;
  //     }

  //     this.refresh()
  //   }

  // }
  // lastPage() {
  //   var lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
  //   this.Skip = lastPage;
  //   this.Take = this.totalRowPerPage

  //   //for the page number
  //    if(this.Skip ==0)
  //     {
  //       this.recordStarting = 1;
  //     }
  //     else
  //     {
  //     this.recordStarting = this.Skip+1;
  //     }

  //   var totalrecord = this.Skip + this.Take;

  //   if (totalrecord == this.TotalRecords) {
  //     this.recordEnding = totalrecord;
  //   }
  //   else if (totalrecord > this.TotalRecords) {
  //     this.recordEnding = this.TotalRecords;
  //   }
  //   else if (totalrecord < this.TotalRecords) {
  //     this.recordEnding = totalrecord;
  //   }
  //   this.refresh();
  // }
  // getSortedList(name){
  //   if(this.sortByName == name){
  //     this.sortOrder == "Desc" ? this.sortOrder = "Asc" : this.sortOrder = "Desc";
  //   }else{
  //     this.sortOrder = "Asc";
  //   }
  //   this.sortByName = name;
  //   this.refresh();
  // }
  // clearFields(){
  //   this.keyword = null;
  //   this.registrationFrom = null;
  //   this.registrationTo = null;
  //   this.categoryType = null;


