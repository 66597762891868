import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../service/data.service";
import { ApiServerService } from "../../../service/api-server.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertService } from "../../../_service/alert.service";

@Component({
  selector: "app-candidate-result",
  templateUrl: "./candidate-result.component.html",
  styleUrls: ["./candidate-result.component.css"]
})
export class CandidateResultComponent implements OnInit {
  registrationNo: any;
  resultView: boolean;

  firstName: any;
  RegistartionNumber: any;
  dob: any;
  score: any;
  schoolName: any;
  class: any;
  Status: any;
  state: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alert: AlertService
  ) {}

  ngOnInit() {}
  getResult() {
    if (this.registrationNo != undefined) {
      this.loginService.result(this.registrationNo).subscribe(
        (res:any)=>{
          this.resultView = true;
          this.firstName = res.Name;
          this.RegistartionNumber = res.RegistartionNumber;
          this.dob = res.DOB;
          this.score = res.Score;
          this.schoolName = res.SchoolName;
          this.class = res.Class;
          this.state = res.state;
          this.Status = res.Status;
        },
        error => {
          this.resultView = false;
          if(error.status == 200 )
          this.alert.error("Invalid Roll Number");
          else{
            this.alert.error(error.error);
          }
        }
      );
    } else {
      this.alert.error("Enter Registration Number", true);
    }
  }
}
