import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiServerService } from 'src/app/service/api-server.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Helper } from 'src/app/_model/helper';
import { AlertService } from 'src/app/_service/alert.service';

@Component({
  selector: 'app-admin-setting-common',
  templateUrl: './admin-setting-common.component.html',
  styleUrls: ['./admin-setting-common.component.css']
})
export class AdminSettingCommonComponent implements OnInit {

  aForm: FormGroup
  isSubmitted = false

  constructor(
    private fb: FormBuilder,
    private alert: AlertService,
    private loaderService: LoaderService,
    private _service: ApiServerService,
    ) { }

  ngOnInit() {
    this.createAdminForm()
    this.getAdminSetting()
  }

  createAdminForm(){
    this.aForm = this.fb.group({
      ExamType: [null, [Validators.required]],
      OnlineURL: [null, [Validators.required]]
    })
  }

  get f(){
    return this.aForm.controls
  }

  submit(){
    this.isSubmitted = true
    if(this.aForm.invalid){
      return
    }
    this.loaderService.show()
    this._service.updateAdminSettingApi(this.aForm.value).subscribe((res: any) => {
      this.loaderService.hide()
      this.isSubmitted = false
      this.alert.success("Setting changed succesfully", true);
    },
    
    (err: any) => {
      this.loaderService.hide();
      this.alert.error(Helper.getMessageFromError(err), true);
    })
  }

  getAdminSetting(){
    this.loaderService.show()
    this._service.getAdminSettingApi().subscribe((res: any) => {
      this.aForm.patchValue(res)
      this.loaderService.hide()
    },
    
    (err: any) => {
      this.loaderService.hide();
      this.alert.error(Helper.getMessageFromError(err), true);
    })
  }

  onExamTypeChange(val){
    if(val === 'Online'){
      this.aForm.get('OnlineURL').setValidators([Validators.required])
      this.aForm.get('OnlineURL').updateValueAndValidity()
    }else{
      this.aForm.get('OnlineURL').clearValidators()
      this.aForm.get('OnlineURL').updateValueAndValidity()
    }
  }

}
