export class PaginationModel {
  constructor() {
    this.SortOrder = 'Desc';
    this.CurrentPage = 1;
    this.PageSize = 10;
  }
  public CurrentPage: number;
  public TotalRecord: number;
  public PageSize: number;
  public SortBy?: string;
  public SortOrder?: 'Asc' | 'Desc';
}

export class PropertyMetaModel {
  constructor() {
    this.PropertyType = GridColumnType.Text;
    this.ColumnName = '';
    this.DisplayText = '';
    this.PropertyName = '';
    this.ClassName = '';
  }
  public ColumnName: string;
  public PropertyName: string;
  public PropertyType?: GridColumnType = GridColumnType.Text;
  public DisplayText?: string | string[];
  public IsSortable?: boolean;
  public ClassName?: string;
}

export enum GridColumnType {
  Text,
  Link,
  Action,
  DisplayLink,
  Boolean,
  EditableText,
  Switch,
  Checkbox,
  Icon
}
