declare var $: any;
export class Helper {
    public static dynamicHeader() {
        const headerInnerHeight = $(document).find('.header-dynamic .navbar-own').outerHeight() + 15;
        $(document).find('.header-dynamic').height(headerInnerHeight);
    }
    public static minViewHeight() {
        setTimeout(() => {
            const viewHeight = $(window).height();
            const footerHeight = $(document).find('.main-footer').outerHeight();
            const totalHeight = viewHeight - footerHeight;
            $(document).find('.min-height-view').css('min-height', totalHeight);
        }, 100);
    }

    public static getMessageFromError(error: any) {
        //console.error('Error response from Server', error);
        let message = '';
        if (error) {
            if(error.status == 0){
                return "The username name or password is incorrect. You are advised to change password by Clicking on 'Forgot Password?'"
            }
            if (error && error.error) {
                if (error.error.error_description) {
                    return error.error.error_description;
                }
                if (error.error.Message) {
                    return error.error.Message;
                }
                return error.error;
            }
            if (error.status === 0) {
                return 'Something went wrong on server';
            }
            if (error.error.Message) {
                return error.error.Message;
            }
            if (error.error.ModelState) {
                message += error.error.Message + ' ';
                for (const key in error.error.ModelState) {
                    if (error.error.ModelState.hasOwnProperty(key)) {
                        error.ModelState[key].forEach(element => {
                            message += element + ' ';
                        });
                    }
                }
            }
            if (error.error.error_description) {
                return error.error.error_description;

            }
            if (error.Message) {
                return error.Message;
            }

            if (error.message) {
                return error.message;
            }
            if (error.error) {
                if (error.error.error_description) {
                    return error.error.error_description;
                }
                if (error.error.Message) {
                    return error.error.Message;
                }
                if (error.error.message) {
                    return error.error.message;
                }
            }
            if (error.statusText) {
                return error.statusText;
            }
        }
        return 'Something went wrong';
    }
}
