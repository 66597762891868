import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SettingComponent } from "src/app/superAdmin/setting/setting.component";
import { AuthorityListComponent } from "src/app/superAdmin/authority-list/authority-list.component";
import { CreateAuthorityComponent } from "src/app/superAdmin/create-authority/create-authority.component";
import { AddCentreComponent } from "src/app/AdmAccess/examcentre/add-centre/add-centre.component";
import { CentreListComponent } from "src/app/AdmAccess/examcentre/centre-list/centre-list.component";
import { FullMigrateComponent } from "src/app/AdmAccess/examcentre/full-migrate/full-migrate.component";
import { PartialMigrateComponent } from "src/app/AdmAccess/examcentre/partial-migrate/partial-migrate.component";
import { AddSeasonComponent } from "src/app/AdmAccess/season/add-season/add-season.component";
import { SeasonListComponent } from "src/app/AdmAccess/season/season-list/season-list.component";
import { SchoolListComponent } from "src/app/AdmAccess/schoolAndCandidate/school-list/school-list.component";
import { CandidateListComponent } from "src/app/AdmAccess/schoolAndCandidate/candidate-list/candidate-list.component";
import { AddCandidateComponent } from "src/app/school/add-candidate/add-candidate.component";
import { ContactReportComponent } from "src/app/AdmAccess/report/contact-report/contact-report.component";
import { SchoolRegReportComponent } from "src/app/AdmAccess/report/school-reg-report/school-reg-report.component";
import { CandidateRegReportComponent } from "src/app/AdmAccess/report/candidate-reg-report/candidate-reg-report.component";
import { CentreUtilizationReportComponent } from "src/app/AdmAccess/report/centre-utilization-report/centre-utilization-report.component";
import { CentreCandidateScheduleComponent } from "src/app/AdmAccess/report/centre-candidate-schedule/centre-candidate-schedule.component";
import { FaqListComponent } from "src/app/AdmAccess/faqContact/faq-list/faq-list.component";
import { ContactUsComponent } from "src/app/AdmAccess/faqContact/contact-us/contact-us.component";
import { UploadQuestionComponent } from "src/app/AdmAccess/faqContact/upload-question/upload-question.component";
import { SuperAdminComponent } from "src/app/superAdmin/super-admin/super-admin.component";
import { PageNotFoundComponent } from "src/app/home/page-not-found/page-not-found.component";
import { GuardSuperAdminService } from "src/app/superAdmin/authguard/guard-super-admin.service";
import { ListLocComponent } from "src/app/AdmAccess/location/list-loc/list-loc.component";
import { AddLocComponent } from "src/app/AdmAccess/location/add-loc/add-loc.component";
import { RegistrationComponent } from "../home/registration/registration.component";
const routes: Routes = [
  {
    path: "super-admin",
    component: SuperAdminComponent,
    canActivate: [GuardSuperAdminService],
    children: [
      { path: "", component: CreateAuthorityComponent },
      { path: "setting", component: SettingComponent },
      { path: "authority-list", component: AuthorityListComponent },
      { path: "create-authority", component: CreateAuthorityComponent },
      {
        path: "update-authority/:id/:uid",
        component: CreateAuthorityComponent
      },
      { path: "add-exam-centre", component: AddCentreComponent },
      { path: "centre-list", component: CentreListComponent },
      { path: "migrate-exam-centre", component: FullMigrateComponent },
      { path: "partial-migrate", component: PartialMigrateComponent },
      { path: "updates-exam-centres/:id/:uid", component: AddCentreComponent },
      { path: "add-season", component: AddSeasonComponent },
      { path: "season-list", component: SeasonListComponent },
      { path: "school-list", component: SchoolListComponent },
      { path: "candidate-list", component: CandidateListComponent },
      { path: "candidates-update", component: AddCandidateComponent },
      { path: "contact-reports", component: ContactReportComponent },
      {
        path: "school-registration-reports",
        component: SchoolRegReportComponent
      },
      {
        path: "edit-School",
        component: RegistrationComponent
      },
      {
        path: "candidate-registration-reports",
        component: CandidateRegReportComponent
      },
      {
        path: "central-utilization-reports",
        component: CentreUtilizationReportComponent
      },
      {
        path: "center-candidates-schedule",
        component: CentreCandidateScheduleComponent
      },

      { path: "faq-admin", component: FaqListComponent },
      { path: "get-contact-us", component: ContactUsComponent },
      { path: "upload-past-question", component: UploadQuestionComponent },
      { path: "location-list", component: ListLocComponent },
      { path: "create-location", component: AddLocComponent },
      { path: "**", component: PageNotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule {}
