import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "src/app/service/api-server.service";
import { DataService } from "src/app/service/data.service";
import { FormBuilder } from "@angular/forms";
import { AlertService } from "src/app/_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Http, Headers } from "@angular/http";
import { Helper } from "../../_model/helper";
declare var $: any;
@Component({
  selector: "app-edit-home-page",
  templateUrl: "./edit-home-page.component.html",
  styleUrls: ["./edit-home-page.component.css"]
})
export class EditHomePageComponent implements OnInit {
  public mainHeading: string = null;
  public firstStage: string = null;
  public secondStage: string = null;
  public areaName1: string = null;
  public areaName2: string = null;
  public areaName3: string = null;
  public areaName1Number: number = null; //   change this to a lsit i.e. array push to add and delete
  public areaName2Number: number = null;
  public areaName3Number: number = null;

  public youtubeLink: string = null;
  public googlePlusLink: string = null;
  public linkedinLink: string = null;
  public facebookLink: string = null;
  public twitterLink: string = null;

  guideLineContent: any; //add new
  guideLineHeading: any; //add new
  guidelineButton: boolean;
  id: any;
  guidelineList: any;
  modelTitle: any;

  syllabusList: any; //syllabus data

  allData:any;
  countryId: any;
  Country: any;
  countryIdForSyllabus: any;
  firstPrize: any;
  secondPrize: any;
  thirdPrize: any;
  countryIdforPrize: any;
  countryIdforHomeHeading: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: Http
  ) {}

  ngOnInit() {
    this.getAllCountries()
    // this.getGuidelines();
    // this.getSyllabus();
    // this.getAllData();
  }
  getAllCountries(): any {
    this.loginService.getAllCountryList().subscribe(res => {
      if(res){
        this.Country = res;
        this.countryId = res[0].Value;
        this.countryIdForSyllabus = res[0].Value;
        this.countryIdforPrize = res[0].Value;
        this.countryIdforHomeHeading = res[0].Value;
        this.getGuidelines();
        this.getSyllabus();
        this.getAllData(this.countryId);

      }
    }, err => {})
  }
  // getPrize(): any {
    // this.loginService.getPrizeAdmin(this.countryId).subscribe(
    //   (res:any)=>{      
    //     this.guidelineList = res;
    //   },
    //   error => {
    //     this.alert.error("error", true);
    //   }
    // );
  // }
  getAllData(id) {
    this.loginService.getAllEditedData(id).subscribe(
      (res:any)=>{
        this.allData = res;       
        for (var _i = 0; _i < res.length; _i++) {
          var num = this.allData[_i];
            if(this.allData[_i].Name=="mainHeading")
             {
               this.mainHeading = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="mainCountryWise")
             {
               this.firstStage = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="whyInterswitchSpak")
             {
               this.secondStage = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="youtubeLink")
             {
               this.youtubeLink = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="googlePlusLink")
             {
               this.googlePlusLink = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="linkedinLink")
             {
               this.linkedinLink = this.allData[_i].Value;
             }
              else if(this.allData[_i].Name=="facebookLink")
             {
               this.facebookLink = this.allData[_i].Value;
             }
              else if(this.allData[_i].Name=="twitterLink")
             {
               this.twitterLink = this.allData[_i].Value;
             }   
             else if(this.allData[_i].Name =="ContactHtml")
             {
                  this.areaName1 = this.allData[_i].Value;
             } 
             else if(this.allData[_i].Name =="firstPrize")
             {
                  this.firstPrize = this.allData[_i].Value;
             } 
             else if(this.allData[_i].Name =="secondPrize")
             {
                  this.secondPrize = this.allData[_i].Value;
             } 
             else if(this.allData[_i].Name =="thirdPrize")
             {
                  this.thirdPrize = this.allData[_i].Value;
             }         
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }




  updateData = {};
  updatMainHeading(editorFieldName) {
    if (editorFieldName == "mainHeading") {
      this.updateData = {
        name: editorFieldName,
        value: this.mainHeading
      };
      this.callServiceToedit();
    } else if (editorFieldName == "firstStage") {
      this.updateData = {
        name: editorFieldName,
        value: this.firstStage
      };
      this.callServiceToedit();
    } else if (editorFieldName == "secondStage") {
      this.updateData = {
        name: editorFieldName,
        value: this.secondStage
      };
      this.callServiceToedit();
    } else {
      this.updateData = {};
      return 0;
    }
    this.updateData = {};    
  }

  updatPrizeHeading(editorFieldName) {
    if (editorFieldName == "firstPrize") {
      this.updateData = {
        name: editorFieldName,
        value: this.firstPrize,
        CountryId: this.countryIdforPrize
      };
      this.callServiceToedit();
    } else if (editorFieldName == "secondPrize") {
      this.updateData = {
        name: editorFieldName,
        value: this.secondPrize,
        CountryId: this.countryIdforPrize        
      };
      this.callServiceToedit();
    } else if (editorFieldName == "thirdPrize") {
      this.updateData = {
        name: editorFieldName,
        value: this.thirdPrize,
        CountryId: this.countryIdforPrize        
      };
      this.callServiceToedit();
    } else {
      this.updateData = {};
      return 0;
    }
    this.updateData = {};
  }

  getdataCountryWiselines(){
    this.countryId = this.countryIdforPrize;
    this.getAllData(this.countryId);
  }

  updatePhoneNumber(editorFieldName) {
     this.updateData = {
       name:"ContactHtml",
       value:this.areaName1
     };
     this.callServiceToedit();
  }

  updateMainHeadingCountryWise(editorFieldName){
    this.updateData = {};

    if (editorFieldName == "whyInterswitchSpak") {
      this.updateData = {
        name: editorFieldName,
        value: this.secondStage,
        CountryId: this.countryId
      };
      this.callServiceToedit();
    } else if (editorFieldName == "mainCountryWise") {
      this.updateData = {
        name: editorFieldName,
        value: this.firstStage,
        CountryId: this.countryId
      };
      this.callServiceToedit();
    } else {
      this.updateData = {};
      
      return 0;
    }
    // Service will go Here
    this.updateData = {};
  }

  updateMediaLinks(editorFieldName) {
    this.updateData = {};

    if (editorFieldName == "youtubeLink") {
      this.updateData = {
        name: editorFieldName,
        value: this.youtubeLink
      };
      this.callServiceToedit();
    } else if (editorFieldName == "googlePlusLink") {
      this.updateData = {
        name: editorFieldName,
        value: this.googlePlusLink
      };
      this.callServiceToedit();
    } else if (editorFieldName == "linkedinLink") {
      this.updateData = {
        name: editorFieldName,
        value: this.linkedinLink
      };
      this.callServiceToedit();
    } else if (editorFieldName == "facebookLink") {
      this.updateData = {
        name: editorFieldName,
        value: this.facebookLink
      };
      this.callServiceToedit();
    } else if (editorFieldName == "twitterLink") {
      this.updateData = {
        name: editorFieldName,
        value: this.twitterLink
      };
      this.callServiceToedit();
    } else {
      this.updateData = {};
      
      return 0;
    }
    // Service will go Here
    this.updateData = {};
  }

  callServiceToedit() {
    
    this.loginService.addMainPageData(this.updateData).subscribe(
      (res:any)=>{
        
        this.alert.success("Success", true);
      },
      error => {
        this.alert.error("error", true);
      }
    );
  }

  testCheckLink(link) {
    
    return this.http.get(link);
  }

  getSyllabus() {
    this.loginService.getSyllabusAdmin(this.countryIdForSyllabus).subscribe(
      (res:any)=>{
        this.syllabusList = res;
      },
      error => {
        this.alert.error("error", true);
      }
    );
  }
  addNewSyllabusTopic() {
    this.modelTitle = "Add Syllabus";
    this.guideLineContent = undefined;
    this.guideLineHeading = undefined;
    this.guidelineButton = false;
    $("#guidelinesAdd").modal("show");
  }
  addSyllabus() {
    var data = {
      Subject: this.guideLineHeading,
      Value: this.guideLineContent
    };
    this.loginService.addSyllabus(data).subscribe(
      (res:any)=>{
       
        this.alert.success("Syllabus added", true);
        $("#guidelinesAdd").modal("hide");
        this.modelTitle = undefined;
        this.guideLineContent = undefined;
        this.guideLineHeading = undefined;
        this.getSyllabus();
      },
      error => {
        this.alert.error("error", true);
      }
    );
  }

deleteSyllabus(item)
{
  
this.modelTitle = "Delete Syllabus";
this.guideLineHeading = item.Key;
this.guideLineContent = item.Value;
 this.id = item.Id;
 this.guidelineButton = false;
   $("#deleteGuidelineModal").modal("show");
}
deleteSyllabusConfirm()
{
  this.loginService.deleteSyllabus(this.id).subscribe(
    (res:any)=>{
      this.alert.success("Successfully submitted", true);
      this.getGuidelines();
      this.id = undefined;
      this.guideLineContent = undefined;
      this.guideLineHeading = undefined;
      //  $("#guidelinesAdd").modal("hide");
      $("#deleteGuidelineModal").modal("hide");
    },
    error => {
      this.alert.error("Something went wrong", true);
    }
  );
}



  getGuidelines() {
    this.loginService.getGuidelinesAdmin(this.countryId).subscribe(
      (res:any)=>{      
        this.guidelineList = res;
      },
      error => {
        this.alert.error("error", true);
      }
    );
  }
 

  deleteGuidelines(item) {
    this.modelTitle = "Delete guideline";
    this.guideLineContent = item.Value;
    this.guideLineHeading = item.Key;
    this.id = item.Id;
    this.guidelineButton = true;
    $("#deleteGuidelineModal").modal("show");
  }
  deleteGuidelineConfirm() {
    this.loginService.deleteGuidelines(this.id).subscribe(
      (res:any)=>{
        this.alert.success("Guideline deleted successfully ", true);
        this.getGuidelines();
        this.id = undefined;
        this.guideLineContent = undefined;
        this.guideLineHeading = undefined;
        $("#deleteGuidelineModal").modal("hide");
        $();
      },
      error => {
        this.alert.error("Something went wrong", true);
      }
    );
  }

editSyllabus(item)
{
   this.modelTitle = "Edit Syllabus";   
   this.guideLineHeading = item.Key;
   this.guideLineContent = item.Value;
   this.id = item.Id;
   this.guidelineButton = false;
     $("#guidelinesAdd").modal("show");
}

addSyllabusConfirm()
{
 var data = {
    Key :  this.guideLineHeading,
    Value : this.guideLineContent,
    Id: this.id,
    CountryId: this.countryIdForSyllabus
  }
  this.loginService.updateSyllabus(data).subscribe(
    (res:any)=>{
      this.alert.success("Successfully submitted", true);
      this.getSyllabus();
      this.id = undefined;
      this.guideLineContent = undefined;
      this.guideLineHeading = undefined;
       $("#guidelinesAdd").modal("hide");
    },
    error => {
      this.alert.error("Something went wrong", true);
    }
  );
}

  editGuidelines(item) {
    this.modelTitle = "Edit guidelines";
    this.guideLineContent = item.Value;
    this.guideLineHeading = item.Key;
    this.id = item.Id;
    this.guidelineButton = true;
    $("#guidelinesAdd").modal("show");
  }

  //button listener for adding new guidelines
  addNew() {
    this.modelTitle = "Add new guideline";
    $("#guidelinesAdd").modal("show");
    this.guidelineButton = true;
  }

  //api hit for adding new data
  addGuideLine() {
    var data;
    if (this.id === undefined || this.id === null) {
      data = {
        Key: this.guideLineHeading,
        Value: this.guideLineContent,
        CountryId: this.countryId
      };
    } else {
      data = {
        Id: this.id,
        Key: this.guideLineHeading,
        Value: this.guideLineContent,
        CountryId: this.countryId
      };
    }

    this.loginService.addGuidelines(data).subscribe(
      (res:any)=>{
        this.alert.success("Successfully submitted", true);
        this.getGuidelines();
        this.id = undefined;
        this.guideLineContent = undefined;
        this.guideLineHeading = undefined;
         $("#guidelinesAdd").modal("hide");
      },
      error => {
        this.alert.error("Something went wrong", true);
      }
    );
    this.guidelineButton = false;
  }

  updateSyllabus(data){
    
  }
}




//  <div class="col-12 pb-2"> 
//   Bimbo - <span class="text-nowrap">0708 705 7670</span> 
//    </div>  <div class="col-12 pb-2"> 
//     Awele - <span class="text-nowrap">0906 998 8833</span> 
//      </div>  <div class="col-12">  Uche - <span class="text-nowrap">0818 025 2372
//      </span>  </div>