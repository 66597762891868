import { SeasonFilterModel } from './../AdmAccess/season/season-list/seasongridmodel';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import * as myapi from './baseLink';
import { PaginationModel } from '../_model/pagination-model';
import { CandidateRegReportGridModel } from '../AdmAccess/report/candidate-reg-report/candidateregreportfiltermodel';
import { candidateResultModel } from '../home/result/school-result/candidateresult-model';

@Injectable({
  providedIn: 'root'
})
export class ApiServerService {
  getMinNoOfGirls(): any {
    const url: string = myapi.getMinNoOfGirls;
    return this.http.get(url);
  }
  getPrizeAdmin(countryId: any): any {
    throw new Error("Method not implemented.");
  }
  getAllCountryList(): any {
    const url: string = myapi.getCountryList;
    return this.http.get(url);
  }
  updateSeasonmanagement(value: any): any {
    const url: string = myapi.updateSeasonmanagement;
    return this.http.put(url, value);
  }
  getSeasonManagementDataById(Id: any, CountryId): any {
    const url: string = myapi.getSeasonManagementDataById + Id + `&CountryId=${CountryId}`;
    return this.http.get(url);
  }
  getallSeasonMAnagementData(data): any {
    const url: string = myapi.getallSeasonMAnagementData;
    return this.http.post(url, data);
  }
  addSeasonmanagement(value: any): any {
    const url: string = myapi.addSeasonmanagement;
    return this.http.post(url, value);
  }
  getActiveSeason(): any {
    const url: string = myapi.getActiveSeason;
    return this.http.get(url);
  }
  getCandidatesResult(filter: candidateResultModel, countryId): any {
    const url: string = myapi.candidateResultModel+ countryId;
    return this.http.post(url, filter);
  }
  updateSeason(value: any, uid: any): any {
    const url: string = myapi.updateSeason + uid;
    return this.http.put(url, value);
  }
  getSeasonDetailById(uid: any): any {
    const url: string = myapi.GetSeasonDetail + uid;
    return this.http.get(url);
  }
  completeSeason(id): any {
    const url: string = myapi.completeSeason + id;
    return this.http.post(url, null);
  }

  getCompletedRegSchoolsNg(data): any {
    const url: string = myapi.getCompletedRegSchoolsNg;
    return this.http.post(url, data);
  }

  getCompletedRegSchoolsKe(data): any {
    const url: string = myapi.getCompletedRegSchoolsKe;
    return this.http.post(url, data);
  }

  updateExamCentre(value: any): any {
    const url: string = myapi.updateExamCenter;
    return this.http.put(url, value);
  }
  getExamCentreWithId(uid: any): any {
    const url: string = myapi.getExamCentreWithId + uid;
    return this.http.get(url);
  }
  changeStatusOfExamCenter(id): any {
    const url: string = myapi.ActivateExamCenter + id;
    return this.http.post(url, null).pipe(map((res: any) => res));
  }
  deleteCandidateAsAdmin(id): any {
    const url: string = myapi.deleteCandidateAsAdmin + id;
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  constructor(private http: HttpClient) {}

  // For the login
  login(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    const u =
      'username=' +
      encodeURIComponent(data.UserName) +
      '&password=' +
      data.Password +
      '&grant_type=password';
    const url: string = myapi.login;
    return this.http.post(url, u, httpOptions).pipe(map((res: any) => res));
  }

  // forgetPassword
  forgetpass(data) {
    const hostname = document.location.origin + '/change-password';
    const url: string = myapi.forgetpass + data + '&URL=' + hostname;
    return this.http.get(url);
  }

  // for the change password component ....(password,token)
  changePassword(data, token) {
    var httpOptions = {
      headers: new HttpHeaders({
        'token':token
      })
    }
    const url: string = myapi.changePassword + data;
    return this.http.get(url, httpOptions);
  }

  // school registration
  schoolRegistration(data, schoolUpdate) {
    data.Role = 'School';
    if (!schoolUpdate) {
      const url: string = myapi.schoolRegistration;
      return this.http.post(url, data);
    } else {
      const url: string = myapi.schoolModify;
      return this.http.post(url, data);
    }
  }

  // get state list  XXXXXXXXXXXXXXXXXXX
  getState(regionId) {
    const url: string = myapi.getState + regionId;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  getStateSchool() {
    const url: string = myapi.getStateSchool;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // get country list XXXXXXXXXXXXX
  getAllCountry() {
    const url: string = myapi.getCountry;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // Get Region list  XXXXXXX remove it
  getAllRegion(countryId) {
    const url: string = myapi.getRegion + countryId;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // to get the region
  getAllRegions() {
    const url: string = myapi.getRegion;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // to get the country
  getallCountry(regionId) {
    const url: string = myapi.getCountry + regionId;
    return this.http.get(url).pipe(map((res: any) => res));
  }
  // to get the state
  getallState(countryId) {
    const url: string = myapi.getState + countryId;
    return this.http.get(url).pipe(map((res: any) => res));
  }
  getRegisteredCandidate(data, value) {
    const url: string = myapi.getRegisteredCandidateOfExamCenter + value;
    return this.http.post(url, data);
  }

  // get the list of Candidate School-Wise
  getCandidateSchoolWise(data) {
    const url: string = myapi.getCandidateSchoolWise;
    return this.http.post(url, data);
  }

  // get the list of class
  // to get the class
  getclass() {
    const url: string = myapi.getclass;
    return this.http.get(url).pipe(map((res: any) => res));
  }
  getResultStatus(status) {
    const url: string = myapi.getResultStatus + status;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // for sending otp to email
  sendingOtpToEmail(data) {
    const url: string = myapi.sendingOtpToEmail + data;
    return this.http.get(url);
  }

  // for otp verification of email
  verficationEmailOtp(data) {
    const url: string = myapi.verficationEmailOtp + data;
    return this.http.get(url);
  }

  // for sending otp to phone
  sendingOtpToPhone(data, CountryCode) {
    const url: string = myapi.sendingOtpToPhone + '?phone=' +data+ '&CountryCode=' + CountryCode;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // for otp verfication of phone
  verficationPhoneOtp(data) {
    const url: string = myapi.verficationPhoneOtp + data;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // for adding candidate in school
  addCandidate(data) {
    return this.http.post(myapi.candidateRegistration, data);
  }
  modifyCandidate(data) {
    return this.http.post(myapi.candidateModify, data);
  }

  // to get admin setting
  getSetting() {
    const url: string = myapi.getSetting;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // to change admin setting
  changeSetting(data) {
    const url: string = myapi.changeSetting;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // get all authority
  getAllAuthority(data) {
    const url: string = myapi.getAllAuthority;
    return this.http.post(url, data);
  }

  // add new authority by super admin
  addAuthority(data) {
    const url: string = myapi.addAuthority;
    return this.http.post(url, data);
  }
  // for changing the status
  statusChange(data) {
    const url: string = myapi.statusChange;
    return this.http.put(url, data);
  }
  // to add exam centre
  addExamCentre(data) {
    const url: string = myapi.addExamCentre;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // get the list of season
  getSeason() {
    const url: string = myapi.getSeason;
    return this.http.get(url);
  }

  getSeasonDD() {
    const url: string = myapi.getAllSeasonDD;
    return this.http.get(url);
  }

  getCandidateById(id) {
    const url: string = myapi.getCandidateById + id;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // get all exam centre
  getAllExamCentre(data) {
    const url: string = myapi.getAllExamCentre;
    return this.http.post(url, data);
  }

  getListOfExamCentre() {
    const url: string = myapi.getAllExamCentreListUrl;
    return this.http.get(url);
  }

  // delete exam centre
  examCentreDelete(data) {
    const url: string = myapi.examCentreDelete + data;
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  // to change the status of the exam centere
  changeExamCentreStatus(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.changeStatusExamCentre;
    return this.http.post(url, data);
  }
  // get exam centre state wise
  examCentreIdwise(id) {
    const url: string = myapi.examCentreIdwise + id;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // get Exam type
  getExamTypeApi(){
    const url: string = myapi.getExamType
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // to initiate the partial migrate
  partiallyMigrate(data, link) {
    const url: string = myapi.partiallyMigrate + link;
    return this.http.post(url, data);
  }

  // to get all register candidate by user
  getAllCandidate(data, filter) {
    const body = Object.assign(data, filter);
    return this.http
      .post(myapi.getCandidateScores, body);
  }

  getAllCandidateAsAdmin(data) {
    const url: string = myapi.getAllCandidatedataAsAdmin;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // to migrate the exam centre fully
  migrateExamCentre(uri) {
    const url: string = myapi.migrateExamCentre + uri;
    return this.http.post(url, null);
  }

  // add season
  addseason(data) {
    const url: string = myapi.addSeason;
    return this.http.post(url, data);
  }
  // to activateSeason
  activateSeason(id) {
    const url: string = myapi.activateSeason + id;
    return this.http.post(url, null).pipe(map((res: any) => res));
  }
  // to delete the season
  deleteSeason(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.deleteSeason + data;
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  // get all seasonList
  getAllSeasonList(data: any, name: string = '') {
    const url: string = myapi.getAllSeasonList + '?filter=' + name;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // get all school data
  getAllSchoolData(data) {
    const url: string = myapi.getAllSchoolData;
    return this.http.post(url, data);
  }

  // for deleteing the candidate
  candidatedelete(data) {
    const url: string = myapi.candidateDelete + data;
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  // get the report in admin section
  getContactReport(data, filter) {
    const body = Object.assign(data, filter);
    let url: string;
    if (data.ContactType === 'candidate') {
      url = myapi.getCandidateContactReport;
      return this.http.post(url, body).pipe(map((res: any) => res));
    }
    if (data.ContactType === 'gaurdians') {
      url = myapi.getGaurdianContactReport;
      return this.http.post(url, body).pipe(map((res: any) => res));
    }
    url = myapi.getSchoolContactReport;
    return this.http.post(url, body).pipe(map((res: any) => res));
  }

  // get the contact report continiously 3 function below
  downloadParentContactReport(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url = `${myapi.downloadParentContactReport}${data}`;
    return this.http.get(url);
  }

  downloadCandidateContactReport(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.downloadCandidateContactReport + `${data}`;
    return this.http.get(url);
  }

  downloadSchoolContactReport(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url = `${myapi.downloadSchoolContactReport}${data}`;
    return this.http.get(url);
  }

  // report of school in admin
  schoolReport(data, body) {
    const a = Object.assign(data, body);
    const url: string = myapi.schoolReport;
    return this.http.post(url, a);
  }

  // download school report in admin
  schoolReportDownload(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.schoolReportDownload + data;
    return this.http.get(url);
  }

  // for candidate report season wise
  candidateReportSesaonWise(
    page: any,
    filter: CandidateRegReportGridModel,
    seasonId: number = 1
  ) {
    const data = Object.assign(page, filter);
    const url: string =
      myapi.candidateReportSesaonWise + '?SeasonId=' + seasonId;
    return this.http.post(url, data);
  }

  // candidate registration download
  candidateReportSesaonWiseDownload(data, link) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.candidateReportSesaonWiseDownload + link;
    return this.http.post(url, data);
  }
  // central utilization download report
  centralUtilizationReportdownload() {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.centralUtilizationReportdownload;
    return this.http.get(url);
  }
  // centeral utilization report
  centralUtilizationReport(data, filter) {
    const body = Object.assign(data, filter);
    return this.http.post(myapi.centralUtilizationReport, body);
  }
  // get all exam centre list in report
  getAllExamCentreList(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.getAllExamCentreList;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // centre candidate report
  centreCandidateReport(link, data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.centreCandidateReport;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }
  // centre candidate report download
  centreCandidateReportDownload(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.centreCandidateReportDownload + `${data}`;
    return this.http.get(url);
  }
  // to store new faq to db
  sendAnswerFaq(data) {
    const url: string = myapi.sendFaq;
    return this.http.post(url, data);
  }
  // get FAQ Simple at Admin level
  getFaqAdmin(countryId) {
    const url: string = myapi.getFaqForAdmin + countryId;
    return this.http.get(url).pipe(map((res: any) => res));
  }
  // get all the list
  getContatctUsDetails(data) {
    const url: string = myapi.getcontactUsDetails;
    return this.http.post(url, data);
  }

  // reply to the solution of the contact us
  answerContatctUsDetails(data) {
    const url: string = myapi.sendContactUsDetailsAnswer;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // get year..for the past question
  getYear(classId) {
    const url: string = myapi.getYear + classId;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // to get past question
  getPastQuestionList(data) {
    const url: string = myapi.getPastQuestionList + data;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // add past year question paper
  pastYearQuestionPaper(data, link) {
    const url: string = myapi.pastYearQuestionPaper + link;
    return this.http.post(url, data);
  }

  // to upadre score in examiner section
  updateScore(data: any) {
    const url: string = myapi.updateScore + '?Id=' + data.Id + '&Score=' + data.Score;
    return this.http.post(url, null);
  }

  // to publish the result
  publishResult() {
    const url: string = myapi.publishResult;
    return this.http.post(url, null).pipe(map((res: any) => res));
  }

  // to upload excel
  uploadExcel(file) {
    const url: string = myapi.uploadCandidateScores;
    return this.http.post(url, file);
  }

  // get FAQ Simple at home page
  getFaq(countryId) {
    const url: string = myapi.faq + countryId;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // contact us in homePage
  contactUs(data) {
    const url: string = myapi.contactUs;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  schoolProfile() {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).access_token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.schoolProfile;
    return this.http.get(url).pipe(map(res => res));
  }
  // get candidate class wise
  getCandidateClassWise(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.getCandidateClassWiseUrl;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  //save exam type 
  updateAdminSettingApi(data){
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.updateAdminSetting;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // get exam type
  getAdminSettingApi(){
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.getAdminSetting;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // state name
  getAllStateNameService() {
    const url: string = myapi.getState;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // get All the exam centre
  getExamCentre(data) {
    const url: string = myapi.getExamCentreListSchoolWise + data;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // register for the competiton
  registerForCompetition(data, examCenterId, CaptureDevice) {
    const url: string = myapi.registerForCompetitionUrl + examCenterId+`&CaptureDevice=${CaptureDevice}`;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // delete authority in admin
  deleteAuthority(data) {
    const url: string = myapi.deleteAuthorityUrl;
    return this.http.post(url, data);
  }
  updatePassword(data) {
    const url: string = myapi.updateAuthorityPass;
    return this.http.post(url, data);
  }

  deleteFaq(id) {
    const url: string = myapi.deletFaqUrl + id;
    return this.http.delete(url);
  }

  getAllSyllabus() {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url = 'Put your URL ';
    return this.http.post(url, null).pipe(map((res: any) => res));
  }

  addMainPageData(data) {
    const url: string = myapi.addMainData;
    return this.http.post(url, data);
  }

  disqualifyCandidate(id) {
    const data = '?id=' + id;
    const Url = `${myapi.disqualifyUrl}${data}`;
    return this.http.post(Url, null).pipe(map((res: any) => res));
  }

  getAllEditedData(countryId) {
    const Url = myapi.getAllEditedDataUrl+countryId;
    return this.http.get(Url).pipe(map((res: any) => res));
  }

  // update school
  updateSchoolProfile(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const url: string = myapi.updateSchoolProfileUrl;
    const headers = new Headers();
    headers.append('token', auth_dat);
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // get guidelines
  getGuidelines(countryId) {
    const url: string = myapi.getGuidelines + countryId;
    return this.http.get(url);
  }
  getGuidelinesAdmin(countryId) {
    const url: string = myapi.getGuidelinesAdmin+countryId;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // add guidelines
  addGuidelines(data) {
    const url: string = myapi.addGuidelines;
    return this.http.post(url, data);
  }
  updateSyllabus(data) {
    const url: string = myapi.updateSyllabus;
    return this.http.post(url, data);
  }

  deleteGuidelines(id) {
    const url: string = myapi.deleteGuidelines + id;
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  // get syllabus
  getSyllabus(countryId) {
    const url: string = myapi.syllabus + countryId;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  getSyllabusAdmin(countryIdForSyllabus) {
    const url: string = myapi.syllabusAdmin+countryIdForSyllabus;
    return this.http.get(url);
  }

  addSyllabus(data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.addSyllabus;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }
  // delete syllabus
  deleteSyllabus(id) {
    const url: string = myapi.deleteSyllabus + id;
    return this.http.delete(url);
  }

  result(result) {
    const Url = myapi.result + result;
    return this.http.get(Url).pipe(map((res: any) => res));
  }
  topSchool() {
    const Url = myapi.topSchool;
    return this.http.get(Url).pipe(map((res: any) => res));
  }

  resultReport(data) {
    const url: string = myapi.resultReport;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }

  // deactivate season
  DeactiveSeason(id) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const url: string = myapi.DeactiveSeason + id;
    const headers = new Headers();
    headers.append('token', auth_dat);
    return this.http.post(url, null).pipe(map((res: any) => res));
  }

  // add new region
  addNewregion(data) {
    const url: string = myapi.addNewregion;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }
  // add new country
  addNewCountry(data, regionId) {
    const url: string = myapi.addNewCountry + regionId;
    return this.http.post(url, data);
  }

  addNewState(data, countryId) {
    const url: string = myapi.addNewState + countryId;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }
  allLocationData() {
    const url: string = myapi.allLocationData;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  allLocationDataAdmin() {
    const url: string = myapi.allLocationDataAdmin;
    return this.http.get(url).pipe(map((res: any) => res));
  }

  // delete region
  deleteRegion(data) {
    const url: string = myapi.deleteRegion + data;
    return this.http.delete(url);
  }
  deleteCountry(data) {
    const url: string = myapi.deleteCountry + data;
    return this.http.delete(url);
  }

  deleteState(data) {
    const url: string = myapi.deleteState + data;
    return this.http.delete(url);
  }
  examinationSlip(id, data) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url: string = myapi.examinationSlip + id;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }
  downloadExaminationSlip(link) {
    const auth_dat = JSON.parse(localStorage.getItem('auth')).Token;
    const headers = new Headers();
    headers.append('token', auth_dat);
    const url = `${myapi.downloadExaminationSlip}${link}`;
    return this.http.get(url);
  }
  getUserInfo() {
    // let auth_dat = JSON.parse(localStorage.getItem("auth")).access_token;
    //   var headers = new Headers();
    //   headers.append("Authorization", `bearer `+auth_dat);
    const url: string = myapi.getUserInfo;
    return this.http.get(url);
  }
  saveImage(imgFile, isChrome) {
    const url: string = myapi.saveImage + isChrome;
    return this.http.post(url, imgFile).pipe(map((res: any) => res));
  }
  getAllCompleteCandidateSchoolWise(data) {
    const url: string = myapi.allCompleteCandidate + 0;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }
  getAllCompleteCandidateClassWise(data, classId) {
    const url: string = myapi.allCompleteCandidate + classId;
    return this.http.post(url, data).pipe(map((res: any) => res));
  }
  getSchoolAsAdmin(Uid: any): any {
    const url: string = myapi.getSchoolAsAdminUrl + '/' + Uid;
    return this.http.get(url);
  }
  schoolRegistrationAsAdmin(mydatas: any): any {
    const url: string = myapi.schoolRegistrationAsAdmin;
    return this.http.post(url, mydatas);
  }
  getCandidateByIdAsAdmin(Adminuid: any): any {
    const url: string = myapi.getCandidateByIdAsAdmin + Adminuid;
    return this.http.get(url);
  }

  getExamCenterCandidateReport(page: any, filter: any): any {
    const body = Object.assign(page, filter);
    const url: string = myapi.getExamCenterCandidateReport;
    return this.http.post(url, body);
  }

  getAllCandidateScore(): any {
    return this.http.get(myapi.getCandidateScoresExcel);
  }

  markCandidateAbsent(Id: any): any {
    const data = '?id=' + Id;
    const Url = `${myapi.markCandidateAbsent}${data}`;
    return this.http.post(Url, null).pipe(map((res: any) => res));
  }
}
