import { Injectable } from '@angular/core';
import {Router,CanActivate,NavigationStart,NavigationEnd,ActivatedRoute} from "@angular/router";
import { AlertService } from "../../_service/alert.service";
@Injectable({
  providedIn: 'root'
})
export class GuardSuperAdminService {

  constructor(private alert: AlertService, private router: Router) { }
    canActivate() {
    // If user is not logged in send his/her to the homepage
    if (!localStorage.getItem("UserInfo")) {
      this.router.navigate(['/']);
      this.alert.error("You are not authorise please login", true);
      return false;
    } else {
      if (JSON.parse(localStorage.getItem("UserInfo")).Role == "SuperAdmin") {
        return true;
      } else {
          this.router.navigate(['/']);
      this.alert.error("You are not authorise please login", true);
        return false;

      }
    }
  }
}
