import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { FilterFieldModel } from '../../_model/filter-field-model';

@Component({
  selector: 'app-registration-status-dropdown',
  templateUrl: './registration-status-dropdown.component.html',
  styleUrls: ['./registration-status-dropdown.component.css']
})
export class RegistrationStatusDropdownComponent implements OnInit {

  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<any> = new EventEmitter();
  val = '';
  constructor() { }

  ngOnInit() {
  }
  typeChange(event, type) {
    const value = { FieldName: this.field.FieldName, Value: event.target.value, EventType: type };
    this.Changed.emit(value);
  }

  Reset() {
    this.val = '';
  }
}

