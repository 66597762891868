import { Component, OnInit } from '@angular/core';
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Helper } from '../../../_model/helper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  mainHeading: any;
  firstStage: any;
  secondStage: any;
  youtubeLink:any;
  googlePlusLink:any;
  linkedinLink:any;
  facebookLink:any;
  twitterLink:any;
  allData:any;
  areaName1:any;
  copyWriteYear: number;
  firstPrize: any;
  secondPrize: any;
  thirdPrize: any;
  CountryId: any;
  constructor( private loginService: ApiServerService,
    private data: DataService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router) {
      this.copyWriteYear = new Date().getFullYear();
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        if (params['cid']) {
          this.CountryId = atob(params['cid']);
        }
      })
     }

  ngOnInit() {
        this.getAllData();
  }
  getAllData() {
    this.loginService.getAllEditedData(0).subscribe(
      (res:any)=>{
        this.allData = res;
        for (var _i = 0; _i < res.length; _i++) {
          var num = this.allData[_i];
            if(this.allData[_i].Name=="mainHeading")
             {
               this.mainHeading = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="firstStage")
             {
               this.firstStage = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="secondStage")
             {
               this.secondStage = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="youtubeLink")
             {
               this.youtubeLink = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="googlePlusLink")
             {
               this.googlePlusLink = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="linkedinLink")
             {
               this.linkedinLink = this.allData[_i].Value;
             }
              else if(this.allData[_i].Name=="facebookLink")
             {
               this.facebookLink = this.allData[_i].Value;
             }
              else if(this.allData[_i].Name=="twitterLink")
             {
               this.twitterLink = this.allData[_i].Value;
             }
             else if(this.allData[_i].Name=="ContactHtml")
             {
               this.areaName1 = this.allData[_i].Value;
             }
                        
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
}
