export class SeasonGridModel {
    Id: number;
    SeasonName: string;
    IsActive: true;
    SeasonRegStartDate: Date;
    SeasonRegEndDate: Date;
    SeasonExamdate: Date;
    SeasonExamTime: Date;
    MinNoOfGirlsInMix: number;
    MaxSelectedCandidate: number;
    IsCompleted: boolean;
}

export class SeasonFilterModel {
    constructor() {
        this.Order = 'Asc';
        this.Skip = 0;
        this.Take = 10;
    }
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}
