import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../../service/baseLink";
import { Helper } from "../../../_model/helper";
import { LoaderService } from "../../../service/loader.service";
@Component({
  selector: "app-full-migrate",
  templateUrl: "./full-migrate.component.html",
  styleUrls: ["./full-migrate.component.css"]
})
export class FullMigrateComponent implements OnInit {
  rForm: FormGroup;
  list: any;
  selectStateId: any;
  state: any;
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
countryList:any;
countryValue:any;

regionList:any;
regionValue:any;

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      FromCentre: ["", Validators.required],
      ToCentre: ["", Validators.required]
    });
    //  this.getAllExamCentreList();
    this.getAllRegion();
  }

  getAllExamCentreList(id) {
    this.loginService.examCentreIdwise(id).subscribe(
      (res:any)=>{        
        if (res.length == 0) {
          this.alert.error("No Exam Centre Found", true);
          this.list = undefined;
        } else {
          this.list = res;
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
    // this.refresh();
  }

  //get all state
  getState(countryId) {
    this.loginService.getallState(countryId).subscribe(
      (res:any)=>{
        this.state = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // state select event handler
  stateSelected(e) {
    this.selectStateId = e;
    this.getAllExamCentreList(this.selectStateId);
  }

  SendData(data) { 
    if (!data.valid) {
      this.alert.error("fill the form correctly", true);
    }
    if (data.valid) {
      this.loaderService.show();
      let url = `${data.value.FromCentre}&MigratedToCenter=${data.value.ToCentre}`
      this.loginService.migrateExamCentre(url).subscribe(
        (res:any)=>{
          this.alert.success("Migrated", true);
          this.loaderService.hide();
        },
        error => {
          this.loaderService.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }


  //country list
  getAllCountry(stateId) {
    this.loginService.getallCountry(stateId).subscribe(
      (res:any)=>{
        this.countryList = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  //country selected event listener
  countrySelected(e) {
    this.countryValue = e;
    this.getState(this.countryValue);
  }


  //region list
  getAllRegion() {
    this.loginService.getAllRegions().subscribe(
      (res:any)=>{
        this.regionList = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
 //region selected event listener
  regionSelected(e) {
    this.regionValue = e;
    this.getAllCountry(this.regionValue);
  }

}
