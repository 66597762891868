import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../../service/baseLink";
import { Helper } from "../../../_model/helper";
import { FilterChangeModel } from "../../../_model/filter-change-model";
import {GridColumnType, PropertyMetaModel, PaginationModel } from "../../../_model/pagination-model";
import { FilterFieldModel, FieldType } from "../../../_model/filter-field-model";
import { ContactUsFilterModel, ContactUsGridResultModel, concernReplyModel } from "./contact-Us-modal";
import { SchoolFilterModel } from "../../../_model/school-models";
import { LoaderService } from "../../../service/loader.service";
declare var $: any;
@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"]
})
export class ContactUsComponent implements OnInit {
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  filter: ContactUsFilterModel;
  concern: concernReplyModel;
  contactUs: ContactUsGridResultModel = new ContactUsGridResultModel();
  public filterData: FilterChangeModel[] = new Array<FilterChangeModel>();
  public displayColumns: PropertyMetaModel[];
  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
  ) {
    this.Fields = [
      { FieldName: 'Name', Label: 'Name', Placeholder: 'Please enter name', FieldType: FieldType.Text },
      { FieldName: 'Email', Label: 'Email', Placeholder: 'Please enter email', FieldType: FieldType.Text },
    ];
    this.displayColumns = [
      { PropertyName: "Name", ColumnName: "Name", PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: "Email", ColumnName: "Email", PropertyType: GridColumnType.Text},
      { PropertyName: "PhoneNumber", ColumnName: "Mobile Number", PropertyType: GridColumnType.Text},
      { PropertyName: "Concern", ColumnName: "Query Type", PropertyType: GridColumnType.Text},
      { PropertyName: "Message", ColumnName: "Message", PropertyType: GridColumnType.Text},
      { PropertyName: "Answer", ColumnName: "Answer", PropertyType: GridColumnType.Text},
      { PropertyName: "IssueSolved", ColumnName: "Resolved", PropertyType: GridColumnType.Icon},
      { PropertyName: "Id", ColumnName: "Write Reply", PropertyType: GridColumnType.Icon, DisplayText: "Edit", ClassName: "mypointer" },
    ]
  }

  ngOnInit() {
    this.filter = new ContactUsFilterModel();
    this.concern = new concernReplyModel();
    this.getContactUs()
   
  }
  getContactUs(){
    this.loaderService.show();
    this.loginService.getContatctUsDetails(this.filter)
      .subscribe((res:any)=>{
        this.contactUs.List = res.List;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.filter.Take;
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
         this.alert.error(Helper.getMessageFromError(error), true);
      })
  }

  filterChange(event: any) {
    this.filter = new ContactUsFilterModel();
    event.forEach(element => {
      this.filter[element.FieldName] = element.Value || null;
    });
    this.getContactUs();
  }

  pageInfoChange(event) {
    this.filter.OrderBy = event.SortBy;
    this.filter.Order = event.SortOrder
    this.filter.Skip = (event.CurrentPage - 1) * event.PageSize;
    this.filter.Take = event.PageSize;
    this.getContactUs();
  }


  action(event)
  {
    if(event.ColumnName=="Edit")
    {
      this.concern = JSON.parse(JSON.stringify(event.value));
        $('#reply').modal('show');
    }
  }
  contactusAnswer(){
    this.loaderService.show()
    this.loginService.answerContatctUsDetails(this.concern)
      .subscribe((res:any)=>{
        this.alert.success("Answer Submitted", true);
        this.concern = new concernReplyModel();
         $('#reply').modal('hide');
         this.loaderService.hide();
        this.getContactUs();

      }, error => {
        this.loaderService.hide();
         this.alert.error(Helper.getMessageFromError(error), true);
      })
  }


//   getAllDetails()
// {   var dataPage = { Skip: this.Skip, Take: this.Take };
//     this.loginService.getContatctUsDetails(dataPage)
//       .subscribe((res:any)=>{
//         this.list = res;
//          this.list = res;
//          this.TotalRecords = res.TotalRecords;
//         this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
//         this.recordStarting = 1;
//           if (this.TotalRecords < this.Take) {
//             this.recordEnding = this.TotalRecords;
//           } else {
//             this.recordEnding = this.Take;
//           }
//       }, error => {
//          this.alert.error(Helper.getMessageFromError(error), true);
//       })

// }



//   EditBox(i, uid,item) {
//     this.index = i;
//     this.uid = uid;
//     $('#reply').modal('show');
//     this.upadating = item;
//   }
//   contactusAnswer() {
//     var data = {
//       "Id": this.uid ,
//       "Answer": this.solution
//     }
//     this.loginService.answerContatctUsDetails(data)
//       .subscribe((res:any)=>{
//         this.alert.success("Answer Submitted", true);
//         this.uid = undefined;
//             $('#reply').modal('hide');
//        this.getAllDetails();
//       }, error => {
//          this.alert.error(Helper.getMessageFromError(error), true);
//       })
//   }


//   refresh() {
//      var dataPage = { Skip: this.Skip, Take: this.Take };
//     this.loginService.getContatctUsDetails(dataPage)
//       .subscribe((res:any)=>{
//         this.list = res;
//          this.TotalRecords = res.TotalRecords;
//         this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
//       }, error => {
//          this.alert.error(Helper.getMessageFromError(error), true);
//       })
//   }


//  RowPerPage(e) {
//     this.totalRowPerPage = e.target.value;
//     this.Take = this.totalRowPerPage;
//     this.Skip = 0;
//     this.TotalPages = Math.ceil(25 / this.Take);
//     //page number
//     this.recordStarting = this.Skip;
//     var totalrecord = this.Skip + this.Take;
//     if (totalrecord == this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     }
//     else if (totalrecord > this.TotalRecords) {
//       this.recordEnding = this.TotalRecords;
//     }
//     else if (totalrecord < this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     }
//     this.refresh();
//   }

//   firstPage() {
//     this.Skip = 0;
//     this.Take = this.totalRowPerPage;
//     this.recordStarting = 1;
//     if (this.TotalRecords < this.Take) {
//       this.recordEnding = this.TotalRecords;
//     }
//     else {
//       this.recordEnding = this.Take;
//     }

//     this.refresh()
//   }
//   nextPage() {
    
//     if (this.recordEnding < this.TotalRecords) {
//       this.Skip += this.totalRowPerPage
//       this.Take = this.totalRowPerPage

//       //for page number
//       if(this.Skip ==0)
//       {
//         this.recordStarting = 1;
//       }
//       else
//       {
//       this.recordStarting = this.Skip;
//       }
//       var totalrecord = this.Skip + this.Take;

//       if (totalrecord == this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       }
//       else if (totalrecord > this.TotalRecords) {
//         this.recordEnding = this.TotalRecords;
//       }
//       else if (totalrecord < this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       }

//       this.refresh()
//     }
//   }
//   priviousPage() {
//     if (this.recordStarting > 1) {
//       this.Skip -= this.totalRowPerPage;
//       this.Take = this.totalRowPerPage;

//       //for page number
//       //for page number
//        if(this.Skip ==0)
//       {
//         this.recordStarting = 1;
//       }
//       else
//       {
//       this.recordStarting = this.Skip;
//       }

//       var totalrecord = this.Skip + this.Take;

//       if (totalrecord == this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       }
//       else if (totalrecord > this.TotalRecords) {
//         this.recordEnding = this.TotalRecords;
//       }
//       else if (totalrecord < this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       }

//       this.refresh()
//     }

//   }
//   lastPage() {
//     var lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
//     this.Skip = lastPage;
//     this.Take = this.totalRowPerPage

//     //for the page number
//      if(this.Skip ==0)
//       {
//         this.recordStarting = 1;
//       }
//       else
//       {
//       this.recordStarting = this.Skip;
//       }

//     var totalrecord = this.Skip + this.Take;

//     if (totalrecord == this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     }
//     else if (totalrecord > this.TotalRecords) {
//       this.recordEnding = this.TotalRecords;
//     }
//     else if (totalrecord < this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     }
//     this.refresh();
//   }

// }
//   // $('#faqEdit').modal('show');
}