export class candidateFilterModel {
    constructor() {
        this.Skip = 0;
        this.Take = 10;
        this.Order = "Asc";
    }
    Name: string;
    Email: string;
    Gender: string;
    StateId: number;
    CountryId: number;
    RegionId: number;
    SchoolId: number;
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}
export class candidateGridModel {
      Id: number;
      Name: string;
      Email: string;
      Gender: string;
      School: string;
      DOB: string;
      GuardianEmail: string;
      State: string;
}

export class candidateFilterResultModel {
    constructor() {
        this.List = new Array<candidateGridModel>();
    }
    List: candidateGridModel[];
    TotalRecords: number;
}