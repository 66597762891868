import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router } from "@angular/router";
import { Helper } from "../../_model/helper";
@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.css"]
})
export class ForgetPasswordComponent implements OnInit {
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router
  ) {}

  rForm: FormGroup;
  submittedError:boolean=false;
  patternMail: string = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  patternPhone: string = "[0-9]*";
  patternName: string = "[a-zA-Z ]*";
  mainloader: boolean = false;
  ngOnInit() {
    this.rForm = this.formBuilder.group({
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.patternMail)
        ])
      ]
    });
  }
goToHomePage()
{
  this.router.navigate(["../"])
}
  forget(data) {
    if (!data.valid) {
      this.submittedError=true;
    } else {
      this.mainloader = true;
      var email = JSON.stringify(data.value);
      var mail = JSON.parse(email).email;
      this.loginService.forgetpass(mail).subscribe(
        (res:any)=>{         
          this.alert.success("Check your mail", true);
          this.mainloader = false;
          this.rForm.reset();
        },
        error => {
          this.mainloader = false;
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
}
