import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ApiServerService } from 'src/app/service/api-server.service';
import { AlertService } from 'src/app/_service/alert.service';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';
import { Helper } from '../../_model/helper';

@Component({
  selector: 'app-country-drop-down',
  templateUrl: './country-drop-down.component.html',
  styleUrls: ['./country-drop-down.component.css']
})
export class CountryDropDownComponent implements OnInit, OnChanges {
  @Input() regionSelected: any;
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<any> = new EventEmitter();
  public countries: any;
  val = '';
  constructor(private loginService: ApiServerService, private alert: AlertService) { }
  ngOnInit() {
    this.loadCountries();
  }

  loadCountries(): any {
    this.countries = [];
    if (this.field.Filter) {
      this.loginService.getallCountry(this.field.Filter).subscribe(
        (res: any) => {
          this.countries = res;
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
  changeCountry(event, type) {
    const value = { FieldName: this.field.FieldName, Value: event.target.value, EventType: type };
    this.Changed.emit(value);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadCountries();
  }
  Reset() {
    this.val = '';
    this.field.Filter = null;
    this.loadCountries();
  }
}
