import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SchoolRoutingModule } from "./school-routing.module";
import { CompetitionInitialComponent } from "src/app/school/competition-initial/competition-initial.component";
import { CompetitionFinalComponent } from "src/app/school/competition-final/competition-final.component";
import { ExaminationSlipComponent } from "src/app/school/examination-slip/examination-slip.component";
import { AddCandidateComponent } from "src/app/school/add-candidate/add-candidate.component";
import { ViewAllCandidateComponent } from "src/app/school/view-all-candidate/view-all-candidate.component";
import { ViewPreviousCandidateComponent } from "src/app/school/view-previous-candidate/view-previous-candidate.component";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { BrowserModule } from "@angular/platform-browser";
import { SchoolHeaderComponent } from "src/app/school/school-header/school-header.component";
import { SchoolComponent } from "./school/school.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgDatepickerModule } from "ng2-datepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DraftCandidateComponent } from "./draft-candidate/draft-candidate.component";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from "../_shared/sharedmodule";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { TextFieldComponent } from "../_shared/text-field/text-field.component";

// import { PdfSlipComponent } from './pdf-slip/pdf-slip.component';
@NgModule({
  imports: [
    CommonModule,
    SchoolRoutingModule,
    BrowserModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgDatepickerModule,
    SharedModule,
    MatSlideToggleModule,
    MatCardModule,
   MatSliderModule,
   
   TooltipModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    SchoolHeaderComponent,
    CompetitionInitialComponent,
    CompetitionFinalComponent,
    ExaminationSlipComponent,
    AddCandidateComponent,
    ViewAllCandidateComponent,
    ViewPreviousCandidateComponent,
    SchoolComponent,
    DraftCandidateComponent,
    // PdfSlipComponent
  ],
  exports: [
    CompetitionInitialComponent,
    CompetitionFinalComponent,
    ExaminationSlipComponent,
    AddCandidateComponent,
    ViewAllCandidateComponent,
    ViewPreviousCandidateComponent,
    SchoolComponent
  ]
})
export class SchoolModule {}
