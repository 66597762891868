import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../service/data.service";
import { ApiServerService } from "../../../service/api-server.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertService } from "../../../_service/alert.service";
import { Helper } from "../../../_model/helper";
import { FilterFieldModel, FieldType } from "../../../_model/filter-field-model";
import { FilterChangeModel } from "../../../_model/filter-change-model";
import { PropertyMetaModel, PaginationModel, GridColumnType } from "../../../_model/pagination-model";
import { candidateResultModel, FilterResultModel } from "./candidateresult-model";
import { LoaderService } from "../../../service/loader.service";
@Component({
  selector: "app-school-result",
  templateUrl: "./school-result.component.html",
  styleUrls: ["./school-result.component.css"]
})
export class SchoolResultComponent implements OnInit {
  // school: any;
  // flag: boolean;
  filter: candidateResultModel;
  candidates: FilterResultModel = new FilterResultModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public filterData: FilterChangeModel[] = new Array<FilterChangeModel>();
  public displayColumns: PropertyMetaModel[];
  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };
  regionId: any = 0;
  Region: any;
  Country: any;
  countryId: any;
  LastPage: number;
  totalPage: any;
  showPagination: boolean;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alert: AlertService,
    private loaderService: LoaderService
  ) {
    let count = localStorage.getItem('country');
    count ? this.countryId = count : this.countryId = 0; 
    this.Fields = [
      {
        FieldName: 'Name',
        Label: 'Name',
        Placeholder: 'Please enter school name',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'Email',
        Label: 'Email',
        Placeholder: 'Please enter school email',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'Type',
        Label: 'School Type',
        Placeholder: 'Select School Type',
        FieldType: FieldType.SchoolTypeDropdown
      },
      {
        FieldName: 'Category',
        Label: 'Category',
        Placeholder: 'Select Category',
        FieldType: FieldType.SchoolCategoryDropdown
      },
    ];
    this.displayColumns = [
      {
        PropertyName: 'Name',
        ColumnName: 'Name',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Gender',
        ColumnName: 'Gender',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Region',
        ColumnName: 'Region',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'EmailId',
        ColumnName: 'EmailId',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Score',
        ColumnName: 'Score',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'State',
        ColumnName: 'State',
        PropertyType: GridColumnType.Text
      },
    ];
  }

  ngOnInit() {
    this.filter = new candidateResultModel();
    this.getCandidatesResult();
    this.getAllCountryList();
  }
  getAllCountryList(): any {
    this.loginService.getAllCountryList().subscribe(res => {
      if(res){
        this.Country = res;
      }
    }, err => {})
  }
  getCandidatesResult() {
    this.loaderService.show();
    this.filter.Take = this.pageInfo.PageSize;
    this.filter.Skip = (this.pageInfo.CurrentPage - 1) * this.pageInfo.PageSize;
    this.loginService.getCandidatesResult(this.filter, this.countryId).subscribe(
      (res:any)=>{
        if(res){
        this.candidates.List = res.List;
        this.pageInfo.PageSize = this.filter.Take;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.setPagination();
        this.loaderService.hide();
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
        this.loaderService.hide();
      }
    );
  }

  setPagination(): any {
    if (this.pageInfo && this.pageInfo.TotalRecord > this.pageInfo.PageSize) {
      const totalPages = Math.ceil(
        this.pageInfo.TotalRecord / this.pageInfo.PageSize
      );
      this.LastPage = totalPages;
      this.totalPage = this.getVisiblePageIndex(
        totalPages,
        this.pageInfo.CurrentPage
      );
      this.showPagination = true;
    } else {
      this.showPagination = false;
    }
  }
  
  getVisiblePageIndex(totalPages: number, CurrentPage: number) {
    const array = [];
    if (totalPages > 7) {
      let j = 1;
      for (let i = 0; i < 7 || array.length < 7; i++) {
        if (CurrentPage - (3 - i) >= 1 || CurrentPage - (3 - i) <= totalPages) {
          if (CurrentPage - (3 - i) > 0) {
            if (CurrentPage - (3 - i) <= totalPages) {
              array.push(CurrentPage - (3 - i));
            } else {
              array.unshift(CurrentPage - (3 + j));
              j++;
            }
          }
        }
      }
      return array;
    } else {
      for (let i = 1; i <= totalPages; i++) {
        array.push(i);
      }
      return array;
    }
  }

  changePageSize(){
    this.pageInfo.CurrentPage = 1;
    this.getCandidatesResult()
  }

  SetPage(index: number) {
    this.pageInfo.CurrentPage = Number(index);
    this.filter.Skip = (this.pageInfo.CurrentPage - 1)  * this.filter.Take;
    this.getCandidatesResult()
  }

  // getAllRegion() {
  //   this.loginService.getAllRegions().subscribe(
  //     (res: any) => {
  //       this.Region = res;  
  //     },
  //     error => {

  //     }
  //   );
  // }
  regionSelected(event){
    this.regionId = event.target.value;
    this.getCandidatesResult();
  }

  filterChange(event: any) {
    this.filter = new candidateResultModel();
    event.forEach(element => {
      this.filter[element.FieldName] = element.Value || null;
    });
    this.getCandidatesResult();
  }

  pageInfoChange(event) {
    this.filter.OrderBy = event.SortBy;
    this.filter.Order = event.SortOrder
    this.filter.Skip = (event.CurrentPage - 1) * event.PageSize;
    this.filter.Take = event.PageSize;
    this.getCandidatesResult();
  }

}
