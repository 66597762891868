import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';
import { FilterChangeModel } from 'src/app/_model/filter-change-model';

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.css']
})
export class NumberFieldComponent implements OnInit {
  @Output() Changed: EventEmitter<FilterChangeModel> = new EventEmitter<FilterChangeModel>();
  @Input() field: FilterFieldModel = new FilterFieldModel();
  public val: number;
  constructor() { }

  ngOnInit() {
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  numberChanged(event, type) {
    const value = { FieldName: this.field.FieldName, Value: event.target.value, EventType: type };
    this.Changed.emit(value);
  }
  Reset() {
    this.val = null;
  }
}
