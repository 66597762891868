import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router } from "@angular/router";
import { Helper } from "../../../_model/helper";
@Component({
  selector: "app-home-contact-us",
  templateUrl: "./home-contact-us.component.html",
  styleUrls: ["./home-contact-us.component.css"]
})
export class HomeContactUsComponent implements OnInit {
  rForm: FormGroup;
  patt: string = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$";
  pattphone: string = "[0-9]*";
  pattname: string = "[a-zA-Z ]*";
  submittedError:boolean=false;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      Name: ["", Validators.required],
      Email: [
        "",
        Validators.compose([Validators.required, Validators.pattern(this.patt)])
      ],
      PhoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.pattphone)
        ])
      ],
      Concern: ["", Validators.required],
      Message: ["", Validators.required]
    });
  }

  SendData(data) {
    if (!data.valid) {
      this.submittedError=true;
    }
    if (data.valid) {
      data.value.PhoneNumber = +data.value.PhoneNumber;
      this.loginService.contactUs(data.value).subscribe(
        (res:any)=>{
          this.alert.success("We will get back to you shortly", true);
          this.router.navigate(['']);
          this.rForm.reset();
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !/[\d\s]/.test(input);
  }
}
