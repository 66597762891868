import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Helper } from '../../../_model/helper';
import { ExamCenterModel, ExamCenterFilterResultModel } from '../../../_model/ExamCenter-model';
import { FilterFieldModel, FieldType } from '../../../_model/filter-field-model';
import { PropertyMetaModel, GridColumnType, PaginationModel } from '../../../_model/pagination-model';
import { LoaderService } from '../../../service/loader.service';
declare var $: any;

@Component({
  selector: 'app-centre-list',
  templateUrl: './centre-list.component.html',
  styleUrls: ['./centre-list.component.css']
})
export class CentreListComponent implements OnInit {
  filter: ExamCenterModel;
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public displayColumns: PropertyMetaModel[];
  examCenter: ExamCenterFilterResultModel = new ExamCenterFilterResultModel();
  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 6,
    TotalRecord: 0
  };
  deleteCandidateId: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute
  ) {
    this.Fields = [
      { FieldName: 'Name', Label: 'Name', Placeholder: 'Please enter center name', FieldType: FieldType.Text },
      { FieldName: 'RegionId', Label: 'Regions', Placeholder: 'Select Region', FieldType: FieldType.RegionDropdown },
      { FieldName: 'CountryId', Label: 'Country', Placeholder: 'Select Country', FieldType: FieldType.CountryDropdown, Filter: null },
      { FieldName: 'StateId', Label: 'State', Placeholder: 'Select State', FieldType: FieldType.StateDropdown, Filter: null }
    ];
    this.displayColumns = [
      { PropertyName: 'Name', ColumnName: 'Name', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'Address', ColumnName: 'Address', PropertyType: GridColumnType.Text },
      { PropertyName: 'MaxCapacity', ColumnName: 'MaxCapacity', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'Region', ColumnName: 'Region', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'Country', ColumnName: 'Country', PropertyType: GridColumnType.Text, IsSortable: true },

      { PropertyName: 'State', ColumnName: 'State', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'IsActive', ColumnName: 'IsActive', PropertyType: GridColumnType.Text},
      { PropertyName: 'IsActive', ColumnName: 'Make Active/InActive', PropertyType: GridColumnType.Switch, DisplayText: 'IsActiveDisable' },
      { PropertyName: 'Id', ColumnName: 'Delete', PropertyType: GridColumnType.Icon, DisplayText: 'Delete', ClassName: 'mypointer' },
      { PropertyName: 'Id', ColumnName: 'Edit', PropertyType: GridColumnType.Icon, DisplayText: 'Edit', ClassName: 'mypointer' },
    ];
  }

  ngOnInit() {
    this.filter = new ExamCenterModel();
    this.getAllExamCenter();
  }

  filterChange(event: any) {
    this.filter = new ExamCenterModel();
    event.forEach(element => {
      this.filter[element.FieldName] = element.Value || null;
    });
    this.getAllExamCenter();
  }

  getAllExamCenter() {
    this.loaderService.show();
    this.loginService.getAllExamCentre(this.filter).subscribe(
      (res: any) => {
        this.examCenter.List = res.List;
        this.examCenter.TotalRecords = res.TotalRecords;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.filter.Take;
        res.List.forEach(element => {
          element.IsActive ? element.IsActiveDisable = true : element.IsActiveDisable = false;
        });
        this.loaderService.hide();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
        this.loaderService.hide();

      }
    );
  }
  pageInfoChange(event) {
    this.filter.OrderBy = event.SortBy;
    this.filter.Order = event.SortOrder;
    this.filter.Skip = (event.CurrentPage - 1) * 10;
    this.filter.Take = event.PageSize;
    this.getAllExamCenter();
  }

  action(event) {
    if (event.ColumnName === 'Edit') {
      var curl = document.location.href;
      var urlaparam;
      if(curl.includes("supe")){
        urlaparam = 'super-admin';
      }
      else{
        urlaparam = 'admin'
      }
      this.data.changeMessage4(this.examCenter.List[event.index]);
      const url = "/" + urlaparam +`/add-exam-centre?uid=${event.value.Id}`;
      this.router.navigateByUrl(url);
    }
    if (event.ColumnName === 'Delete') {
      this.deleteCandidateId = event.value.Id;
      $('#deleteModel').modal('show');
    } else if (event.action === 'IsActive') {
      this.changeStatus(event.value.Id);
    }
  }
  changeStatus(Id: any): any {
    this.loginService.changeStatusOfExamCenter(Id).subscribe(res => {
      this.alert.success('Changed Successfully');
      this.getAllExamCenter();
    }, err => {
      this.alert.error(Helper.getMessageFromError(err), true);
    });
  }

  deleteConfirm() {
    this.loginService.examCentreDelete(this.deleteCandidateId).subscribe(
      (res: any) => {
        this.alert.success('Exam Centre Delete', true);
        $('#deleteModel').modal('hide');
        this.getAllExamCenter();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
}

