import { Component, OnInit } from '@angular/core';
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router } from "@angular/router";
import { Helper } from '../../_model/helper';
declare var $: any;
@Component({
  selector: 'app-draft-candidate',
  templateUrl: './draft-candidate.component.html',
  styleUrls: ['./draft-candidate.component.css']
})
export class DraftCandidateComponent implements OnInit {
  list: any;
  url: string = "";
  auth: any;
deleteId:any;
  //For pagination purpose
  Skip: number = 0;
  Take: number = 10;
  TotalRecords: number;
  TotalPages: number;
  totalRowPerPage: number = 10;

  recordStarting: number;
  recordEnding: number;
  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router
  ) {
    this.auth = JSON.parse(localStorage.getItem("auth"));
  }

  ngOnInit() {
    var dataPage = { Skip: this.Skip, Take: this.Take ,FlagSubmit:false };
    this.loginService.getCandidateSchoolWise(dataPage).subscribe(
      (res:any)=>{
        // this.alert.success("All Candidate Fetched", true);
        this.list = res;
        this.TotalRecords = res.TotalRecords;
        this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
        this.recordStarting = 1;
           if(this.TotalRecords<10)
        {
          this.recordEnding = this.TotalRecords;
        }
        else
        {
           this.recordEnding = this.Take;
        }
        // this.recordEnding = this.Take;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  //for deleteing the candidtate
  DeleteCandidate(i,Id) {
    this.deleteId = Id;
     $("#deleteCandidate").modal("show");

  }

deleteCandidateConfirm()
{
    this.loginService.candidatedelete(this.deleteId).subscribe(
      (res:any)=>{
        this.alert.success("Succesfully Deleted", true);
         $("#deleteCandidate").modal("hide");
        this.refresh();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
}

  //for updating the candidate
  updateCandidate(id, uid) {
    this.data.changeMessage4(this.list.List[id]);
    this.url = `school/candidate-update/${id}/${uid}`;
    this.router.navigate([this.url]);
  }

  refresh() {
    var dataPage = { Skip: this.Skip, Take: this.Take,FlagSubmit:false };
   
    this.loginService.getCandidateSchoolWise(dataPage).subscribe(
      (res:any)=>{
        this.list = res;
   
        this.TotalRecords = res.TotalRecords;
        this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  addNewCandidate() {
    this.router.navigate(["school/add-candidate"]);
  }

  rowPerPage(e) {
    this.totalRowPerPage = e.target.value;
    this.Take = this.totalRowPerPage;
    this.Skip = 0;
    this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
    //page number
        if (this.Skip == 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }
    // this.recordStarting = this.Skip;
    var totalrecord = this.Skip + this.Take;
    if (totalrecord == this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }

  firstPage() {
    this.Skip = 0;
    this.Take = this.totalRowPerPage;
    this.recordStarting = 1;
    if (this.TotalRecords < this.Take) {
      this.recordEnding = this.TotalRecords;
    } else {
      this.recordEnding = this.Take;
    }

    this.refresh();
  }
  nextPage() {
  
    if (this.recordEnding < this.TotalRecords) {
      this.Skip += this.totalRowPerPage;
      this.Take = this.totalRowPerPage;
      //for page number
      if (this.Skip == 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip +1;
      }
      var totalrecord = this.Skip + this.Take;
      if (totalrecord == this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  priviousPage() {
    if (this.recordStarting > 1) {
      this.Skip -= this.totalRowPerPage;
      this.Take = this.totalRowPerPage;

      //for page number
      //for page number
      if (this.Skip == 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip +1;
      }

      var totalrecord = this.Skip + this.Take;

      if (totalrecord == this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  lastPage() {
    var lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
    this.Skip = lastPage;
    this.Take = this.totalRowPerPage;

    //for the page number
    if (this.Skip == 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip +1 ;
    }

    var totalrecord = this.Skip + this.Take;

    if (totalrecord == this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }
}
