// Backend
export const BaseUrl = 'https://api.interswitchspak.com/';
//export const BaseUrl = 'https://apikenya.interswitchspak.com/';
// export const BaseUrl = 'http://10.0.0.19:49790/';
// export const BaseUrl = 'http://192.168.1.16:49790/';

// export const BaseUrl = "http://10.0.0.2:5810/";
// export const BaseUrl = "http://10.0.0.12:49794/";
// export const BaseUrl = "http://10.0.0.18:53771/";
// for the login
export const login = `${BaseUrl}Token`;

export const ActivateExamCenter = `${BaseUrl}api/ExamCenter/ActivateExamCenter?id=`;

export const getExamCentreWithId = `${BaseUrl}api/ExamCenter/`;

export const updateExamCenter = `${BaseUrl}api/ExamCenter`;

export const getCompletedRegSchoolsNg = `${BaseUrl}api/admin/GetAllRegistrationSchoolNg`;

export const getCompletedRegSchoolsKe = `${BaseUrl}api/admin/GetAllRegistrationSchoolKe`;

export const GetSeasonDetail = `${BaseUrl}api/season/GetSeasonDetail?id=`;

export const updateSeason = `${BaseUrl}api/Season/`;

export const candidateResultModel = `${BaseUrl}api/Public/GetQualifiedCandidate?CountryId=`;

export const addSeasonmanagement = `${BaseUrl}api/season/AddSeasonRegionData`;

export const getallSeasonMAnagementData  = `${BaseUrl}api/season/GetAllSeasonRegionData`;

export const getSeasonManagementDataById = `${BaseUrl}api/season/GetSeasonRegionDataById?Id=`;

export const updateSeasonmanagement = `${BaseUrl}api/season/UpdateSeasonRegionData`;

export const getActiveSeason = `${BaseUrl}api/Public/GetActiveSeason`;

export const completeSeason = `${BaseUrl}api/season/MarkSeasonComplete?Id=`;

export const deleteCandidateAsAdmin = `${BaseUrl}/api/admin/deletecandidate?id=`;

// for the forget password
export const forgetpass = `${BaseUrl}api/Public/forgetpasword?emailId=`;

// for the change password
export const changePassword = `${BaseUrl}api/Public/change-password?Password=`;

// for the school registration
export const schoolRegistration = `${BaseUrl}api/Account/Register`;
// Modify School
export const schoolModify = `${BaseUrl}api/school/ModifySchool`;
// get state list
export const getState = `${BaseUrl}api/Public/GetStateId?CountryId=`;

export const getRegisteredCandidateOfExamCenter = `${BaseUrl}api/ExamCenter/GetCandidateForExamCenter?ExamCenterId=`;

export const getExamCenterCandidateReport = `${BaseUrl}api/admin/GetExamCenterCandidateReport`;

export const getStateSchool = `${BaseUrl}api/Public/GetSchoolStates`;

// get country list
export const getCountry = `${BaseUrl}api/Public/GetCountries?RegionId=`;

export const getCountryList = `${BaseUrl}api/Public/GetCountriesList?RegionId=0`;

export const getMinNoOfGirls = `${BaseUrl}api/school/GetSchoolSeasonData`;


// get region list
export const getRegion = `${BaseUrl}api/Public/GetRegions`;

// get candidate school wise
export const getCandidateSchoolWise = `${BaseUrl}api/school/GetAllCandidate`;

// get candidate school wise
export const getclass = `${BaseUrl}api/Public/GetAllClasses`;

export const getResultStatus = `${BaseUrl}api/Public/IsResultPublished?isPublished=`;

// sending otp to email
export const sendingOtpToEmail = `${BaseUrl}api/public/GenerateEmailOTP?Email=`;

// verfication of email otp
export const verficationEmailOtp = `${BaseUrl}api/Public/api/verifyEmailOTP?`;

// sending otp to phone
export const sendingOtpToPhone = `${BaseUrl}api/Public/api/verifyPhone`;

// verification of otp of phone
export const verficationPhoneOtp = `${BaseUrl}api/VerifyPhoneOTP?otp=`;

// adding new candidate in school
export const candidateRegistration = `${BaseUrl}api/school/CreateCandidate`;

export const candidateModify = `${BaseUrl}api/school/ModifyCandidate`;

// get setting in super admin
export const getSetting = `${BaseUrl}api/admin/GetSetting`;

// change setting in super admin
export const changeSetting = `${BaseUrl}api/admin/UpdateSetting`;

// get all the authority list
export const getAllAuthority = `${BaseUrl}api/Account/GetAllUsers`;

// for register or upade of  admin and examiner
export const addAuthority = `${BaseUrl}api/Account/RegisterAdmin`;

// change the status of the authority
export const statusChange = `${BaseUrl}api/admin/ActivateDeactivateUser`;

// to get the list of season
export const getSeason = `${BaseUrl}api/Public/GetActiveSeason`;

// for adding exam centre
export const addExamCentre = `${BaseUrl}api/ExamCenter`;

// get all exam Centre list
export const getAllExamCentre = `${BaseUrl}api/admin/GetAllExamCenter`;

// exam centre  delete
export const examCentreDelete = `${BaseUrl}api/ExamCenter/DeleteExamCenter?id=`;

// change the status of the examCentre
export const changeStatusExamCentre = `${BaseUrl}api/portal/secure/examcentre/updatestatus`;

// to perform partial migration
export const partiallyMigrate = `${BaseUrl}api/ExamCenter?MigratedToCenter=`;

// for the exam centre
export const examCentreIdwise = `${BaseUrl}api/Public/GetExamCenter?stateId=`;

// to get list of candidate
export const getAllCandidatedata = `${BaseUrl}api/portal/secure/candidate/getallcandidate`;
export const getCandidateScores = `${BaseUrl}api/Examiner/GetAllCandidate`;
export const getCandidateScoresExcel = `${BaseUrl}api/Examiner/GetCandidateDataForMarksUpload`;
export const uploadCandidateScores = `${BaseUrl}api/Examiner/UploadCandidateScoreExcel`;

export const markCandidateAbsent = `${BaseUrl}api/Examiner/MarkCandidateAbsent`;

export const getAllCandidatedataAsAdmin = `${BaseUrl}api/admin/GetAllCandidate`;

// migrate the exam centre
export const migrateExamCentre = `${BaseUrl}api/ExamCenter?MigrateFrom=`;

// add season
export const addSeason = `${BaseUrl}api/Season`;

// get list all the season
export const getAllSeasonDD = `${BaseUrl}api/season/GetSeasonDD`;

export const getAllSeasonList = `${BaseUrl}api/admin/GetAllSeason`;

// delete the season
export const deleteSeason = `${BaseUrl}api/secure/admin/deleteseason?Id=`;

//  //delete the season
//  export const deleteSeason = `${BaseUrl}api/secure/admin/deleteseason`;

// activate season
export const activateSeason = `${BaseUrl}api/season/Activate?Id=`;

// get all school data
export const getAllSchoolData = `${BaseUrl}api/admin/getallschools`;

// delete candidate
export const candidateDelete = `${BaseUrl}api/school/deletecandidate?id=`;

// get the contact report and by the filter
export const getSchoolContactReport = `${BaseUrl}api/admin/GeSchoolContactReport`;
export const getCandidateContactReport = `${BaseUrl}api/admin/GetCandidateContactReport`;
export const getGaurdianContactReport = `${BaseUrl}api/admin/GeParentContactReport`;

// ParentNumber
export const downloadSchoolContactReport = `${BaseUrl}api/SchoolContactsToExcel?take=`;

// For downloading candidate contactsFor downloading candidate contacts
export const downloadCandidateContactReport = `${BaseUrl}api/CandidateContactsToExcel?take=`;

// For downloading parent contacts
export const downloadParentContactReport = `${BaseUrl}api/ParentContactsToExcel?take=`;

// school report filter wise
export const schoolReport = `${BaseUrl}api/admin/GetSchoolRegistrationReport`;

// school report download
export const schoolReportDownload = `${BaseUrl}api/SchoolRegistrationToExcel?limit=`;

// report of candidate in admin season wise
export const candidateReportSesaonWise = `${BaseUrl}api/admin/GetCandidateRegistrationReport`;

// report of candidate in admin season wise to download
export const candidateReportSesaonWiseDownload = `${BaseUrl}api/CandidateRegistartionToExcel?season=`;

// central utilization report
export const centralUtilizationReport = `${BaseUrl}api/admin/GetExamCenterUtilizationReport`;

// central utilization report download
export const centralUtilizationReportdownload = `${BaseUrl}api/ExamCenterRegistartionToExcel`;

// get all exam centre list
export const getAllExamCentreList = `${BaseUrl}api/portal/secure/examcentre/getallexamcentre`;

// centre candidate report
export const centreCandidateReport = `${BaseUrl}api/portal/secure/candidate/getcandidateforExamCentre`;

// centre candidate report download
export const centreCandidateReportDownload = `${BaseUrl}api/ExamCentreCandidates?id=`;

// send the faq from home
export const sendFaq = `${BaseUrl}api/admin/UpdateFAQ`;

// get the faq for admin
export const getFaqAdmin = `${BaseUrl}api/Public/GetAllFaqs`;

export const getFaqForAdmin = `${BaseUrl}api/admin/GetFaqAdmin?CountryId=`;

// getallcontactUs detatils
export const getcontactUsDetails = `${BaseUrl}api/admin/GetAllEnquiries`;

// sendContactUs
export const sendContactUsDetailsAnswer = `${BaseUrl}api/admin/AnswerEnquiry`;

// to get the year for the home page ..past question
export const getYear = `${BaseUrl}api/Public/GetAllQuestionsYear?ClassId=`;

// to get past question
export const getPastQuestionList = `${BaseUrl}api/Public/GetAllQuestions?year=`;

// upload pdf past year question paper
export const pastYearQuestionPaper = `${BaseUrl}api/admin/AddPastQuestionandAnswer?year=`;

// Score update in the examiner section
export const updateScore = `${BaseUrl}api/Examiner/UpdateCandidateScore`;

// publish result in the examiner section
export const publishResult = `${BaseUrl}api/Examiner/PublishResult`;

// upload the the excel file for the result publish in the examiner section
export const uploadExcel = `${BaseUrl}api/portal/secure/candidate/uploadmarks`;

// Get FAQ for the home page
export const faq = `${BaseUrl}api/Public/GetAllFaqs?CountryId=`;

// contact us
export const contactUs = `${BaseUrl}api/Public/CreateEnquiry`;

// school profile data
//  export const schoolProfile = `${BaseUrl}api/GetDatafromToken`;
export const schoolProfile = `${BaseUrl}api/school/GetDetails`;

// to get candidate class wise
export const getCandidateClassWiseUrl = `${BaseUrl}api/portal/secure/candidate/getCandidateByClass`;

// for the exam centre list
export const getExamCentreListUrl = `${BaseUrl}api/portal/secure/candidate/getexamcentrebystate?state=`;

export const getAllExamCentreListUrl = `${BaseUrl}api/Public/GetExamCenter`;

export const getExamCentreListSchoolWise = `${BaseUrl}api/Public/GetExamCenter?stateId=`;

// for competetition registeration
export const registerForCompetitionUrl = `${BaseUrl}api/school/RegisterCandidateforCompetition?ExamCenterId=`;

// delete the authority
export const deleteAuthorityUrl = `${BaseUrl}api/admin/deleteUser`;

export const updateAuthorityPass = `${BaseUrl}api/Account/SetPassword`;

// Delete FAQ
export const deletFaqUrl = `${BaseUrl}api/admin/DeleteFAQ?Id=`;

// add data to home
export const addMainData = `${BaseUrl}api/admin/UpdateStaticContent`;

// Desqualify Candidate From Examiner
export const disqualifyUrl = `${BaseUrl}api/Examiner/DisqualifyCandidate`;

// main Edited Data
export const getAllEditedDataUrl = `${BaseUrl}api/Public/GetAllStaticData?CountryId=`;

// to update school data
export const updateSchoolProfileUrl = `${BaseUrl}api/portal/secure/school/updateschool`;

// get all guidelines
export const getGuidelines = `${BaseUrl}api/Public/GetAllGuideLines?CountryId=`;

export const getGuidelinesAdmin = `${BaseUrl}api/admin/GetGuidelinesAdmin?CountryId=`;

// add guidelines
export const addGuidelines = `${BaseUrl}api/admin/UpdateGuidelines`;

export const updateSyllabus = `${BaseUrl}api/admin/UpdateSyllabus`;

// delete guideline
export const deleteGuidelines = `${BaseUrl}api/admin/DeleteGuidelines?Id=`;

// get syllabus
export const syllabus = `${BaseUrl}api/Public/GetAllSyllabus?CountryId=`;

export const syllabusAdmin = `${BaseUrl}api/admin/GetSyllabusAdmin?CountryId=`;

// add syllabus
export const addSyllabus = `${BaseUrl}api/AddSyllabus`;

// delete syllabus
export const deleteSyllabus = `${BaseUrl}api/admin/DeleteSyllabus?Id=`;

// season deactivate
export const DeactiveSeason = `${BaseUrl}api/secure/admin/markseasoncomplete?Id=`;

export const result = `${BaseUrl}api/Public/GetCandidateResult?registrationNumber=`;
export const topSchool = `${BaseUrl}api/portal/secure/school/getTopSchoolResult`;
export const resultReport = `${BaseUrl}api/Public/GetCandidateResult`;

export const emailPattern = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
//  export const emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$";
export const phonePattern = '[0-9]*';
export const alphabhatpattern = '[a-zA-Z ]*';
export const alphaNumeric = '[a-zA-Z0-9]*';

// add location

// add region
export const addNewregion = `${BaseUrl}api/admin/UpdateRegion`;
export const addNewCountry = `${BaseUrl}api/admin/UpdateCountry?RegionId=`;
export const addNewState = `${BaseUrl}api/admin/UpdateState?CountryId=`;
export const allLocationData = `${BaseUrl}api/getstatecountryregion`;
export const allLocationDataAdmin = `${BaseUrl}api/admin/GetLocation`;

// delete region
export const deleteRegion = `${BaseUrl}api/admin/DeleteRegion?Id=`;
// delete country
export const deleteCountry = `${BaseUrl}api/admin/DeleteCountry?Id=`;
// delete state
export const deleteState = `${BaseUrl}api/admin/DeleteState?Id=`;

// get register candidate slip
export const examinationSlip = `${BaseUrl}api/portal/secure/candidate/getallcandidateforschool?school=`;

// download examination slip
export const downloadExaminationSlip = `${BaseUrl}GET api/portal/secure/candidate/downloadfile?url=`;
export const getUserInfo = `${BaseUrl}api/Account/UserInfo`;
export const saveImage = `${BaseUrl}api/Public/ImageUpload?IsChrome=`;
export const allCompleteCandidate = `${BaseUrl}api/school/GetAllRegisteredCandidate?classId=`;
export const getSchoolAsAdminUrl = `${BaseUrl}api/adminschool`;
export const getCandidateById = `${BaseUrl}api/school/GetCandidateDetails?Id=`;
export const schoolRegistrationAsAdmin = `${BaseUrl}api/adminschool/0`;
export const getCandidateByIdAsAdmin = `${BaseUrl}api/admin/GetCandidateDetails?Id=`;

// get Exam type for season
export const getExamType = `${BaseUrl}api/Public/GetExamType`;

// save Exam type for season
export const updateAdminSetting = `${BaseUrl}api/admin/UpdateAdminSetting`;

// get Exam type for season
export const getAdminSetting = `${BaseUrl}api/admin/GetAdminSetting`;