import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ExcelService } from '../../../_model/exceldownload.helper';
import { Helper } from '../../../_model/helper';

import {
  PropertyMetaModel,
  PaginationModel,
  GridColumnType
} from 'src/app/_model/pagination-model';
import { FilterFieldModel, FieldType } from 'src/app/_model/filter-field-model';
import {
  CandidateRegReportFilterModel,
  CandidateRegReportGridModel
} from './candidateregreportfiltermodel';
import { LoaderService } from '../../../service/loader.service';
import { pdfPotrait } from '../../../_model/pdfDownloadHelper';

@Component({
  selector: 'app-candidate-reg-report',
  templateUrl: './candidate-reg-report.component.html',
  styleUrls: ['./candidate-reg-report.component.css']
})
export class CandidateRegReportComponent implements OnInit {
  public displayColumns: PropertyMetaModel[];
  public filter: CandidateRegReportGridModel = new CandidateRegReportGridModel();
  public pageInfo = new PaginationModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public gridData: CandidateRegReportGridModel[] = [];
  public pdfColumns = [
    { title: "Name", dataKey: "Name" },
    { title: "Gender", dataKey: "Gender" },
    { title: "Registration Date", dataKey: "RegistrationDate" },
    { title: "Registration Status", dataKey: "RegistrationStatus" },
    { title: "Schoo Name", dataKey: "SchoolName" },
    { title: "School State", dataKey: "SchoolState" },
    { title: "Candidate State", dataKey: "State" }
  ]

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService,
    private loaderService: LoaderService
  ) {
    this.displayColumns = [
      {
        PropertyName: 'Name',
        ColumnName: 'Candidate Name',
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: 'Gender',
        ColumnName: 'Gender',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'SchoolName',
        ColumnName: 'School Name',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'SchoolState',
        ColumnName: 'School State',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'RegistrationStatus',
        ColumnName: 'Status',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'RegistrationDate',
        ColumnName: 'RegistrationDate',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'AddCaptureDevice',
        ColumnName: 'Candidate Added Device',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'RegistrationCaptureDevice',
        ColumnName: 'Registration Device ',
        PropertyType: GridColumnType.Text
      }
    ];
    this.Fields = [
      {
        FieldName: 'Name',
        Label: 'Candidate Name',
        Placeholder: 'Please enter candidate name',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'Gender',
        Label: 'Gender',
        Placeholder: 'Please enter gender',
        FieldType: FieldType.SchoolTypeDropdown
      },
      {
        FieldName: 'Region',
        Label: 'Region',
        Placeholder: 'Please enter',
        FieldType: FieldType.RegionDropdown
      },
      {
        FieldName: 'Country',
        Label: 'Country',
        Placeholder: 'Please Select',
        FieldType: FieldType.CountryDropdown
      },
      {
        FieldName: 'SchoolName',
        Label: 'SchoolName',
        Placeholder: 'Please enter school name',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'SeasonId',
        Label: 'Season',
        Placeholder: 'Please Select',
        FieldType: FieldType.SeasonDropdown
      },
      {
        FieldName: 'RegistrationStatus',
        Label: 'Registration Status',
        Placeholder: 'Please Select',
        FieldType: FieldType.RegistrationStatusDropdown
      },
      {
        FieldName: 'RegistrationDateFrom',
        Label: 'Registration Date From',
        Placeholder: 'dd/mm/yyyy',
        FieldType: FieldType.RegistrationDateFrom
      },
      {
        FieldName: 'RegistrationDateTo',
        Label: 'Registration Date To',
        Placeholder: 'dd/mm/yyyy',
        FieldType: FieldType.RegistrationDateTo
      }
    ];
  }

  ngOnInit() {
    this.getAllCadidateReport();
  }

  getAllCadidateReport(): any {
    this.gridData = [];
    const page: any = {};
    page.Skip = (this.pageInfo.CurrentPage - 1) * this.pageInfo.PageSize;
    page.Take = this.pageInfo.PageSize;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loaderService.show();
    this.loginService.candidateReportSesaonWise(page, this.filter).subscribe(
      (res: any) => {
        this.gridData = res.List;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.pageInfo.PageSize;
        this.loaderService.hide();
      },
      error => {
        this.loaderService.hide();
       }
    );
  }

  action(event: any) {
  }

  pageInfoChange(event: PaginationModel) {    
    this.pageInfo.CurrentPage = event.CurrentPage;
    this.pageInfo.PageSize = event.PageSize;
    this.pageInfo.SortOrder = event.SortOrder;
    this.pageInfo.SortOrder = event.SortOrder;
    this.getAllCadidateReport();
  }

  filterChange(event: any) {
    if (event && event.length > 0) {
      event.forEach(element => {
        this.filter[element.FieldName] = element.Value;
      });
    } else {
      this.filter = new CandidateRegReportGridModel();
    }
    this.pageInfo.CurrentPage = 1;
    this.getAllCadidateReport();
  }

  download() {
    const page: any = {};
    page.Skip = 0;
    page.Take = 1000000;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loginService.candidateReportSesaonWise(page, this.filter).subscribe(
      (res: any) => {
        this.downloadFile(res.List);
      },
      error => { }
    );
  }

  downloadFile(data: any) {
    this.excelService.exportAsExcelFile(data, 'Candidate Registration Report:');
  }
  downloadPDF() {
    const page: any = {};
    page.Skip = 0;
    page.Take = 1000000;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loginService.candidateReportSesaonWise(page, this.filter).subscribe(
      (res: any) => {
        if(res){
          pdfPotrait.exportPdfportrait(res, "CandidateRegistrationReport:", this.pdfColumns, 'Candidate Registration Report', 'p');
        }
      },
      error => { }
    );
  }
}
