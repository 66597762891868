import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../service/api-server.service';
import { DataService } from '../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import * as myapi from './../../../app/service/baseLink';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { Helper } from '../../_model/helper';
import { LoaderService } from '../../service/loader.service';
import { setRootDomAdapter } from '@angular/platform-browser/esm2015/src/dom/dom_adapter';
declare var $: any;
declare var jic: any;
declare var window: any;

@Component({
  selector: 'app-add-candidate',
  templateUrl: './add-candidate.component.html',
  styleUrls: ['./add-candidate.component.css'],
  providers: [DatePipe]
})

export class AddCandidateComponent implements OnInit {
  Adminuid: any;
  Phonetype: any;
  count: number = 0;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private _location: Location,
    private loader: LoaderService
  ) {
    this.reader.onload = (e: any) => {
      this.uploadSchoolImage = e.target.result;
    };
    this.setMaxDob.setFullYear(this.setMaxDob.getFullYear() - 13);
    this.setMinDob.setFullYear(this.setMinDob.getFullYear() - 18);
  }
  rForm: FormGroup;
  uploadSchoolImage: any; // uploaded school image
  uploadImageEvent: any; // uploaded image event
  stateList: any; // list of state
  classList: any; // list of class
  date: any;
  phoneNumber: any; // for storing the phone number
  phoneOtp: any;
  reader = new FileReader();
  setGenderFieldDisabled = false;
  guardianEmailLoader = false;
  personalLoader = false;
  otpEmail: any; // otp of email store
  type: any; // it is set at email verfication level
  Email: any; // for all type of email
  phone: any; // for storing the phone
  setMaxDob: any = new Date();
  setMinDob: any = new Date();


  isVerifiedPersonalEmail: boolean;
  isVerfiedGaurdianEmail: boolean;
  isVerifiedGaurdianPhone: boolean;
  isVerifiedNumber: boolean;
  imageToVerify: any;
  GurdianEmailOTP: any = null;
  personalEmailOTP: any;
  isGaurdianEmailSubmitted: boolean;
  isPersonalEmailSubmitted: boolean;
  id: any;
  uid: any;
  draftError = false;
  submittedError = false;

  candidateData: any; // data obtained from the previous component using the message the service
  Profileimage: any = 'assets/img/avatar_bbosch.jpg';
  options: DatepickerOptions = {
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      SchoolId: [''],
      FirstName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(myapi.alphabhatpattern)
        ])
      ],
      LastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(myapi.alphabhatpattern)
        ])
      ],
      DOB: ['', Validators.required],
      StateId: [null, Validators.required],
      Gender: ['', Validators.required],
      Email: ['', Validators.compose([Validators.required,Validators.pattern(myapi.emailPattern)])],
      number: [
        '',
        Validators.compose([
          Validators.pattern(myapi.phonePattern)
        ])
      ],
      GuardiansName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(myapi.alphabhatpattern)
        ])
      ],
      GuardiansEmail: [
        '',
        Validators.compose([Validators.pattern(myapi.emailPattern)])
      ],
      GuardiansPhone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(myapi.phonePattern)
        ])
      ],
      file: [''],
      flagsubmit: [''],
      ClassId: [null, Validators.required],
      Picture: ['', Validators.required],
      Id: [''],
      Season: ['']
    });

    this.getClass();

    this.getSeason();
    if (JSON.parse(localStorage.getItem('SchoolDetails'))) {
      const getItemGender = JSON.parse(localStorage.getItem('SchoolDetails')).Type;
      if (getItemGender === 'Boy' || getItemGender === 'Girl') {
        this.rForm.get('Gender').setValue(getItemGender);
        this.setGenderFieldDisabled = true;
      }
    }


    this.activatedRoute.params.subscribe((params: Params) => {
      this.uid = params['uid'];
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.Adminuid = params['uid'];
    });
    if (this.uid) {
      this.getcandidateDetails();
    } else if (this.Adminuid) {
      this.getcandidateDetailsAsAdmin();
    }
  }
  getcandidateDetailsAsAdmin() {
    if (this.Adminuid) {
      this.loginService.getCandidateByIdAsAdmin(this.Adminuid).subscribe(res => {
        this.setCandidateDetails(res);
      }, err => { });
    }
  }
  setCandidateDetails(res: any): any {
    if (res && res != null) {
      this.candidateData = res;
      this.rForm.get('Id').setValue(res.Id);
      this.rForm.get('FirstName').setValue(res.FirstName);
      this.rForm.get('LastName').setValue(res.LastName);
      const latest_date = this.datePipe.transform(
        res.DOB,
        'MM-dd-yyyy'
      );
      this.rForm.get('DOB').setValue(latest_date);
      this.rForm
        .get('StateId')
        .setValue(res.StateId);
      this.rForm.get('Email').setValue(res.Email);
      this.rForm.get('number').setValue(res.number);
      this.rForm.get('Gender').setValue(res.Gender);
      this.rForm
        .get('GuardiansName')
        .setValue(res.GuardiansName);
      this.rForm
        .get('GuardiansEmail')
        .setValue(res.GuardiansEmail);
      this.rForm
        .get('GuardiansPhone')
        .setValue(res.GuardiansPhone);
      this.rForm.get('file').setValue(res.Picture);
      this.rForm.get('Picture').setValue(res.Picture);
      this.rForm.get('ClassId').setValue(res.ClassId);
      if (res.Email) { this.isVerifiedPersonalEmail = true; }
      if (res.GuardiansEmail) { this.isVerfiedGaurdianEmail = true; }
      if (res.GuardiansPhone) { this.isVerifiedGaurdianPhone = true; }
      if (res.number) { this.isVerifiedNumber = true; }

      if (res.Picture) {
        this.Profileimage = myapi.BaseUrl + res.Picture;
      }

    }
  }

  getcandidateDetails() {
    if (this.uid) {
      this.loginService.getCandidateById(this.uid).subscribe(res => {        
        this.setCandidateDetails(res);
      }, err => { });
    }
  }
  // public EmailCheck() {
  //   if (this.Email !== this.candidateData.Email) {
  //     this.isVerifiedPersonalEmail = false;
  //   } else {
  //     this.isVerifiedPersonalEmail = true;
  //   }
  // }

  PhoneCheck() {
    if (this.phone !== this.candidateData.number) {
      this.isVerifiedGaurdianPhone = false;
    } else {
      this.isVerifiedGaurdianPhone = true;
    }
  }
  PrincipalPhoneCheck(event) {
    if (event.target.value !== this.candidateData.GuardiansPhone) {
      this.isVerifiedGaurdianPhone = false;
    } else {
      this.isVerifiedGaurdianPhone = true;
    }
  }
  getSeason() {
    this.loginService.getSeason().subscribe((res: any) => {
      if (res) {
        localStorage.setItem('SeasonDetails', JSON.stringify(res));
        const seasonName = res.SeasonName;
        this.rForm.get('Season').setValue(seasonName);
      }
    }, err => { });
  }

  // image upload listener
  imageUpload(event) {
    if (event.target.files[0].type.includes('image/')) {
      this.uploadImageEvent = event; // copy the event for the further uses
      // to render the pic on the screen
      this.uploadSchoolImage = event.target.files[0];
      this.reader.readAsDataURL(event.target.files[0]);
    } else {
      this.alert.error('Please Select Valid Image File.');
    }
  }


  public resizeImage() {       
    let imgFile;
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (typeof (this.uploadSchoolImage) === 'string') {
      imgFile = this.dataURLtoFile(this.uploadSchoolImage, 'myfile.jpg');
    } else {
      imgFile = this.uploadSchoolImage;
    }
    if (imgFile && imgFile.size > 204800 && this.count < 1) {
      !isChrome ? this.count++ : this.count = 0;
      const src_img: any = document.getElementById('myprofileimg');
      let percentage = 200 * 100 / (imgFile.size / 1024);    
      const targetImg = jic.compress(src_img, Math.floor(percentage), 'jpg').src;
      const file = this.dataURLtoFile(targetImg, imgFile.name);
      this.uploadSchoolImage = file;
      this.imageToVerify = file;
      src_img.src = targetImg;
      if (file && file.size > 204800 && isChrome) {        
        this.resizeImage();
      } else {
        this.reader.readAsDataURL(this.uploadSchoolImage);
      }
    } else {
      this.count = 0;
      this.imageSave(imgFile, isChrome);
    }
  }
  imageSave(imgFile, isChrome) {
    if (typeof (imgFile) === 'object') {
      const form = new FormData();
      form.append('file', imgFile);
      this.loginService.saveImage(form, isChrome).subscribe(res => {
        this.rForm.get('Picture').setValue(res);
      }, err => { });
    }

  }



  dataURLtoFile(dataurl, filename) {
    // tslint:disable-next-line:prefer-const
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      // tslint:disable-next-line:prefer-const
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getState() {
    this.loginService.getStateSchool().subscribe(
      (res: any) => {
        this.stateList = res;
        // this.getcandidateDetails();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // get class in list
  getClass() {
    this.loginService.getclass().subscribe(
      (res: any) => {
        this.classList = res;
        this.getState();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // verify email
  SendEmailOTP(email, type) {
    if (email.length === 0) {
      this.alert.error('Enter valid email', true);
      return;
    }
    this.Email = email;
    this.type = type;
    this.type === 'guardianEmail' ? this.guardianEmailLoader = true : this.personalLoader = true;
    this.loginService.sendingOtpToEmail(email).subscribe(
      (res: any) => {
        // this.alert.success('check your email', true);
        $('#emails').modal('show');
        this.guardianEmailLoader = false;
        this.personalLoader = false;
      },
      error => {
        this.alert.error('An unexpected error', true);
        this.guardianEmailLoader = false;
        this.personalLoader = false;
      }
    );
  }


  // otp verification of email
  otpEmailSubmit() {
    const link: string = 'OTP=' + this.otpEmail + '&Email=' + this.Email;
    if (this.type === 'guardianEmail') {
      // this.rForm.get("flagguardianemail").setValue("True");
      this.isVerfiedGaurdianEmail = true;
      this.GurdianEmailOTP = this.otpEmail;
    } else if (this.type === 'personal') {
      // this.rForm.get("flagemail").setValue("True");
      this.isVerifiedPersonalEmail = true;
      this.personalEmailOTP = this.otpEmail;
    }
      $('#emails').modal('hide');
      this.otpEmail = null;

    // this.loginService.verficationEmailOtp(link).subscribe(
    //   (res: any) => {
    //     if (res.match('Email Verified')) {
    //       //this.alert.success('OTP verified', true);
    //       if (this.type === 'guardianEmail') {
    //         // this.rForm.get("flagguardianemail").setValue("True");
    //         this.isVerfiedGaurdianEmail = true;
    //         this.GurdianEmailOTP = this.otpEmail;
    //       } else if (this.type === 'personal') {
    //         // this.rForm.get("flagemail").setValue("True");
    //         this.isVerifiedPersonalEmail = true;
    //         this.personalEmailOTP = this.otpEmail;
    //       }
    //       $('#emails').modal('hide');
    //       this.otpEmail = null;
    //     }
    //   },
    //   error => {
    //     this.alert.error('OTP not verified', true);
    //   }
    // );
  }

  addCandidate(data) {
    this.registerCandidate(data);
  }

  verifyOtp(mydatas) {
    if (mydatas.Email && !this.isVerifiedPersonalEmail) {
      this.alert.error('Please Verify Personal Email');
      return false;
    } else if (mydatas.GuardiansEmail && !this.isVerfiedGaurdianEmail) {
      this.alert.error('Please Verify Guardian Email');
      return false;
    } else if (mydatas.number && !this.isVerifiedNumber) {
      this.alert.error('Please Verify Personal Phone No.');
      return false;
    } else if (mydatas.GuardiansPhone && !this.isVerifiedGaurdianPhone) {
      this.alert.error('Please Verify Guardian Phone No.');
      return false;
    } else {
      return true;
    }
  }
  getDevice(){
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
  			return 'Mobile';
		} else {
			return 'Desktop';
		}
  }

  // adding candidate
  registerCandidate(data) {
    debugger
    // typeof(data.value.DOB) == "object" ? data.value.DOB = data.value.DOB.toLocaleString(): data.value.DOB;
    const mydata = JSON.stringify(data.value);
    const mydatas = JSON.parse(mydata);
    mydatas['AddCaptureDevice'] = this.getDevice();
    // mydatas['DOBS'] =  typeof(data.value.DOB) == "object" ? data.value.DOB = data.value.DOB.toLocaleString(): data.value.DOB;
    if (typeof (data.value.DOB) == "object") {
      date = this.setDateObject(data.value.DOB);
    }else {
      var date = new Date(data.value.DOB);
     date = this.setDateObject(date);
    }
    mydatas['DOBData'] = date;
    if (!this.verifyOtp(mydatas)) { return; }
    mydatas.EmailOTP = this.otpEmail;
    mydatas.GurdianEmailOTP = this.GurdianEmailOTP;
    mydatas.personalEmailOTP = this.personalEmailOTP;
    if (mydatas.Id !== '') {
      this.loader.show();
      this.loginService.modifyCandidate(mydatas).subscribe(
        res => {
      this.loader.hide();
          this.alert.success('Modified successfully', true);
          if (this.Adminuid) {
            this.router.navigateByUrl('admin/candidate-list');
          } else {
          this.router.navigateByUrl('school/all-candidate');

          }
          // this.rForm.reset();
          this.uploadSchoolImage = null;
        },
        error => {
      this.loader.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    } else {
      this.loader.show();
      this.loginService.addCandidate(mydatas).subscribe(
        res => {
      this.loader.hide();
          if (data.valid) {
            this.alert.success('Submitted successfully', true);
          } else {
            this.alert.success('Candidate Saved as Draft', true);
          }
          if (this.Adminuid) {
            this.router.navigateByUrl('admin/candidate-list');
          }
          this.router.navigateByUrl('school/all-candidate');
          // this.rForm.reset();
          this.getSeason();
          this.uploadSchoolImage = null;
          if (this.id !== undefined) {
            this._location.back();
          }
        },
        error => {
      this.loader.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
  setDateObject(date): any {
    let b = date.getDate();
      let c = date.getMonth();
      let d = date.getFullYear();
      // let a = data.SeasonExamTime.toLocaleString();
      return   {
        Date: b,
        Month: c + 1,
        Year: d
      }
  }

  // phone verfication method (sending otp to respected phone)
  verifyPhone(phnNo, type) {
    let countryCode;
    this.phoneNumber = phnNo;
    this.Phonetype = type;
    if (this.phoneNumber.length === 0) {
      this.alert.error('Enter valid phone Number', true);
      return;
    }
    let a = JSON.parse(localStorage.getItem('SchoolDetails')).CountryId;
    (a == 4042) ? countryCode ='KE':countryCode='NG';
    this.loginService.sendingOtpToPhone(this.phoneNumber, countryCode).subscribe(
      res => {
        if (this.Phonetype === 'gaurdianphone') {
          // this.rForm.get("flagguardiannumber").setValue("True");
          this.isVerifiedGaurdianPhone = true;
        } else if (this.Phonetype === 'number') {
          // this.rForm.get("flagnumber").setValue("True");
          this.isVerifiedNumber = true;
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // phone otp verification
  phoneOtpVerfication(data, phone) {
    // &phone=
    const url = `${data}&phone=${phone}`;
    this.loginService.verficationPhoneOtp(url).subscribe(
      (res: any) => {
        if (res === 'OTP verified') {
          if (this.type === 'gaurdianphone') {
            this.rForm.get('flagguardiannumber').setValue('True');
            this.isVerifiedGaurdianPhone = true;
          } else if (this.type === 'number') {
            this.rForm.get('flagnumber').setValue('True');
            this.isVerifiedNumber = true;
          }
        }
        this.alert.success(res, true);
        $('#phoneModel').modal('hide');
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !/[\d\s]/.test(input);
  }
}
