export class SchoolRegGridModel {
    Name: string;
    Category: string;
    Type: string;
    State: string;
    Country: string;
    Email: string;
    Region: string;
}

export class SchoolRegReportFilterModel extends SchoolRegGridModel {
    constructor() {
        super();
        this.Order = 'Asc';
        this.Skip = 0;
        this.Take = 10;
    }
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}
