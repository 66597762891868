import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"]
})
export class HomePageComponent implements OnInit {
  Country: any;
  firstPrize: any;
  secondPrize: any;
  thirdPrize: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router
  ) { }
  private allData: any = ["", "", ""];

  mainHeading: any;
  firstStage: any;
  secondStage: any;
  youtubeLink: any;
  googlePlusLink: any;
  linkedinLink: any;
  facebookLink: any;
  twitterLink: any;

  ngOnInit() {
    this.getAllData();
    // localStorage.clear();
    this.getAllCountries();
  }
  getAllCountries(): any {
    this.loginService.getAllCountryList().subscribe(res => {
      if (res) {
        this.Country = res;
      }
    }, err => { })
  }

  getAllData() {
    this.loginService.getAllEditedData(0).subscribe(
      (res: any) => {
        this.allData = res;
        for (var _i = 0; _i < res.length; _i++) {
          var num = this.allData[_i];
          if (this.allData[_i].Name == "mainHeading") {
            this.mainHeading = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "firstStage") {
            this.firstStage = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "secondStage") {
            this.secondStage = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "youtubeLink") {
            this.youtubeLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "googlePlusLink") {
            this.googlePlusLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "linkedinLink") {
            this.linkedinLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "facebookLink") {
            this.facebookLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "twitterLink") {
            this.twitterLink = this.allData[_i].Value;
          }
          else if (this.allData[_i].Name == "firstPrize") {
            this.firstPrize = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "secondPrize") {
            this.secondPrize = this.allData[_i].Value;
          }
          else if (this.allData[_i].Name == "thirdPrize") {
            this.thirdPrize = this.allData[_i].Value;
          }
        }
      },
      error => {
        this.alert.error("something went wrong", true);
      }
    );
  }

  //route to the login page
  loginPage() {
    this.router.navigate(["login"]);
  }

  //route to the school registration page
  registerSchool() {
    this.router.navigate(["registration"]);
  }

  countrySelected(m) {  
    var a = this.Country.filter(x => x.Value == m.target.value);  
    this.router.navigateByUrl(`country?cid=${btoa(m.target.value)}&rId=${a[0].RegionId}`);
  }
}
