import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FilterFieldModel, FieldType } from 'src/app/_model/filter-field-model';
import { FilterChangeModel } from 'src/app/_model/filter-change-model';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {
  @Input() Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public filterData: FilterChangeModel[] = new Array<FilterChangeModel>();
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('child') children: QueryList<any>;

  constructor() {
    // this.Fields = [
    //   { FieldName: 'Name', Label: 'Student Name', Placeholder: 'Please enter student name', FieldType: FieldType.Text },
    //   // { FieldName : 'Email', Label: 'Persnale', Placeholder : 'Please enter email', FieldType: FieldType.Email },
    //   { FieldName: 'Count', Label: 'Counter', Placeholder: 'Please enter number', FieldType: FieldType.Number },
    //   // { FieldName : 'Password', Label: 'Password', Placeholder : 'Please enter password', FieldType: FieldType.Password },
    //   // { FieldName : 'PhoneNumber', Label: 'Mobile Number', Placeholder
    // : 'Please enter mobile number', FieldType: FieldType.PhoneNumber },
    //   { FieldName: 'Regions', Label: 'Regions', Placeholder: 'Select Region', FieldType: FieldType.RegionDropdown },
    //   { FieldName: 'Category', Label: 'Category', Placeholder: 'Select Category', FieldType: FieldType.SchoolCategoryDropdown },
    //   { FieldName: 'SchoolType', Label: 'School Type', Placeholder: 'Select School Type', FieldType: FieldType.SchoolTypeDropdown },
    //   { FieldName: 'State', Label: 'State', Placeholder: 'Select State', FieldType: FieldType.StateDropdown, Filter: null },
    //   { FieldName: 'Class', Label: 'Student Name', Placeholder: 'Please enter student name', FieldType: FieldType.ClassDropdown },
    //   { FieldName: 'Country', Label: 'Student Name', Placeholder: 'Please enter
    // student name', FieldType: FieldType.CountryDropdown, Filter: null }
    // ]
  }

  ngOnInit() {
  }

  public Changed(value: FilterChangeModel) {
    const field = this.filterData.find(x => x.FieldName === value.FieldName);
    if (field) {
      field.Value = value.Value;
      field.EventType = value.EventType;
    } else {
      this.filterData.push(value);
    }
    this.dataChanged(value);
  }

  dataChanged(value: FilterChangeModel): any {
    const field = this.Fields.find(x => x.FieldName === value.FieldName);
    if (field.FieldType === FieldType.RegionDropdown) {
      this.Fields.find(x => x.FieldType === FieldType.CountryDropdown).Filter = value.Value;
    } if (field.FieldType === FieldType.CountryDropdown) {
      this.Fields.find(x => x.FieldType === FieldType.StateDropdown).Filter = value.Value;
    }
  }

  Filter() {
    this.filter.emit(this.filterData);
  }

  clearFilter() {
    this.filterData = [];
    const field = [];
    this.Fields.forEach(ele => {
      field.push(Object.assign({}, ele));
    });
    this.children.forEach(ele => {
      if (ele.Reset) {
        ele.Reset();
      }
    });
    setTimeout(() => {
      this.filter.emit(this.filterData);
    }, 0);
  }
}
