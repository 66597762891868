import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Helper } from "../../../_model/helper";
@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"]
})
export class FaqComponent implements OnInit {
  list: any;
  question: any;
  countryId: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.countryId = params['countryId'];
    })
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    if(this.countryId){
      this.loginService.getFaq(this.countryId).subscribe(
        (res:any)=>{
          this.list = res;
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
}
