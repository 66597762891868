export class FilterFieldModel {
    FieldType: FieldType;
    Label: string;
    Placeholder: string;
    FieldName: string;
    Filter?: string | number;
}

export enum FieldType {
    Text,
    Number,
    PhoneNumber,
    Email,
    Password,
    RegionDropdown,
    CountryDropdown,
    StateDropdown,
    SchoolCategoryDropdown,
    SchoolTypeDropdown,
    ClassDropdown,
    SeasonDropdown,
    ContactTypeDropdown,
    ExamCenterDropdown,
    RegistrationStatusDropdown,
    RegistrationDateFrom,
    RegistrationDateTo
}
