import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import { ApiServerService } from '../../service/api-server.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AlertService } from '../../_service/alert.service';
import { PasswordValidation } from './PasswordValidation';
import * as myapi from '../../service/baseLink';
import { Location } from '@angular/common';
import { schoolCategory, schoolType } from '../enums/enum';
import { LoaderService } from '../../service/loader.service';
import { Helper } from '../../_model/helper';
declare var $: any;
declare var jic: any;
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  message: any;
  rForm: FormGroup;
  loader: boolean;
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  phonePattern = '[0-9]*';
  alphabhatpattern = '[a-zA-Z._ ]*';
  uploadSchoolImage: any; // uploaded school image
  uploadImageEvent: any; // uploaded image event
  mainloader = false; // mainloader of this component which is false
  state: any; // getting list of state
  Country: any; // getting list of country
  Region: any; // getting list of region
  emailOtp: any; // used in email otp model
  phoneOtp: any; // used in phine otp model
  phoneNumber: any;
  countryId: any; // sore the coutry id value
  regionId: any; // store region id value
  isVerifiedSchoolEmail = true;
  fieldId: any;
  isVerifiedSchoolPhone: boolean;
  isVerifiedPrinciplePhone: boolean;
  public submittedError = false;
  reader = new FileReader();
  imageToVerify: any;
  schoolUpdate: boolean;
  Schoollogo: any; // for the school image getting from the backend
  defaultImageSchool: any = 'assets/img/school-img.png';
  Email: any;
  Uid: any;
  type: any;
  // obtained data from data service
  list: any;
  schoolData: any = {}; // fetched from api
  SchoolContactLoader = false;
  PrimaryContactLoader = false;
  regionIdForAutoComplete: any;
  countryIdForAutoComplete: any;


  schoolCategory: any;
  schoolType: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private newloader: LoaderService,
  ) {

    this.reader.onload = (e: any) => {
      this.uploadSchoolImage = e.target.result;
      const array = new Uint8Array(this.uploadSchoolImage),
        binaryString = String.fromCharCode.apply(null, array);
    };
    this.schoolCategory = schoolCategory;
    this.schoolType = schoolType;
  }

  ngOnInit() {
    if (localStorage.getItem('auth')) {
     if( location.href.includes("school") || location.href.includes("admin") || location.href.includes("super-admin")) {
      this.schoolUpdate = true;
      }
      else {
        this.schoolUpdate = false;
      }
    } else {
      this.schoolUpdate = false;
    }
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.Uid = params['uid'];
      if(params['rId']) this.regionIdForAutoComplete = params['rId'];
      if(params['cid']) this.countryIdForAutoComplete = atob(params['cid']);
    });
    this.rForm = this.formBuilder.group({
      Id: [''],
      Logo: [''],
      Name: ['', Validators.required],
      Address: ['', Validators.required],
      PhoneNumber: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.phonePattern)
      ])
      ],

      StateId: ['', Validators.required],

      PrimaryContactName: ['', Validators.compose([Validators.required,
      Validators.pattern(this.alphabhatpattern)
      ])
      ],

      PrimaryContactPhone: ['', Validators.compose([Validators.required,
      Validators.pattern(this.phonePattern)
      ])
      ],
      Type: ['', Validators.required],

      IsNumberVerified: [''],
      IsEmailVerified: [''],
      IsPrimaryContactNumberVerified: [''],


      Password: ['', Validators.compose([this.initialiseRequiredValidation.bind(this), Validators.minLength(3)])],

      Email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)
      ])
      ],
      // Id: [""],
      CountryId: ['', Validators.required],
      RegionId: ['', Validators.required],
      Category: ['', Validators.required],

      ConfirmPassword: ['']
    }, {
        validator: PasswordValidation.MatchPassword // your validation method
      });
    if (localStorage.getItem('auth') && !(this.Uid) && (location.href.includes("school") || location.href.includes("admin") || location.href.includes("super-admin"))) {
      this.schoolUpdate = true;
      this.schoolProfileLoad();
    } else {
      if (this.Uid) {
        this.schoolAdmLoad();
      }
    }
    this.getAllRegion();
    this.getAllCountry('0');
    this.data.currentMessage.subscribe(message => (this.message = message));
  }

  // image upload listener
  imageUpload(event) {
    if (event.target.files[0].type.includes('image/')) {
      this.uploadImageEvent = event; // copy the event for the further uses
      // to render the pic on the screen
      this.uploadSchoolImage = event.target.files[0];
      this.reader.readAsDataURL(event.target.files[0]);
    } else {
      this.alert.error('Please Select Valid Image File.');
    }
  }

  initialiseRequiredValidation(control: AbstractControl) {
    if (!this.schoolUpdate) {
      control.setValidators(Validators.required);
    }

  }

  public resizeImage() {
    let imgFile;
    if (typeof (this.uploadSchoolImage) === 'string') {
      imgFile = this.dataURLtoFile(this.uploadSchoolImage, 'myfile.jpg');
    } else {
      imgFile = this.uploadSchoolImage;
    }
    if (imgFile && imgFile.size > 204800) {
      const src_img: any = document.getElementById('myprofileimg');
      const percentage = 200 * 100 / (imgFile.size / 1024);
      const targetImg = jic.compress(src_img, Math.floor(percentage), 'jpg').src;
      const file = this.dataURLtoFile(targetImg, imgFile.name);
      this.uploadSchoolImage = file;
      this.imageToVerify = file;
      src_img.src = targetImg;
      if (file && file.size > 204800) {
        this.resizeImage();
      } else {
        this.reader.readAsDataURL(this.uploadSchoolImage);
      }
    } else {
      this.imageSave(imgFile);
    }
  }


  imageSave(imgFile) {
    if (typeof (imgFile) === 'object') {
      const form = new FormData();
      form.append('file', imgFile);
      this.loginService.saveImage(form, true).subscribe(res => {
        this.rForm.get('Logo').setValue(res);
      }, err => { });
    }

  }
  // going to hit registration api
  Registration(data) {
    if (data.invalid) {
      this.submittedError = true;
      return;
    } else {
      // api hitting code....
      const form_data = new FormData(); // create object of formdata
      const registrationData = JSON.stringify(data.value);
      const mydatas = JSON.parse(registrationData);
      if (!(this.isVerifiedPrinciplePhone && this.isVerifiedSchoolPhone)) {
        this.alert.error('Please Verify Contacts First.');
        return;
      } else if (!this.isVerifiedSchoolEmail) {
        this.alert.error('Please Verify School Email.');
        return;
      }
      mydatas.Picture = '';
      mydatas.EmailOTP = this.emailOtp;
      this.callForRegistrationUpdate(mydatas);

    }
  }

  callForRegistrationUpdate(mydatas) {
    this.newloader.show();
    if (!this.Uid) {
      this.loginService.schoolRegistration(mydatas, this.schoolUpdate).subscribe(
        (res: any) => {
          if (!this.schoolUpdate) {
            this.alert.success('Registration Successful', true);
            this.router.navigate(['/login']);
            this.newloader.hide();
          } else {
            this.alert.success('School Update Successful', true);
          }
          this.newloader.hide();
          this.mainloader = false;
          localStorage.setItem('SchoolDetails', JSON.stringify(mydatas));
        },
        error => {
          this.mainloader = false;

          this.newloader.hide();
          this.alert.error(Helper.getMessageFromError(error), true);

        }
      );
    } else {
      this.loginService.schoolRegistrationAsAdmin(mydatas).subscribe(
        (res: any) => {
          if (!this.schoolUpdate) {
            this.alert.success('Registration Successful', true);
            this.router.navigate(['/login']);
            this.newloader.hide();
          } else {
            this.alert.success('School Update Successful', true);
          }

          this.newloader.hide();
          this.mainloader = false;
          localStorage.setItem('SchoolDetails', JSON.stringify(mydatas));
        },
        error => {
          this.mainloader = false;
          this.newloader.hide();
          this.alert.error(Helper.getMessageFromError(error), true);

        }
      );
    }
  }

  // state list
  getAllState(countryid) {
    this.loginService.getallState(countryid).subscribe(
      (res: any) => {
        this.state = res;
      },
      error => {
        this.alert.error('State is not able to fetched', true);
      }
    );
  }

  // country list
  getAllCountry(regionId) {
    this.loginService.getallCountry(regionId).subscribe(
      (res: any) => {
        this.Country = res;
        this.setCountry();    
      },
      error => {
        this.alert.error(error);
      }
    );
  }

  // region list
  getAllRegion() {
    this.loginService.getAllRegions().subscribe(
      (res: any) => {
        this.Region = res;  
        this.setRegionCountry();      
      },
      error => {
      }
    );
  }

  // email verfication method (sending otp to respected email)
  verifyEmail(email, type) {
    this.fieldId = type;
    this.loader = true;
    this.loginService.sendingOtpToEmail(email).subscribe(
      (res: any) => {
        $('#emailModel').modal('show');
        // this.alert.success('Check your mail for OTP', true);
        this.loader = false;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // email otp verification
  emailOtpVerifiy() {
    $('#emailModel').modal('hide');
    if (this.fieldId === 'SchoolEmail') {
      this.isVerifiedSchoolEmail = true;
      this.rForm.get('IsEmailVerified').setValue('True');
      this.schoolData.Email = this.emailOtp;
    }
  }

  // phone verfication method (sending otp to respected phone)
  verifyPhone(phoneNumber, type) {
    debugger
    let countryCode;
    this.fieldId = type;
    this.phoneNumber = phoneNumber;
    this.fieldId === 'PrimaryContactPhoneNumber' ? this.PrimaryContactLoader = true : this.SchoolContactLoader = true;
    let a = this.rForm.get('RegionId').value;
    if(!a) a = this.countryIdForAutoComplete;
    (a == 22) ? countryCode ='KE':countryCode='NG';
    this.loginService.sendingOtpToPhone(phoneNumber, countryCode).subscribe(
      res => {
        if (this.fieldId === 'PhoneNumber') {
          this.rForm.get('IsNumberVerified').setValue('True');
          this.schoolData.SchoolPhone = phoneNumber;
          this.isVerifiedSchoolPhone = true;
        } else if (this.fieldId === 'PrimaryContactPhoneNumber') {
          this.rForm.get('IsPrimaryContactNumberVerified').setValue('True');
          this.schoolData.PrimaryContactPhoneNumberNumber = phoneNumber;
          this.isVerifiedPrinciplePhone = true;
        }
        this.PrimaryContactLoader = false;
        this.SchoolContactLoader = false;
      },
      error => {
        this.PrimaryContactLoader = false;
        this.SchoolContactLoader = false;
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // phone otp verification
  phoneOtpVerfication(data, phone) {
    const url = `${data}&phone=${phone}`;
    this.loginService.verficationPhoneOtp(url).subscribe(
      res => {
        if ((res = 'OTP verified')) {
          if (this.fieldId === 'SchoolPhone') {
            this.rForm.get('IsNumberVerified').setValue('True');
            this.isVerifiedSchoolPhone = true;
          } else if (this.fieldId === 'PrimaryContactPhoneNumber') {
            this.rForm.get('IsPrimaryContactNumberVerified').setValue('True');
            this.isVerifiedPrinciplePhone = true;
          }
        }
        $('#phoneModel').modal('hide');
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // country selected event listener
  countrySelected(e) {
    this.countryId = e.target.value;
    this.rForm.get('CountryId').setValue(this.countryId);
    this.rForm.get('StateId').setValue('');
    this.state = undefined;
    this.getAllState(this.countryId);
  }
  // region selected event listener
  regionSelected(e) {
    debugger
    this.regionId = e.target.value;
    this.rForm.get('RegionId').setValue(this.regionId);
    this.rForm.get('CountryId').setValue('');
    this.rForm.get('StateId').setValue('');
    this.state = undefined;
    this.getAllCountry(this.regionId);
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !/[\d\s]/.test(input);
  }

  dataURLtoFile(dataurl, filename) {
    // tslint:disable-next-line:prefer-const
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      // tslint:disable-next-line:prefer-const
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  schoolProfileLoad() {
    this.loader = true;
    this.loginService.schoolProfile().subscribe(
      (res: any) => {
        if (res) {
          this.SetSchoolForm(res);
        }
      },
      error => {
        this.alert.error('Invalid Response', true);
        this.loader = false;
      }
    );
  }
  SetSchoolForm(res): any {
    this.getAllCountry(res.RegionId);
    localStorage.setItem('SchoolDetails', JSON.stringify(res));
    this.loader = false;
    localStorage.setItem('RegionId', JSON.stringify(res.RegionId));
    localStorage.setItem('CountryId', JSON.stringify(res.CountryId));
    this.schoolData = res;
    this.getAllRegion();
    this.getAllState(this.schoolData.CountryId);
    this.rForm.get('IsNumberVerified').setValue(this.schoolData.IsNumberVerified);
    this.rForm.get('IsPrimaryContactNumberVerified').setValue(this.schoolData.IsPrimaryContactNumberVerified);
    this.rForm.get('IsEmailVerified').setValue(this.schoolData.IsEmailVerified);
    this.rForm.get('RegionId').setValue(res.RegionId);
    this.rForm.get('Id').setValue(this.schoolData.Id);
    this.rForm.get('Name').setValue(this.schoolData.Name);
    this.rForm.get('PhoneNumber').setValue(this.schoolData.PhoneNumber);
    this.rForm.get('Address').setValue(this.schoolData.Address);
    this.rForm.get('CountryId').setValue(this.schoolData.CountryId);
    this.rForm.get('StateId').setValue(this.schoolData.StateId);
    this.rForm.get('PrimaryContactName').setValue(this.schoolData.PrimaryContactName);
    this.rForm.get('PrimaryContactPhone').setValue(this.schoolData.PrimaryContactPhone);
    this.rForm.get('Type').setValue(this.schoolData.Type);
    this.rForm.get('Email').setValue(this.schoolData.Email);
    this.rForm.get('Category').setValue(this.schoolData.Category);
    this.Email = this.schoolData.Email;
    this.rForm.get('Password').setValue(this.schoolData.Password);
    this.rForm.get('ConfirmPassword').setValue(this.schoolData.Password);
    this.rForm.get('Logo').setValue(this.schoolData.Logo);
    if (this.schoolData.PhoneNumber) { this.isVerifiedSchoolPhone = true; }
    if (this.schoolData.PrimaryContactPhone) { this.isVerifiedPrinciplePhone = true; }
    if (this.schoolData.Email) { this.isVerifiedSchoolEmail = true; }
    if (this.schoolData.Logo != null) {
      const candidatePic = myapi.BaseUrl + this.schoolData.Logo;
      this.Schoollogo = candidatePic;
    }
  }

  // admin/superadmin login into school for modification....!
  schoolAdmLoad() {
    this.loginService.getSchoolAsAdmin(this.Uid).subscribe(res => {
      this.SetSchoolForm(res);
    });
  }

  public EmailCheck(event) {
    if (event.target.value !== this.schoolData.Email) {
      this.isVerifiedSchoolEmail = false;
    } else {
      this.isVerifiedSchoolEmail = true;
    }
  }

  PhoneCheck(event) {
    if (event.target.value !== this.schoolData.SchoolPhone) {
      this.isVerifiedSchoolPhone = false;
    } else {
      this.isVerifiedSchoolPhone = true;
    }
  }
  PrincipalPhoneCheck(event) {
    if (event.target.value !== this.schoolData.PrimaryContactPhoneNumberNumber) {
      this.isVerifiedPrinciplePhone = false;
    } else {
      this.isVerifiedPrinciplePhone = true;
    }
  }

  trimValue(event: any) {
    const name = this.rForm.get('Name').value.trim();
    this.rForm.get('Name').setValue(name);
    const ContactName = this.rForm.get('PrimaryContactName').value.trim();
    this.rForm.get('PrimaryContactName').setValue(ContactName);
    const address = this.rForm.get('Address').value.trim();
    this.rForm.get('Address').setValue(address);
  }
  setRegionCountry() {
    if(this.regionIdForAutoComplete){
     let filterResult = this.Region.filter(x => x.Value == this.regionIdForAutoComplete)
     this.rForm.get('RegionId').setValue(filterResult[0].Value);
     this.getAllCountry(this.regionIdForAutoComplete);
    }
  }
  setCountry(): any {
    debugger    
    if(this.countryIdForAutoComplete) { 
      let filterResult = this.Country.filter(x=> x.Value == this.countryIdForAutoComplete);
      this.rForm.get('CountryId').setValue(filterResult[0].Value);
      this.getAllState(this.countryIdForAutoComplete);
    }
  }
}

