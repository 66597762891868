export class ExamCenterModel {
    constructor() {
        this.Skip = 0;
        this.Take = 10;
        this.Order = 'Asc';
    }
    Name: string;
    Address: string;
    MaximumCapacity: number;
    StateId: number;
    CountryId: number;
    RegionId: number;
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}

export class ExamCenterGridModel {
    Id: number;
    Name: string;
    StateId: number;
    MaxCapacity: number;
    Address: string;
    IsActive: boolean;
    ExamCenterNumber: number;
    CountryId: number;
    RegionId: number;
}

export class ExamCenterFilterResultModel {
    constructor() {
        this.List = new Array<ExamCenterGridModel>();
    }
    List: ExamCenterGridModel[];
    TotalRecords: number;
}
