import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as myapi from '../../../service/baseLink';
import { ExcelService } from '../../../_model/exceldownload.helper';
import { Helper } from '../../../_model/helper';
import { PropertyMetaModel, PaginationModel, GridColumnType } from 'src/app/_model/pagination-model';
import { FilterFieldModel, FieldType } from 'src/app/_model/filter-field-model';
import { LoaderService } from '../../../service/loader.service';
import { pdfPotrait } from '../../../_model/pdfDownloadHelper';

@Component({
  selector: 'app-contact-report',
  templateUrl: './contact-report.component.html',
  styleUrls: ['./contact-report.component.css']
})
export class ContactReportComponent implements OnInit {
  public displayColumns: PropertyMetaModel[];
  public filter: any = {};
  public pageInfo = new PaginationModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public gridData: any[] = [];
  public pdfColumns = [
    { title: "NAME", dataKey: "Name" },
    { title: "ADDRESS", dataKey: "Address" },
    { title: "SCHOOLPHONE", dataKey: "SchoolPhone" },
    { title: "SCHOOLTYPE", dataKey: "SchoolType" },
    { title: "EMAIL", dataKey: "Email" }
  ]

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService,
    private loaderService: LoaderService) {
      this.Fields = [
        {
          FieldName: 'ContactType',
          Label: 'ContactType',
          Placeholder: 'Please enter conatct name',
          FieldType: FieldType.ContactTypeDropdown
        },
        {
          FieldName: 'Name',
          Label: 'Name',
          Placeholder: 'Please enter conatct name',
          FieldType: FieldType.Text
        }
        // {
        //   FieldName: 'Email',
        //   Label: 'Email',
        //   Placeholder: 'Please enter email',
        //   FieldType: FieldType.Text
        // }
      ];
    }

    ngOnInit() {
      this.getAllCadidateReport();
    }

    getAllCadidateReport(): any {
      this.gridData = [];
      const page: any = {};
      page.Skip = (this.pageInfo.CurrentPage - 1) * this.pageInfo.PageSize;
      page.Take = this.pageInfo.PageSize;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loaderService.show();
      this.loginService.getContactReport(page, this.filter).subscribe(
        (res: any) => {
          this.gridData = res.List;
          this.pageInfo.TotalRecord = res.TotalRecords;
      this.loaderService.hide();

        },
        error => {
      this.loaderService.hide();

         }
      );
    }

    action(event: any) {      
    }

    pageInfoChange(event: PaginationModel) {
      this.pageInfo.CurrentPage = event.CurrentPage;
      this.pageInfo.PageSize = event.PageSize;
      this.pageInfo.SortOrder = event.SortOrder;
      this.pageInfo.SortOrder = event.SortOrder;
      this.getAllCadidateReport();
    }

    filterChange(event: any) {      
      if (event && event.length > 0) {
        event.forEach(element => {
          this.filter[element.FieldName] = element.Value;
        });
      } else {
        this.filter = {};
      }
      this.pageInfo.CurrentPage = 1;
      this.getAllCadidateReport();
    }

    download() {
      const page: any = {};
      page.Skip = 0;
      page.Take = 1000;
      this.filter.Take = 1000;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loginService.getContactReport(page, this.filter).subscribe(
        (res: any) => {
          this.downloadFile(res.List);
        },
        error => { }
      );
    }

    downloadFile(data: any) {
      this.excelService.exportAsExcelFile(data, 'Contact List:');
    }
    downloadPDF() {
      const page: any = {};
      page.Skip = 0;
      page.Take = 1000000;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loginService.getContactReport(page, this.filter).subscribe(
        (res: any) => {
          if(res){
            pdfPotrait.exportPdfportrait(res, "ContactReport:", this.pdfColumns, 'Contact Report', 'p');
          }
        },
        error => { }
      );
    }

  }
