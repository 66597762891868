import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  title = 'app';
  imageVisible = true;
  constructor(router: Router) {
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        const url = document.location.pathname;
        if (url === '' ||
          url === '/guidelines' ||
          url === '/faq' ||
          url === '/contact-us'
        ) {
          this.imageVisible = false;
        } else {
          this.imageVisible = true;
        }
      }
    });
  }
}
