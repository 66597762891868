import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './home/login/login.component';
import { RegistrationComponent } from './home/registration/registration.component';
import { ForgetPasswordComponent } from './home/forget-password/forget-password.component';
import { ChangePasswordComponent } from './home/change-password/change-password.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiServerService } from './service/api-server.service';
import { HttpModule } from '@angular/http';
import { DataService } from './service/data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from './_service/alert.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ExaminerComponent } from './examiner/examiner/examiner.component';
import { HomePageComponent } from './home/homeScreen/home-page/home-page.component';
import { HomeHeaderComponent } from './home/homeScreen/home-header/home-header.component';
import { GuidelinesComponent } from './home/homeScreen/guidelines/guidelines.component';
import { FaqComponent } from './home/homeScreen/faq/faq.component';
import { HomeContactUsComponent } from './home/homeScreen/home-contact-us/home-contact-us.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SchoolModule } from 'src/app/school/school.module';
import { SuperAdminModule } from 'src/app/superAdmin/super-admin.module';
import { AdminModule } from 'src/app/admin/admin.module';
import { SyllabusComponent } from './home/homeScreen/syllabus/syllabus.component';
import { FooterComponent } from './home/homeScreen/footer/footer.component';
import { PageNotFoundComponent } from './home/page-not-found/page-not-found.component';
import { CandidateResultComponent } from './home/result/candidate-result/candidate-result.component';
import { SchoolResultComponent } from './home/result/school-result/school-result.component';
import { ResultReportComponent } from './home/result/result-report/result-report.component';
import { ExcelService } from './_model/exceldownload.helper';
import { SharedModule } from './_shared/sharedmodule';
import { MatSlideToggleModule, MatSlideToggle } from '@angular/material/slide-toggle';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor} from './HttpInterceptor/httpconfig.interceptor';
import { LoaderService } from './service/loader.service';
import { LoaderComponent } from './service/loader/loader.component';
import { TimepickerModule, TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { CountryComponent } from './home/country/country.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TnCComponent } from './home/homeScreen/tn-c/tn-c.component';
import { RegSchoolsComponent } from './home/reg-schools/reg-schools.component';
import { NewsComponent } from './home/homeScreen/news/news.component';
import { CookieLawModule } from 'angular2-cookie-law';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    ExaminerComponent,
    HomePageComponent,
    HomeHeaderComponent,
    GuidelinesComponent,
    FaqComponent,
    HomeContactUsComponent,
    SyllabusComponent,
    FooterComponent,
    PageNotFoundComponent,
    CandidateResultComponent,
    SchoolResultComponent,
    ResultReportComponent,
    LoaderComponent,
    CountryComponent,
    TnCComponent,
    RegSchoolsComponent,
    NewsComponent
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    SchoolModule,
    SuperAdminModule,
    AdminModule,
    SharedModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    NgDatepickerModule,
    CookieLawModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [ApiServerService, DataService, AlertService, LoaderService, ExcelService, HttpClientModule, {
    provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {

}
