import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PhoneNumberFieldComponent } from './phone-number-field/phone-number-field.component';
import { EmailFieldComponent } from './email-field/email-field.component';
import { PasswordFieldComponent } from './password-field/password-field.component';
import { NumberFieldComponent } from './number-field/number-field.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { CountryDropDownComponent } from './country-drop-down/country-drop-down.component';
import { StateDropDownComponent } from './state-drop-down/state-drop-down.component';
import { SchoolCategoryDropDownComponent } from './school-category-drop-down/school-category-drop-down.component';
import { RegionDropDownComponent } from './region-drop-down/region-drop-down.component';
import { SchoolTypeDropDownComponent } from './school-type-drop-down/school-type-drop-down.component';
import { ClassDropDownComponent } from './class-drop-down/class-drop-down.component';
import { AlertComponent } from './alert/alert.component';
import { FiltersComponent } from './filters/filters.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { TruncatePipePipe } from './pipe/truncate-pipe.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SeasonDropdownComponent } from './season-dropdown/season-dropdown.component';
import { ContactTypeDropdownComponent } from './contact-type-dropdown/contact-type-dropdown.component';
import { ExamcenterDropdownComponent } from './examcenter-dropdown/examcenter-dropdown.component';
import { RegistrationStatusDropdownComponent } from './registration-status-dropdown/registration-status-dropdown.component';
import { RegistrationDateToComponent } from './registration-date-to/registration-date-to.component';
import { RegistrationDateFromComponent } from './registration-date-from/registration-date-from.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AdminSettingCommonComponent } from './admin-setting-common/admin-setting-common.component';

@NgModule({
  exports: [
    AlertComponent,
    PhoneNumberFieldComponent,
    EmailFieldComponent,
    PasswordFieldComponent,
    NumberFieldComponent,
    TextFieldComponent,
    RegionDropDownComponent,
    CountryDropDownComponent,
    StateDropDownComponent,
    SchoolCategoryDropDownComponent,
    SchoolTypeDropDownComponent,
    ClassDropDownComponent,
    FiltersComponent,
    DataGridComponent,
    TruncatePipePipe,
    SeasonDropdownComponent,
    RegistrationStatusDropdownComponent,

    RegistrationDateFromComponent,
    RegistrationDateToComponent
  ],
  imports: [
    CommonModule,
    HttpModule,
    RouterModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()

  ],
  declarations: [
    FiltersComponent,
    AlertComponent,
    PhoneNumberFieldComponent,
    EmailFieldComponent,
    PasswordFieldComponent,
    NumberFieldComponent,
    TextFieldComponent,
    RegionDropDownComponent,
    CountryDropDownComponent,
    StateDropDownComponent,
    SchoolCategoryDropDownComponent,
    SchoolTypeDropDownComponent,
    ClassDropDownComponent,
    DataGridComponent,
    TruncatePipePipe,
    SeasonDropdownComponent,
    ContactTypeDropdownComponent,
    ExamcenterDropdownComponent,
    RegistrationStatusDropdownComponent,
    RegistrationDateFromComponent,
    RegistrationDateToComponent,
    AdminSettingCommonComponent
  ],
  providers: []
})

export class SharedModule {}
