import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../../service/baseLink";
import { DatePipe } from "@angular/common";
import { Helper } from "../../../_model/helper";
import { LoaderService } from "../../../service/loader.service";
@Component({
  selector: "app-add-season",
  templateUrl: "./add-season.component.html",
  styleUrls: ["./add-season.component.css"],
  providers: [DatePipe]
})
export class AddSeasonComponent implements OnInit {
  minDate: Date; //season registration date start from initial today
  minRegEndDate: Date;
  minExamDate: Date;
  heading: String = "Create Season"
  examType: String = ""
  rForm: FormGroup;
  list: any;
  submittedError: boolean = false;
  uid: any;
  Id: any;
  CountryId: any;
  countryName: string = '';
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private loaderService: LoaderService
  ) {
    this.minDate = new Date(); //Season Registration Start Date
    this.minRegEndDate = new Date(); //season end date
    this.minExamDate = new Date(); //season exam date

    this.activatedRoute.queryParams.subscribe(params => {
      this.Id = params["Id"];
      this.CountryId = params["CountryId"];
  });
  }

  ngOnInit() {
    this.loginService.getExamTypeApi().subscribe((res: any) => {
      this.examType = res
      if(this.examType === 'Online'){
        this.rForm.get('SeasonExamEndDate').setValidators([Validators.required])
        this.rForm.get('SeasonExamEndDate').updateValueAndValidity()
      }else{
        this.rForm.get('SeasonExamEndDate').clearValidators()
        this.rForm.get('SeasonExamEndDate').updateValueAndValidity()
      }
    },
    error => {}
    );
    this.loginService.getclass().subscribe(
      (res: any) => {
        this.list = res;
      },
      error => { }
    );
    this.rForm = this.formBuilder.group({
      SeasonName: ["", Validators.required],
      Id: [""],
      CountryId: [""],
      SeasonRegStartDate: ["", Validators.required],
      SeasonRegEndDate: ["", Validators.required],
      SeasonExamDate: ["", Validators.required],
      SeasonExamTime: ['', Validators.required],
      SeasonExamEndDate: ['', Validators.required],
      SeasonExamEndTime: ['', Validators.required],
      // gender: ["", Validators.required],
      MaxSelectedCandidate: ["", Validators.required],
      MinNumberofGirlsinMix: ['', Validators.required]
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.uid = params["uid"];
    });
    if(this.Id && this.CountryId){
      this.heading = "Update Season Details (Country Wise)";
      this.getSeasonCountryList();
    }
  }
  getSeasonCountryList(): any {
    this.loaderService.show();
    this.loginService.getSeasonManagementDataById(this.Id, this.CountryId).subscribe(res => {
      if(res){
        this.setDetails(res);
        this.loaderService.hide();
      }
    }, err => {})
  }
  // getSeasonList(): any {
  //   this.loginService.getSeasonDetailById(this.uid).subscribe(res => {
  //     if(res){
  //       this.setDetails(res);
  //     }
  //   },
  //   err => {

  //   })
  // }
  setDetails(res: any): void {
    this.rForm.get("Id").setValue(res.Id);
    this.rForm.get("CountryId").setValue(res.CountryId);
    this.rForm.get("SeasonName").setValue(res.Season);
    this.rForm.controls["SeasonName"].disable();
    this.rForm.get("SeasonRegStartDate").setValue(new Date(res.RegistrationStartDate));
    // this.rForm.controls["SeasonRegStartDate"].disable();
    this.rForm.get("SeasonRegEndDate").setValue(new Date(res.RegistrationEndDate));
    this.rForm.get("SeasonExamDate").setValue(new Date(res.ExamDate));
    this.rForm.get("SeasonExamEndTime").setValue(new Date(res.ExamEndTime));
    this.rForm.get("SeasonExamEndDate").setValue(new Date(res.ExamEndDate));
    this.rForm.get("SeasonExamTime").setValue(new Date(res.ExamTime));
    this.rForm.get("MaxSelectedCandidate").setValue(res.MaxSelectedCandidate);
    this.rForm.get("MinNumberofGirlsinMix").setValue(res.MinNoOfGirlsInMix);
    this.countryName = res.Country;
  }

  startDate(value: Date) {
    this.rForm.get("SeasonRegEndDate").setValue("");
    this.rForm.get("SeasonExamDate").setValue("");

    if (value) {
      this.minRegEndDate = value;
    }
  }
  endDate(value: Date) {
    if (value) {
      var date = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1)
      this.minExamDate = date;
    }
  }

  SendData(data) {
    if (!data.valid) {
      this.submittedError = true;
    }
      if(this.Id && this.CountryId){
        this.updateCountrySeasonDetails(data.value);
      }
      else {
        this.addSeason(data.value);
      }
  }
  updateCountrySeasonDetails(data){
    let b = data.SeasonExamTime.getHours();
    let c = data.SeasonExamTime.getMinutes();
    let d = data.SeasonExamTime.getSeconds();
    let a = data.SeasonExamTime.toLocaleString();
    let e = {
      Hour:b,
      Minute:c,
      Second: d 
    }
    let f = data.SeasonExamEndTime.getHours();
    let g = data.SeasonExamEndTime.getMinutes();
    let i = data.SeasonExamEndTime.getSeconds();
    let j = data.SeasonExamEndTime.toLocaleString();
    let k = {
      Hour:f,
      Minute:g,
      Second: i 
    }
    let dataObj = {
      Id: data.Id,
      CountryId: data.CountryId,
      RegistrationStartDate: data.SeasonRegStartDate,
      RegistrationEndDate: data.SeasonRegEndDate,
      ExamTime: data.SeasonExamTime.toLocaleString('en-GB'),
      ExamDate: data.SeasonExamDate,
      ExamEndDate: data.SeasonExamEndDate,
      ExamEndTime: data.SeasonExamEndTime.toLocaleString('en-GB'),
      Endtime: k,
      MaxSelectedCandidate: data.MaxSelectedCandidate,
      MinNoOfGirlsInMix: data.MinNumberofGirlsinMix,
      time: e
    }
    this.loaderService.show();
    this.loginService.updateSeasonmanagement(dataObj).subscribe(res => {
      this.alert.success("Success");
      this.loaderService.hide();
      this.getSeasonCountryList();
      this.redirect();
    }, err => {
      this.loaderService.hide();
      this.alert.error(err);
    });
  }

  addSeason(data){
    let b = data.SeasonExamTime.getHours();
    let c = data.SeasonExamTime.getMinutes();
    let d = data.SeasonExamTime.getSeconds();
    let a = data.SeasonExamTime.toLocaleString();
    let e = {
      Hour:b,
      Minute:c,
      Second: d 
    }
    let f = data.SeasonExamEndTime.getHours();
    let g = data.SeasonExamEndTime.getMinutes();
    let h = data.SeasonExamEndTime.getSeconds();
    let i = data.SeasonExamEndTime.toLocaleString();
    let j = {
      Hour:f,
      Minute:g,
      Second: h
    }
    let dataObj= {
      Id: data.Id,
      SeasonName: data.SeasonName,
      CountryId: data.CountryId,
      SeasonRegStartDate: data.SeasonRegStartDate,
      SeasonRegEndDate: data.SeasonRegEndDate,
      // SeasonExamTime: e,
      SeasonExamDate: data.SeasonExamDate,
      MaxSelectedCandidate: data.MaxSelectedCandidate,
      MinNumberofGirlsinMix: data.MinNumberofGirlsinMix,
      ExamTime: data.SeasonExamTime.toLocaleString('en-GB'),
      SeasonExamEndDate: data.SeasonExamEndDate,
      SeasonExamEndTime: data.SeasonExamEndTime.toLocaleString('en-GB'),
      Endtime: j,
      time: e
    }
    this.loaderService.show();
      this.loginService.addseason(dataObj).subscribe(
        (res: any) => {
          this.alert.success("Season is added", true);
          this.rForm.reset();
          this.loaderService.hide();
          this.redirect();
        },
        error => {
          this.loaderService.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
  }
  updateSeason(data){
    this.loaderService.show();
      this.loginService.updateSeason(data.value, this.uid).subscribe(
        (res: any) => {
          this.alert.success("Season is updated", true);
          this.rForm.reset();
          this.loaderService.hide();
          this.redirect();
        },
        error => {
          this.loaderService.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
  }
  redirect(): any {
    var cUrl = document.location.href;
        var urlaparam = '';
        if(cUrl.includes('super')){
          urlaparam = 'super-admin';
        }else{
          urlaparam = 'admin'
        }
        const url = "/" + urlaparam +`/season-settings`;
         this.router.navigateByUrl(url);
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !/[\d\s]/.test(input);
  }
}
