import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiServerService } from 'src/app/service/api-server.service';
import { Helper } from '../../../_model/helper';
declare var $: any;
@Component({
  selector: 'app-list-loc',
  templateUrl: './list-loc.component.html',
  styleUrls: ['./list-loc.component.css']
})
export class ListLocComponent implements OnInit {
  loc: any;

  regionData: any;
  regionName: any;

  countryData: any;
  countryName: any;

  stateData: any;
  stateName: any;
  constructor(
    private loginService: ApiServerService,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getLocationData();
  }

  getLocationData() {
    this.loginService.allLocationDataAdmin().subscribe(
      (res: any) => {
        this.loc = res;
      },
      error => {
      }
    );
  }

  // region Operation
  EditRegion(data) {
    this.regionData = data;
    this.regionName = data.Region;
    $('#region').modal('show');
  }
  DeleteRegion(data) {
    this.regionData = data;
    this.regionName = data.Region;
    $('#deleteregion').modal('show');
  }
  updateRegion() {
    const data = {
      DisplayName: this.regionName,
      Value: this.regionData.Id
    };

    if (
      this.regionName.length !== 0 &&
      this.regionName !== '' &&
      this.regionName !== undefined
    ) {
      this.loginService.addNewregion(data).subscribe(
        (res: any) => {
          this.alert.success('Update successfully', true);
          this.getLocationData();
          $('#region').modal('hide');
        },
        error => {
          this.alert.error('Region not added');
        }
      );
    } else {
      this.alert.error('Enter the Region Name', true);
    }
  }

  deleteRegion() {
    this.loginService.deleteRegion(this.regionData.Id).subscribe(
      (res: any) => {
        this.alert.success('Deleted successfully', true);
        this.getLocationData();
        $('#deleteregion').modal('hide');
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // country operation
  editCountry(data) {
    this.countryData = data;
    this.countryName = data.DisplayName;
    $('#country').modal('show');
  }
  deleteCountry(data) {
    this.countryData = data;
    this.countryName = data.DisplayName;
    $('#deletecountry').modal('show');
  }
  updateCountry() {
    if (
      this.countryName.length !== 0 &&
      this.countryName !== '' &&
      this.countryName !== undefined
    ) {
      const addCountry = {
        RegionId: this.countryData.Value,
        Value: this.countryData.Value,
        DisplayName: this.countryName
      };
      this.loginService.addNewCountry(addCountry, this.countryData.Value).subscribe(
        (res: any) => {
          this.alert.success('added successfully', true);
          this.getLocationData();
          $('#country').modal('hide');
        },
        error => {
          this.alert.error('Country not added');
        }
      );
    } else {
      this.alert.error('Enter the Country Name', true);
    }
  }
  DeleteCountry() {
    this.loginService.deleteCountry(this.countryData.Value).subscribe(
      (res: any) => {
        this.alert.success('Deleted successfully', true);
        this.getLocationData();
        $('#deletecountry').modal('hide');
      },
      error => {
        this.alert.error('Country not deleted', true);
      }
    );
  }

  // state operation
  editState(data) {
    this.stateName = data.DisplayName;
    this.stateData = data;
    $('#state').modal('show');
  }
  deleteState(data) {
    this.stateName = data.DisplayName;
    this.stateData = data;
    $('#deletestate').modal('show');
  }

  UpdateState() {

 if (
      this.stateName.length !== 0 &&
      this.stateName !== '' &&
      this.stateName !== undefined
    ) {
      const addCountry = {
        CountryId: this.stateData.Value,
        DisplayName: this.stateName,
        Value: this.stateData.Value,
        isactive: true
      };
      this.loginService.addNewState(addCountry, this.stateData.Value).subscribe(
        (res: any) => {
          this.alert.success('Update successfully', true);
          $('#state').modal('hide');
         this.getLocationData();
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    } else {
      this.alert.error('Enter the State Name', true);
    }
  }


  DeleteState() {
      this.loginService.deleteState(this.stateData.Value).subscribe(
      (res: any) => {
        this.alert.success('Deleted successfully', true);
        this.getLocationData();
        $('#deletestate').modal('hide');
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
}
