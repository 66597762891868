import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { ApiServerService } from '../../../service/api-server.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../../_service/alert.service';
import { Helper } from '../../../_model/helper';
@Component({
  selector: 'app-result-report',
  templateUrl: './result-report.component.html',
  styleUrls: ['./result-report.component.css']
})
export class ResultReportComponent implements OnInit {
  isDataPresent: boolean;

  filterType: any = null;
  value: any = null;

  countryId: any;
  regionId: any;
  Region: any;
  Country: any;
  state: any;
  resultList: any;

  Name: any = null;
  School: any = null;
  ExaminationNo: any = null;

  // For pagination purpose
  Skip = 0;
  Take = 10;
  TotalRecords: number;
  TotalPages: number;
  totalRowPerPage = 10;

  recordStarting: number;
  recordEnding: number;
  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.Initialrefresh();
    this.getAllRegion();
    // this.getState();
  }
  //  getState() {
  //     this.loginService.getAllStateNameService().subscribe(
  //       (res:any)=>{
  //         this.state = res;
  //       },
  //       error => {
  //         this.alert.error(Helper.getMessageFromError(error), true);
  //       }
  //     );
  //   }

  // state list
  getAllState(countryId) {
    this.loginService.getallState(countryId).subscribe(
      (res: any) => {
        this.state = res;
      },
      error => {
        this.alert.error('State is not able to fetched', true);
      }
    );
  }
  // country list
  getAllCountry(regionId) {
    this.loginService.getallCountry(regionId).subscribe(
      (res: any) => {
        this.Country = res;
      },
      error => {
      }
    );
  }
  // region list
  getAllRegion() {
    this.loginService.getAllRegions().subscribe(
      (res: any) => {
        this.Region = res;        
      },
      error => {

      }
    );
  }
  // country selected event listener
  countrySelected(e) {
    if (e !== 'null') {
      this.countryId = e;

      this.getAllState(this.countryId);
    } else {
      this.alert.error('Select the Country', true);
      // this.Region = undefined;
      // this.countryId = null;
      this.state = undefined

    }
  }

  // region selected event listener
  regionSelected(e) {
    if (e !== 'null') {
      this.regionId = e;
      this.Country = null;
      this.state = null;
      this.getAllCountry(this.regionId);
    } else {
      // this.alert.error('Select the Region', true);
      this.regionId = undefined;
      this.state = undefined;
    }
  }

  stateSelected(value) {
    this.value = value;
  }

  search() {
    if (this.School === '') {
      this.School = null;
    } else if (this.ExaminationNo === '') {
      this.ExaminationNo = null;
    } else if (this.Name === '') {
      this.Name = null;
    }
    const data = {
      RegionId: this.regionId,
      CountryId: this.countryId,
      Skip: this.Skip,
      Take: this.Take,
      Name: this.Name,
      StateId: this.value,
      SchoolName: this.School,
      RegistrationNumber: this.ExaminationNo
    };
      this.loginService.resultReport(data).subscribe(
        (res: any) => {
          if (res.length === 0) {
            this.alert.error('No Record Found', true);
            this.isDataPresent = true;
          } else {
            this.isDataPresent = false;
          }
          this.resultList = res.List;
          this.TotalRecords = res.TotalRecords;
          this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
          this.recordStarting = 1;
          if (this.TotalRecords < this.Take) {
            this.recordEnding = this.TotalRecords;
          } else {
            this.recordEnding = this.Take;
          }
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
  }
  clear() {
    this.getAllRegion();
    this.regionId = undefined;
    this.Skip = 0;
    this.Take = 10;
    this.state = undefined;
    this.Name = null;
    this.ExaminationNo = null;
    this.School = null;
    this.Initialrefresh();
  }

  refresh() {
    const data = {
      RegionId: this.regionId,
      CountryId: this.countryId,
      Skip: this.Skip,
      Take: this.Take,
      Name: this.Name,
      StateId: this.value,
      SchoolName: this.School,
      RegistrationNumber: this.ExaminationNo
    };

    this.loginService.resultReport(data).subscribe(
      (res: any) => {
        this.resultList = res.List;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  Initialrefresh() {
    this.filterType = null;
    this.value = null;
    const data = {
      Skip: this.Skip,
      Take: this.Take
    };
    this.loginService.resultReport(data).subscribe(
      (res: any) => {
        if (res.length === 0) {
          this.alert.error('No Record Found', true);
          this.isDataPresent = true;
        } else {
          this.isDataPresent = false;
        }
        this.resultList = res.List;
        this.TotalRecords = res.TotalRecords;
        this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
        this.recordStarting = 1;

        if (this.TotalRecords < 10) {
          this.recordEnding = this.TotalRecords;
        } else {
          this.recordEnding = this.Take;
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  RowPerPage(e) {
    this.totalRowPerPage = Number(e.target.value);
    this.Take = this.totalRowPerPage;
    this.Skip = 0;
    this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
    // page number
    // this.recordStarting = this.Skip;
    if (this.Skip === 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }
    const totalrecord = this.Skip + this.Take;
    if (totalrecord === this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }

  firstPage() {
    this.Skip = 0;
    this.Take = this.totalRowPerPage;
    this.recordStarting = 1;
    if (this.TotalRecords < this.Take) {
      this.recordEnding = this.TotalRecords;
    } else {
      this.recordEnding = this.Take;
    }

    this.refresh();
  }
  nextPage() {
    if (this.recordEnding < this.TotalRecords) {
      this.Skip += this.totalRowPerPage;
      this.Take = this.totalRowPerPage;

      // for page number
      if (this.Skip === 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }
      const totalrecord = this.Skip + this.Take;

      if (totalrecord === this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  priviousPage() {
    if (this.recordStarting > 1) {
      this.Skip -= this.totalRowPerPage;
      this.Take = this.totalRowPerPage;

      // for page number
      // for page number
      if (this.Skip === 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }

      const totalrecord = this.Skip + this.Take;

      if (totalrecord === this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  lastPage() {
    const lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
    this.Skip = lastPage;
    this.Take = this.totalRowPerPage;

    // for the page number
    if (this.Skip === 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }

    const totalrecord = this.Skip + this.Take;

    if (totalrecord === this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }
}
