import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiServerService } from "src/app/service/api-server.service";
import { DataService } from "src/app/service/data.service";
import { FormBuilder } from "@angular/forms";
import { AlertService } from "src/app/_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Http, Headers } from "@angular/http";
import { Helper } from 'src/app/_model/helper';
@Component({
  selector: 'app-syllabus',
  templateUrl: './syllabus.component.html',
  styleUrls: ['./syllabus.component.css']
})
export class SyllabusComponent implements OnInit , AfterViewInit {
syllabusList:any;
  countryId: any;
  constructor(
       private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public http: Http
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.countryId = params['countryId'];
    })
   }

  ngOnInit() {
    this.getSyllabus();
  }
  ngAfterViewInit() {
    Helper.minViewHeight()
    
  }
  getSyllabus() {
    if(this.countryId){
      this.loginService.getSyllabus(this.countryId).subscribe(
        (res:any)=>{
          this.syllabusList = res;
        },
        error => {
          this.alert.error("Something went wrong", true);
        }
      );
    }
    }
    
}
