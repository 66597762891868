import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../service/baseLink";
import { Helper } from "../../_model/helper";
import { LoaderService } from "../../service/loader.service";
@Component({
  selector: "app-competition-initial",
  templateUrl: "./competition-initial.component.html",
  styleUrls: ["./competition-initial.component.css"]
})
export class CompetitionInitialComponent implements OnInit {
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    this.auth = JSON.parse(localStorage.getItem("auth"));
  }
  ServerAddress: any;
  list: any;
  auth: any;
  array = [];
  size: number = 0;
  condidateCount: number;
  check: boolean = false;
  disable: any;
  Competition = [];
  SaveData = [];
  class: any;
  paginationViewOff: boolean = false;
  examinationSlip: boolean = false;
  minNoOfGirls: any;
  displayMinNoOfGirls: boolean = false;
content:any="Register the candidate to generate the examination slip";
  //For pagination purpose
  Skip: number = 0;
  Take: number = 10;
  TotalRecords: number;
  TotalPages: number;
  totalRowPerPage: number = 10;

  recordStarting: number;
  recordEnding: number;
  classKey: any;
  //XXXXXXXXXXXXXXXXXXXXXXx

  ngOnInit() {
    this.getClass();
    this.getAllCompleteCandidate();
    this.ServerAddress = myapi.BaseUrl;
    this.loginService.getMinNoOfGirls().subscribe(
      (res: any) => {
        this.minNoOfGirls = res.MinNoOfGirlsInMix;
      },
      error => {}
    );
    let Type = JSON.parse(localStorage.getItem("SchoolDetails")).Type;
    if(Type != "Girl" && Type != "Boy") this.displayMinNoOfGirls = true;
  }
  //get all ca ndidate of school

  //this is called when we are going to next page
  confirmPage() {
    // if (this.array.length < 6) {
    //   this.alert.error("Select minimum 6 canadidate", true);
    // }
    if (this.array.length < 6 || this.array.length>10) {
      this.alert.error("Select minimum 6 and maximum 10 candidates", true);
    }
    else if(this.displayMinNoOfGirls && this.minNoOfGirls>0){
      this.alert.error(`Select Required Minimum Girls`, true);
    }
     else {
      this.data.changeMessage(this.list.List[0].Class);
      this.data.changeMessage2(this.array);
      this.router.navigate(["school/Competition-final"]);
    }
  }

  //logic for adding 6 items in array in checkbox event
  addCandidate(e, i) {
    // if (e.target.checked && this.size < 6) {
      if (e.target.checked && this.size < 10) {
      var a = this.array.indexOf(i);
      if (a == -1) {
        i.Register = true;
        this.array.push(i);
        if(i.Gender == "Girl" || i.Gender == "girl"){
          this.minNoOfGirls = this.minNoOfGirls - 1;
        }
      }
      this.size = this.array.length;
      if (this.size == 6) {
      }
    } else if (!e.target.checked) {
      var j = this.array.indexOf(i);
      if (j > -1) {
        this.array.splice(j, 1);
        i.Register = false;
        if(i.Gender == "Girl" || i.Gender == "girl"){
          this.minNoOfGirls = this.minNoOfGirls + 1;
        }
      }
      this.size = this.array.length;
    }    
  }

  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  //getting the class list from the server side
  getClass() {
    this.loginService.getclass().subscribe(
      (res:any)=>{
        this.class = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  //when the we select the all candidate from dropdown.
  classChange(e) {
    var a = 0;
    this.array = [];
    this.classKey = e.target.value;
    if (e.target.value == "allCandidate") {
      this.getAllCompleteCandidate();
    } else {
      this.Skip = 0;
      this.Take = 10;
      var data = {
        Skip: this.Skip,
        Take: this.Take
      };
      this.array.splice(0, this.array.length);
      this.size = 0;
      this.loginService.getAllCompleteCandidateClassWise(data, this.classKey).subscribe(
        (res:any)=>{          
          if (!a) {
            a++;
            res.List.forEach(regi => {
              if (regi.IsRegistered) {
                this.array.push(regi);
                if (this.array.length > 5) {
                  // if (this.array.length > 9) {
                  this.generateExaminationSlip();
                } else {
                  this.examinationSlip = false;
                  this.content = "To view the candidate examination slip";
                }
              } else if (this.array.length < 6) {
              // } else if (this.array.length < 10) {
                this.examinationSlip = false;
                this.content = "Register the candidate to generate the examination slip";
              }
            });
          }
          this.list = res;
          if (this.list.TotalRecords == 0) {
            this.paginationViewOff = true;
          }
          this.TotalRecords = res.TotalRecords;
          this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
          this.recordStarting = 1;
          if (this.TotalRecords <= this.Take) {
            this.recordEnding = this.TotalRecords;
          } else {
            this.recordEnding = this.Take;
          }
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }

  //get all candidate of school
  getAllCompleteCandidate() {
    this.condidateCount = 0;
    var a = 0;
    var dataPage = { Skip: this.Skip, Take: this.Take};
    this.loaderService.show();
    this.loginService.getAllCompleteCandidateSchoolWise(dataPage).subscribe(
      (res:any)=>{
    this.loaderService.hide();

        if (res == null) {
          return;
        }
        this.list = res;
        this.TotalRecords = res.TotalRecords;
        this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
        this.recordStarting = 1;
        this.recordEnding = this.Take;
        this.TotalRecords = res.TotalRecords;
        this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
        if (!a) {
          a++;
          this.list.List.forEach(regi => {
            if (regi.IsRegistered) {
              this.array.push(regi);
              if (this.array.length > 5) {
                // if (this.array.length > 10) {
                this.content = "To view the candidate examination slip";
                this.generateExaminationSlip();
              } else {
                this.examinationSlip = false;
                 this.content = "Register the candidate to generate the examination slip";
              }
            }
          });
        }
        // this.loaderService.hide();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
        this.loaderService.hide();
      }
    );
    // if (this.array.length > 5) {
      if (this.array.length > 9) {
      this.content = "To view the candidate examination slip";
      this.generateExaminationSlip();
    } else {
      this.examinationSlip = false;
       this.content = "Register the candidate to generate the examination slip";
    }
  }

  generateExaminationSlip() {
    this.alert.success("Allready candidate registered for competition", true);
    this.examinationSlip = true;
    // setTimeout(() => {
    //   this.data.changeMessage2(this.array);
    //   this.examinationSlip = true;
    //   // this.router.navigate(['/school/examination-slip']);
    // }, 1000);
  }

  //refresh
  refresh() {
    if (this.classKey == "allCandidate" || this.classKey == undefined) {
      var dataPage = { Skip: this.Skip, Take: this.Take};
      this.loginService
        .getAllCompleteCandidateSchoolWise(dataPage)
        .subscribe(
          (res:any)=>{
            this.list = res;
          },
          error => {
            this.alert.error(Helper.getMessageFromError(error), true);
          }
        );
    } else {
      var data = {
        Class: this.classKey,
        School: this.auth.Id,
        Skip: this.Skip,
        Take: this.Take
      };
      this.loginService.getCandidateClassWise(data).subscribe(
        (res:any)=>{
          this.list = res;
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }

  //All Pagination Function
  RowPerPage(e) {
    this.totalRowPerPage = Number(e.target.value);
    this.Take = this.totalRowPerPage;
    this.Skip = 0;
    this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
    //page number
    this.recordStarting = this.Skip;
    var totalrecord = this.Skip + this.Take;
    if (totalrecord == this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }

  firstPage() {
    this.Skip = 0;
    this.Take = this.totalRowPerPage;
    this.recordStarting = 1;
    if (this.TotalRecords < this.Take) {
      this.recordEnding = this.TotalRecords;
    } else {
      this.recordEnding = this.Take;
    }
    this.refresh();
  }

  nextPage() {
    if (this.recordEnding < this.TotalRecords) {
      this.Skip += this.totalRowPerPage;
      this.Take = this.totalRowPerPage;
      //for page number
      if (this.Skip == 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }
      var totalrecord = this.Skip + this.Take;

      if (totalrecord == this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }

  priviousPage() {
    if (this.recordStarting > 1) {
      this.Skip -= this.totalRowPerPage;
      this.Take = this.totalRowPerPage;
      //for page number
      //for page number
      if (this.Skip == 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }
      var totalrecord = this.Skip + this.Take;

      if (totalrecord == this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }
      this.refresh();
    }
  }

  lastPage() {
    var lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
    this.Skip = lastPage;
    this.Take = this.totalRowPerPage;
    //for the page number
    if (this.Skip == 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }
    var totalrecord = this.Skip + this.Take;

    if (totalrecord == this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }
}
