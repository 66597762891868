export class SeasonManagementModel {
     Id: number;
     Season: String;
     Region: String;
     RegistrationStartDate: String; 
     RegistrationEndDate: String;
     ExamDate: String;
     ExamTime: String;
     MinNoOfGirlsInMix: number;
}
export class SeasonManagementFilterModel {
    constructor() {
        this.Order = 'Asc';
        this.Skip = 0;
        this.Take = 1000;
    }
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}