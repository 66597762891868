import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { map } from  "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { Helper } from "../../_model/helper";
declare var $:any;
@Component({
  selector: "app-school-header",
  templateUrl: "./school-header.component.html",
  styleUrls: ["./school-header.component.css"]
})
export class SchoolHeaderComponent {
  ngAfterViewInit() {
    Helper.dynamicHeader();
  }
  header: boolean = false;
  constructor(router: Router) {
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        var url = document.location.pathname;
        if (
          url === "/school" ||
          url === "/school/profile" ||
          url === "/school/Competition-initial" ||
          url === "/school/Competition-final" ||
          url === "/school/add-candidate" ||
          url === "/school/all-candidate" ||
          url === "/school/privious-candidate" ||
          url === "/school/candidate-draft" ||
          url.includes("candidate-update") ||
          url === "/school/examination-slip"
        ) {
          this.header = true;
        } else {
          this.header = false;
        }
      }
    });
  }

  RemoveCred() {
    localStorage.clear();
  }

  
}
