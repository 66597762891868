import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminRoutingModule } from "./admin-routing.module";
import { SchoolModule } from "src/app/school/school.module";
import { BrowserModule } from "@angular/platform-browser";
import { HttpModule } from "@angular/http";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgDatepickerModule } from "ng2-datepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AddCentreComponent } from "src/app/AdmAccess/examcentre/add-centre/add-centre.component";
import { CentreListComponent } from "src/app/AdmAccess/examcentre/centre-list/centre-list.component";
import { FullMigrateComponent } from "src/app/AdmAccess/examcentre/full-migrate/full-migrate.component";
import { PartialMigrateComponent } from "src/app/AdmAccess/examcentre/partial-migrate/partial-migrate.component";
import { AddSeasonComponent } from "src/app/AdmAccess/season/add-season/add-season.component";
import { SeasonListComponent } from "src/app/AdmAccess/season/season-list/season-list.component";
import { SchoolListComponent } from "src/app/AdmAccess/schoolAndCandidate/school-list/school-list.component";
import { CandidateListComponent } from "src/app/AdmAccess/schoolAndCandidate/candidate-list/candidate-list.component";
import { SchoolRegReportComponent } from "src/app/AdmAccess/report/school-reg-report/school-reg-report.component";
import { ContactReportComponent } from "src/app/AdmAccess/report/contact-report/contact-report.component";
import { CandidateRegReportComponent } from "src/app/AdmAccess/report/candidate-reg-report/candidate-reg-report.component";
import { CentreUtilizationReportComponent } from "src/app/AdmAccess/report/centre-utilization-report/centre-utilization-report.component";
import { CentreCandidateScheduleComponent } from "src/app/AdmAccess/report/centre-candidate-schedule/centre-candidate-schedule.component";
import { FaqListComponent } from "src/app/AdmAccess/faqContact/faq-list/faq-list.component";
import { ContactUsComponent } from "src/app/AdmAccess/faqContact/contact-us/contact-us.component";
import { UploadQuestionComponent } from "src/app/AdmAccess/faqContact/upload-question/upload-question.component";
import { AdminComponent } from "./admin/admin.component";
import { SuperAdminModule } from "src/app/superAdmin/super-admin.module";
import { AdminHeaderComponent } from "src/app/admin/admin-header/admin-header.component";
import { EditHomePageComponent } from "./edit-home-page/edit-home-page.component";
import { AddLocComponent } from "../AdmAccess/location/add-loc/add-loc.component";
import { ListLocComponent } from "src/app/AdmAccess/location/list-loc/list-loc.component";
import { SharedModule } from "../_shared/sharedmodule";



@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SchoolModule,
    SuperAdminModule,
    BrowserModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgDatepickerModule,
    SharedModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    AdminComponent,
    AdminHeaderComponent,
    EditHomePageComponent,
    AddLocComponent,
    ListLocComponent,
  ]
})
export class AdminModule {}
