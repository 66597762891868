import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';

@Component({
  selector: 'app-school-category-drop-down',
  templateUrl: './school-category-drop-down.component.html',
  styleUrls: ['./school-category-drop-down.component.css']
})
export class SchoolCategoryDropDownComponent implements OnInit {
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<any> = new EventEmitter();
  val = '';
  constructor() { }

  ngOnInit() {
  }

  categoryChange(event, type) {
    const value = { FieldName: this.field.FieldName, Value: event.target.value, EventType: type };
    this.Changed.emit(value);
  }

  Reset() {
    this.val = '';
  }
}
