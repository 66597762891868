import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiServerService } from 'src/app/service/api-server.service';
import { AlertService } from 'src/app/_service/alert.service';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';
import { Helper } from '../../_model/helper';

@Component({
  selector: 'app-class-drop-down',
  templateUrl: './class-drop-down.component.html',
  styleUrls: ['./class-drop-down.component.css']
})
export class ClassDropDownComponent implements OnInit {
  public class: any;
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<any> = new EventEmitter();
  val = '';
  constructor(private loginService: ApiServerService, private alert: AlertService) { }

  ngOnInit() {
    this.loginService.getclass().subscribe(
      (res: any) => {
        this.class = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  classChange(event, type) {
    const value = { FieldName: this.field.FieldName, Value: event.target.value, EventType: type };
    this.Changed.emit(value);
  }

  Reset() {
    this.val = '';
  }
}
