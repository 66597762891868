import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../service/baseLink";
import { Helper } from "../../_model/helper";
import { LoaderService } from "../../service/loader.service";
@Component({
  selector: "app-examination-slip",
  templateUrl: "./examination-slip.component.html",
  styleUrls: ["./examination-slip.component.css"]
})
export class ExaminationSlipComponent implements OnInit {
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {}
baseUrl:any = myapi.BaseUrl ;
  locaData: any;
  list:any;
  array=[];

  ngOnInit() {
    this.getRegisterCandidate();
  }

  getRegisterCandidate() {
    var a = 0;
    var dataPage = { Skip: 0, Take: 10000};
    this.loaderService.show();
    this.loginService.getAllCompleteCandidateSchoolWise(dataPage).subscribe(
      (res:any)=>{
        this.loaderService.hide();
        if (res == null) {
          return;
        }        
        this.list = res;
        if (!a) {
          a++;
          this.list.List.forEach(regi => {
            if (regi.IsRegistered) {
              this.array.push(regi);
            }
          });
        }
      },
      error => {
        this.loaderService.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

downloadSlip(link){
  var path = `${this.baseUrl}${link}`;
   window.open(path, "_blank");
}

}
