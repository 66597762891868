import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../service/api-server.service';
import { DataService } from '../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Helper } from '../../_model/helper';
import {
  GridColumnType,
  PropertyMetaModel,
  PaginationModel
} from '../../_model/pagination-model';
import { ExaminerFilterModel, ExaminerGridModelResult } from './examiner-model';
import { FilterFieldModel, FieldType } from '../../_model/filter-field-model';
import { ExcelService } from '../../_model/exceldownload.helper';
declare var $: any;
@Component({
  selector: 'app-examiner',
  templateUrl: './examiner.component.html',
  styleUrls: ['./examiner.component.css']
})
export class ExaminerComponent implements OnInit {
  public displayColumns: PropertyMetaModel[] = [];
  public pageInfo = new PaginationModel();
  public filter: ExaminerFilterModel = new ExaminerFilterModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public gridData: ExaminerGridModelResult[] = [];
  isResultPublished: boolean;
  Message: string;

  constructor(
    private loginService: ApiServerService,
    private excelService: ExcelService,
    private alert: AlertService,
    private router: Router,
  ) {
    this.displayColumns = [
      {
        PropertyName: 'Name',
        ColumnName: 'Candidate Name',
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: 'RegistrationNumber',
        ColumnName: 'Registration Number',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Class',
        ColumnName: 'Class',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'ExamCenter',
        ColumnName: 'Exam Center',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Status',
        ColumnName: 'Status',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Score',
        ColumnName: 'Score',
        PropertyType: GridColumnType.EditableText
      },
      {
        PropertyName: 'DOB',
        ColumnName: 'DOB',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'CanUpdate',
        ColumnName: 'Update',
        PropertyType: GridColumnType.Action,
        DisplayText: 'Update'
      },
      {
        PropertyName: 'CanUpdate',
        ColumnName: 'Action',
        PropertyType: GridColumnType.Action,
        DisplayText: 'Disqualify'
      },
      {
        PropertyName: 'CanUpdate',
        ColumnName: 'Action',
        PropertyType: GridColumnType.Action,
        DisplayText: 'Mark Absent'
      }
    ];
    this.Fields = [
      {
        FieldName: 'RegistrationNumber',
        Label: 'Registration Number',
        Placeholder: 'Registartion number',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'Name',
        Label: 'Candidate Name',
        Placeholder: 'Please enter school name',
        FieldType: FieldType.Text
      },
      {
        FieldName: 'ClassId',
        Label: 'Class',
        Placeholder: 'Please enter school name',
        FieldType: FieldType.ClassDropdown
      }
    ];
  }

  ngOnInit() {
    this.getAllCandidates();
    this.isResultPublished = Boolean(localStorage.getItem('isResultPublished'));
  }
  getAllCandidates(): any {
    this.gridData = [];
    const page: any = {};
    page.Skip = (this.pageInfo.CurrentPage - 1) * this.pageInfo.PageSize;
    page.Take = this.pageInfo.PageSize;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loginService.getAllCandidate(this.filter, page).subscribe(
      (res: any) => {
        if (res && res.List) {
          res.List.forEach(element => {
            element.CanUpdate = true;
          });
          this.gridData = res.List;
          this.pageInfo.TotalRecord = res.TotalRecords;
          this.pageInfo.PageSize = this.filter.Take;
        }
      },
      err => { }
    );
  }

  RemoveCred() {
    localStorage.clear();
    this.router.navigate(['/']);
  }


  action(event: any) {
    if (event.action === 'Update') {
      this.updateCandidateScore(event);
    } else if (event.action === 'Disqualify') {
      this.disqualifyCandidate(event.value.Id);
    } else if (event.action === 'Mark Absent') {
      this.markAbsent(event.value.Id);
    }
  }

  markAbsent(Id: any): any {
    this.loginService.markCandidateAbsent(Id).subscribe(res => {
      this.alert.success('Updated succesfully');
      this.getAllCandidates();
    },
      error => {
        this.alert.error(Helper.getMessageFromError(error));
      });
  }
  disqualifyCandidate(Id: any): any {
    this.loginService.disqualifyCandidate(Id).subscribe(res => {
      this.alert.success('Updated succesfully');
      this.getAllCandidates();
    },
      error => {
        this.alert.error(Helper.getMessageFromError(error));
      });
  }

  updateCandidateScore(event: any): any {
    this.loginService.updateScore(event.value).subscribe(res => {
      this.alert.success('Score updated successfully');
      this.getAllCandidates();
    }, error => {
      this.alert.error(Helper.getMessageFromError(error));
    });
  }

  pageInfoChange(event: PaginationModel) {

    this.pageInfo.CurrentPage = event.CurrentPage;
    this.pageInfo.PageSize = event.PageSize;
    this.pageInfo.SortOrder = event.SortOrder;
    this.pageInfo.SortOrder = event.SortOrder;
    this.getAllCandidates();
  }

  filterChange(event: any) {
    if (event && event.length > 0) {
      event.forEach(element => {
        this.filter[element.FieldName] = element.Value;
      });
    } else {
      this.filter = new ExaminerFilterModel();
    }
    this.pageInfo.CurrentPage = 1;
    this.getAllCandidates();
  }

  download() {
    const page: any = {};
    page.Skip = 0;
    page.Take = 1000;
    page.OrderBy = this.pageInfo.SortBy;
    page.Order = this.pageInfo.SortOrder;
    this.loginService.getAllCandidateScore().subscribe(
      (res: any) => {
        this.downloadFile(res);
      },
      error => { }
    );
  }

  downloadFile(data: any) {
    this.excelService.exportAsExcelFile(data, 'Candidate Score:');
  }

  excelSheetFormatView() {
    $('#ExcelSheet').modal('show');
  }

  fileChange(event) {
    $('#ExcelSheet').modal('hide');
    const form_data = new FormData();
    form_data.append('file', event.target.files[0]);
    this.loginService.uploadExcel(form_data).subscribe(
      (res: any) => {
        this.alert.success(res, true);
        this.getAllCandidates();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  publish() {
    this.Message = 'Are you sure, you want to publish result?';
    if (this.isResultPublished) {
      this.Message += 'Results have been published before, It will republish again';
    }
    $('#publishResult').modal('show');
  }

  confirmPublish() {
    $('#publishResult').modal('hide');
    this.loginService.publishResult().subscribe(res => {
      this.alert.success('Result Published Successfully');
      this.isResultPublished = true;
    },
    error => {
      this.alert.error(Helper.getMessageFromError(error), true);
    });
  }

}

//   initialrefresh() {
//     var data = {
//       Skip: this.Skip,
//       Take: this.Take,
//       Register: true
//     };
//     this.loader = true;
//     this.loginService.getAllCandidate(data).subscribe(
//       (res:any)=>{
//         this.list = res;
//         if (res == null) {
//           this.loader = false;
//           return 0;
//         }
//         this.TotalRecords = res.TotalRecords;
//         this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
//         this.recordStarting = 1;
//         if (this.TotalRecords < this.Take) {
//           this.recordEnding = this.TotalRecords;
//         } else {
//           this.recordEnding = this.Take;
//         }
//         this.loader = false;
//       },
//       error => {
//         this.alert.error(Helper.getMessageFromError(error), true);
//         this.loader = false;
//       }
//     );
//   }

//   refresh() {
//     this.loader = true;
//     var data = {
//       Skip: this.Skip,
//       Take: this.Take,
//       Register: true
//     };
//     this.loginService.getAllCandidate(data).subscribe(
//       (res:any)=>{
//         if (res == null) {
//           this.loader = false;
//           return 0;
//         }

//         this.list = res;
//         this.TotalRecords = res.TotalRecords;
//         this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
//         this.loader = false;
//       },
//       error => {
//         this.alert.error(Helper.getMessageFromError(error), true);
//         this.loader = false;
//       }
//     );
//   }

//   update(i, id, item) {
//     this.updateItem = item;
//     $("#scoreModel").modal("show");
//     this.uid = id;
//     this.scoreUpdate = item.Score;
//   }

//   updateScoreModel() {
//     var data = {
//       Id: this.uid,
//       Score: this.scoreUpdate
//     };

//     this.loginService.updateScore(data).subscribe(
//       (res:any)=>{
//         this.alert.success("Score Updated", true);
//         this.refresh();
//         $("#scoreModel").modal("hide");
//       },
//       error => {
//         this.alert.error(Helper.getMessageFromError(error), true);
//       }
//     );
//   }

//   publishResult() {
//     this.pubLishResultLoader = true;
//     this.loginService.publishResult().subscribe(
//       (res:any)=>{
//         this.alert.success("Result Publish", true);
//         this.pubLishResultLoader = false;
//       },
//       error => {
//         this.alert.error(Helper.getMessageFromError(error), true);
//         this.pubLishResultLoader = false;
//       }
//     );
//   }
//   excelSheetFormatView() {
//     $("#ExcelSheet").modal("show");
//   }
//   DownloadExcelFormat() {
//     $("#ExcelSheet").modal("hide");
//   }

//   RowPerPage(e) {
//     this.totalRowPerPage = Number(e.target.value);
//     this.Take = this.totalRowPerPage;
//     this.Skip = 0;
//     this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
//     //page number
//     if (this.Skip == 0) {
//       this.recordStarting = 1;
//     } else {
//       this.recordStarting = this.Skip + 1;
//     }
//     // this.recordStarting = this.Skip;
//     var totalrecord = this.Skip + this.Take;
//     if (totalrecord == this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     } else if (totalrecord > this.TotalRecords) {
//       this.recordEnding = this.TotalRecords;
//     } else if (totalrecord < this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     }
//     this.refresh();
//   }

//   firstPage() {
//     this.Skip = 0;
//     this.Take = this.totalRowPerPage;
//     this.recordStarting = 1;
//     if (this.TotalRecords < this.Take) {
//       this.recordEnding = this.TotalRecords;
//     } else {
//       this.recordEnding = this.Take;
//     }

//     this.refresh();
//   }
//   nextPage() {
//     if (this.recordEnding < this.TotalRecords) {
//       this.Skip += this.totalRowPerPage;
//       this.Take = this.totalRowPerPage;

//       //for page number
//       if (this.Skip == 0) {
//         this.recordStarting = 1;
//       } else {
//         this.recordStarting = this.Skip + 1;
//       }
//       var totalrecord = this.Skip + this.Take;

//       if (totalrecord == this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       } else if (totalrecord > this.TotalRecords) {
//         this.recordEnding = this.TotalRecords;
//       } else if (totalrecord < this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       }

//       this.refresh();
//     }
//   }
//   priviousPage() {
//     if (this.recordStarting > 1) {
//       this.Skip -= this.totalRowPerPage;
//       this.Take = this.totalRowPerPage;

//       //for page number
//       //for page number
//       if (this.Skip == 0) {
//         this.recordStarting = 1;
//       } else {
//         this.recordStarting = this.Skip + 1;
//       }

//       var totalrecord = this.Skip + this.Take;

//       if (totalrecord == this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       } else if (totalrecord > this.TotalRecords) {
//         this.recordEnding = this.TotalRecords;
//       } else if (totalrecord < this.TotalRecords) {
//         this.recordEnding = totalrecord;
//       }

//       this.refresh();
//     }
//   }
//   lastPage() {
//     var lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
//     this.Skip = lastPage;
//     this.Take = this.totalRowPerPage;

//     //for the page number
//     if (this.Skip == 0) {
//       this.recordStarting = 1;
//     } else {
//       this.recordStarting = this.Skip + 1;
//     }

//     var totalrecord = this.Skip + this.Take;

//     if (totalrecord == this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     } else if (totalrecord > this.TotalRecords) {
//       this.recordEnding = this.TotalRecords;
//     } else if (totalrecord < this.TotalRecords) {
//       this.recordEnding = totalrecord;
//     }
//     this.refresh();
//   }

//   disqulaifyCandidate(candidateData) {
//     this.loginService.disqualifyCandidate(candidateData.Id).subscribe(
//       (res:any)=>{
//         this.alert.success("Disqualified candidate Successful", true);
//         this.initialrefresh();
//       },
//       error => {
//         this.alert.error(Helper.getMessageFromError(error), true);
//       }
//     );
//   }
// }
