import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  countryId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { 
      this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.countryId = params['countryId'];  
      });
    }

  ngOnInit() {
  }

}
