import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AlertService } from 'src/app/_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiServerService } from 'src/app/service/api-server.service';
import { Helper } from '../../../_model/helper';

@Component({
  selector: 'app-add-loc',
  templateUrl: './add-loc.component.html',
  styleUrls: ['./add-loc.component.css']
})
export class AddLocComponent implements OnInit {
  rForm: FormGroup;
  id: any;
  uid: any;

  regionEditor: boolean;
  countryEditor: boolean;
  stateEditor: boolean;

  dropdownRegion: boolean;
  dropdownCountry: boolean;
  dropdownState: boolean;

  Region: any; // list

  newRegiondata: any;


  Country: any; // list

  regionId: any;

  state: any; // dtata in form

  statelist: any; // list of state

  countryId: any;
  constructor(
    private loginService: ApiServerService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      region: [null, Validators.required],
      Region: [''],

      Country: [null, Validators.required],
      NewCountry: [''],

      state: [null, Validators.required],
      NewState: ['']
    });

    // route exachange
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.uid = params['uid'];
    });
    this.getAllRegion();
  }

  SendData(data) {
    if (data.valid) {
    } else {
      this.alert.error('Please fill the form correctly', true);
    }
  }

  // region list
  getAllRegion() {
    this.loginService.getAllRegions().subscribe(
      (res: any) => {
        this.Region = res;
      },
      error => {

      }
    );
  }

  // region select event listener
  regionSelected(e) {
    if (e.target.value === 'addregion') {
      this.dropdownCountry = false; // drop down of country is visible
      this.regionEditor = true; // region edit box and button visible
      this.countryEditor = false; // for the edit box for country and button
      this.dropdownState = false; // dropdown of state
      this.stateEditor = false; // edit box and button of state
      this.rForm.controls['Region'].setValidators([Validators.required]);
      this.rForm.controls['Region'].updateValueAndValidity();
    } else {
      this.regionId = e.target.value;
      this.getallCountry(e.target.value);
      this.regionEditor = false; // region edit box and button visible
      this.dropdownCountry = true; // drop down of country is visible
      this.countryEditor = false; // for the edit box for country and button
      this.dropdownState = false; // dropdown of state
      this.stateEditor = false; // edit box and button of state
      this.rForm.controls['Region'].clearValidators();
    }
  }

  // region update
  regionUpdate() {
    const data = this.rForm.value;
    if (
      data.Region.length !== 0 &&
      data.Region !== '' &&
      data.Region !== undefined
    ) {
      const dataPost = { DisplayName: data.Region };
      this.loginService.addNewregion(dataPost).subscribe(
        (res: any) => {
          this.alert.success('added successfully', true);
          this.getAllRegion();
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    } else {
      this.alert.error('Enter the Region Name', true);
    }
  }

  // get the country list
  getallCountry(regionid) {
    this.loginService.getallCountry(regionid).subscribe(
      (res: any) => {
        this.Country = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  countrySelected(e) {
    this.countryId = e.target.value;

    if (e.target.value === 'addcountry') {
      this.countryEditor = true; // for the edit box for country and button
      this.dropdownState = false; // dropdown of state
      this.stateEditor = false; // edit box and button of state
      this.rForm.controls['NewCountry'].setValidators([Validators.required]);
      this.rForm.controls['NewCountry'].updateValueAndValidity();
    } else {
      this.getallState(e.target.value);
      this.countryEditor = false; // for the edit box for country and button
      this.dropdownState = true; // dropdown of state
      this.stateEditor = false; // edit box and button of state
      this.rForm.controls['NewCountry'].clearValidators();
    }
  }

  countryUpdate() {
    const data = this.rForm.value;

    if (
      data.NewCountry.length !== 0 &&
      data.NewCountry !== '' &&
      data.NewCountry !== undefined
    ) {
      const addCountry = {
        // RegionId: this.regionId,
        DisplayName: data.NewCountry
      };
      this.loginService.addNewCountry(addCountry, this.regionId).subscribe(
        (res: any) => {

          this.alert.success('added successfully', true);
          this.getallCountry(this.regionId);
        },
        error => {
          this.alert.error('Country not added');
        }
      );
    } else {
      this.alert.error('Enter the Country Name', true);
    }
  }

  getallState(countryId) {
    this.loginService.getallState(countryId).subscribe(
      (res: any) => {
        this.statelist = res;
      },
      error => {
      }
    );
  }

  stateSelected(e) {
    if (e.target.value === 'addstate') {
      this.stateEditor = true; // state editor and button
      this.rForm.controls['NewState'].setValidators([Validators.required]);
      this.rForm.controls['NewState'].updateValueAndValidity();
    } else {
      this.stateEditor = false; // state editor and button
      this.rForm.controls['NewState'].clearValidators();
    }
  }

  stateUpdate() {
    const data = this.rForm.value;

    if (
      data.NewState.length !== 0 &&
      data.NewState !== '' &&
      data.NewState !== undefined
    ) {
      const addCountry = {
        DisplayName: data.NewState
      };
      this.loginService.addNewState(addCountry, this.countryId).subscribe(
        (res: any) => {
          this.alert.success('added successfully', true);
          this.getallState(this.countryId);
          this.rForm.reset();
        },
        error => {
          this.alert.error('state not added');
        }
      );
    } else {
      this.alert.error('Enter the State Name', true);
    }
  }
}
