import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';
import { ApiServerService } from 'src/app/service/api-server.service';

@Component({
  selector: 'app-season-dropdown',
  templateUrl: './season-dropdown.component.html',
  styleUrls: ['./season-dropdown.component.css']
})
export class SeasonDropdownComponent implements OnInit, OnChanges {
  @Input() countrySelected: any;
  public Seasons: any;
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<any> = new EventEmitter();
  public val = '';
  constructor(private loginService: ApiServerService) {}

  ngOnInit() {
    this.LoadSeasons();
  }

  LoadSeasons(): any {
    this.Seasons = [];
      this.loginService.getSeasonDD().subscribe(
        (res: any) => {
          this.Seasons = res;
        },
        error => {}
      );
  }

  seasonChange(event, type) {
    const value = {
      FieldName: this.field.FieldName,
      Value: event.target.value ? event.target.value : '',
      EventType: type
    };
    this.Changed.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  Reset() {
    this.val = '';
    this.field.Filter = null;
  }
}
