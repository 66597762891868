import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { PasswordValidation } from "./PasswordValidation";
import { Helper } from "../../_model/helper";
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  email: string;
  token: string;
  rForm: FormGroup;
  patternMail: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  patternPhone: string = "[0-9]*";
  patternName: string = "[a-zA-Z ]*";

  mainloader: boolean = false;
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.token = params["id"];
    });
    this.rForm = this.formBuilder.group(
      {
        password: [
          "",
          Validators.compose([Validators.required, Validators.minLength(3)])
        ],
        passwordr: ["", Validators.compose([Validators.required])]
      },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      }
    );
  }
  changePassword(data) {
    this.mainloader = true;
    let y = JSON.stringify(data.value);
    let z = JSON.parse(y).password;    
    let d = {
      NewPassword: z
      //"Token": this.token
    };
    this.loginService.changePassword(z, this.token).subscribe(
      (res:any)=>{
        this.mainloader = false;
        this.alert.success("Successfulfy Password Changed", true);
        this.router.navigate(['/login']);        
      },
      error => {
        this.mainloader = false;
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
}
