import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiServerService } from '../../service/api-server.service';
import { DataService } from '../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';
import { Router } from '@angular/router';
import { GridColumnType, PropertyMetaModel, PaginationModel } from 'src/app/_model/pagination-model';
import { debug } from 'util';
import { Helper } from '../../_model/helper';
import { LoaderService } from '../../service/loader.service';
declare var $: any;
@Component({
  selector: 'app-view-all-candidate',
  templateUrl: './view-all-candidate.component.html',
  styleUrls: ['./view-all-candidate.component.css']
})
export class ViewAllCandidateComponent implements OnInit {
  list: any;
  url = '';
  auth: any;
  public gridData: any[] = [];
  public displayColumns: PropertyMetaModel[];
  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };
  deleteId: any;
  // For pagination purpose
  Skip = 0;
  Take = 10;
  TotalRecords: number;
  TotalPages: number;
  totalRowPerPage = 10;

  recordStarting: number;
  recordEnding: number;
  activateAdd: boolean = false;
  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {
    this.displayColumns = [
      // { PropertyName: "link", ColumnName: "Name",
      // PropertyType: GridColumnType.DisplayLink, DisplayText: "Name", IsSortable: true, ClassName: "mylink mypointer" },
      { PropertyName: 'Name', ColumnName: 'Name', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'Email', ColumnName: 'Email', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'DOB', ColumnName: 'Date of Birth', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'PhoneNumber', ColumnName: 'Mobile Number', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'Gender', ColumnName: 'Gender', PropertyType: GridColumnType.Text, IsSortable: true },
      { PropertyName: 'Status', ColumnName: 'Status', PropertyType: GridColumnType.Text, IsSortable: true },
      // { PropertyName: "centrelink", ColumnName: "#CandidateCount", 
      // PropertyType: GridColumnType.DisplayLink, DisplayText: "CandidateCount", IsSortable: true },
      // { PropertyName: "IsActive", ColumnName: "Active", PropertyType: GridColumnType.Boolean, IsSortable: true },
      { PropertyName: 'Id', ColumnName: 'Delete', PropertyType: GridColumnType.Icon, DisplayText: 'Delete', ClassName: 'mypointer' },
      { PropertyName: 'Id', ColumnName: 'Edit', PropertyType: GridColumnType.Icon, DisplayText: 'Edit', ClassName: 'mypointer' },
      // { PropertyName: "id", ColumnName: "", PropertyType: GridColumnType.Action, DisplayText:  }
    ];

    this.auth = JSON.parse(localStorage.getItem('auth'));
  }

  ngOnInit() {
    this.getSeason();
    this.loaderService.show();
    const dataPage = { Skip: this.Skip, Take: this.Take };
    this.loginService.getCandidateSchoolWise(dataPage).subscribe(
      (res: any) => {
        if (res && res.List && res.List.length > 0) {
          this.gridData = res.List;
          this.pageInfo.TotalRecord = res.TotalRecords;
          this.pageInfo.PageSize = this.Take;
        } else {
          this.pageInfo.TotalRecord = 0;
          this.gridData = [];
        }
    this.loaderService.hide();
        this.ref.detectChanges();
        // this.alert.success("All Candidate Fetched", true);
        // this.list = res;
        // this.TotalRecords = res.TotalRecords;
        // this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
        // this.recordStarting = 1;
        // this.recordEnding = this.Take;
      },
      error => {
    this.loaderService.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  getSeason() {
    this.loginService.getSeason().subscribe((res: any) => {
      if (res) {
        if(res.SeasonName) {
          this.activateAdd = true;
        }
      }
    }, err => { });
  }

  // for deleteing the candidtate
  DeleteCandidate(i, Id) {
    this.deleteId = Id;
    $('#deleteCandidate').modal('show');
  }


  deleteCandidateConfirm() {
    this.loginService.candidatedelete(this.deleteId).subscribe(
      (res: any) => {
        this.alert.success('Succesfully Deleted', true);
        $('#deleteCandidate').modal('hide');
        this.refresh();
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }



  // for updating the candidate
  updateCandidate(id, uid) {
    this.data.changeMessage4(this.list.List[id]);
    this.url = `school/candidate-update/${id}/${uid}`;
    this.router.navigate([this.url]);
  }

  refresh() {
    const dataPage = { Skip: this.Skip, Take: this.Take};
    this.loaderService.show();
    this.loginService.getCandidateSchoolWise(dataPage).subscribe(
      (res: any) => {
        // this.list = res;
        // this.TotalRecords = res.TotalRecords;
        // this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
        if (res && res.List) {
          this.gridData = res.List;
          this.pageInfo.TotalRecord = res.TotalRecords;
          this.pageInfo.PageSize = this.Take;
        } else {
          this.gridData = [];
        }
        this.loaderService.hide();
      },
      error => {
        this.loaderService.hide();
        this.alert.error(error, true);
      }
    );
  }
  addNewCandidate() {
    this.router.navigate(['school/add-candidate']);
  }

  RowPerPage(e) {
    this.totalRowPerPage = Number(e.target.value);
    this.Take = this.totalRowPerPage;
    this.Skip = 0;
    this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
    // page number
    if (this.Skip === 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }
    //  this.recordStarting = this.Skip;
    const totalrecord = this.Skip + this.Take;
    if (totalrecord === this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }

  firstPage() {
    this.Skip = 0;
    this.Take = this.totalRowPerPage;
    this.recordStarting = 1;
    if (this.TotalRecords < this.Take) {
      this.recordEnding = this.TotalRecords;
    } else {
      this.recordEnding = this.Take;
    }

    this.refresh();
  }
  nextPage() {

    if (this.recordEnding < this.TotalRecords) {
      this.Skip += this.totalRowPerPage;
      this.Take = this.totalRowPerPage;
      // for page number
      if (this.Skip === 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }
      const totalrecord = this.Skip + this.Take;
      if (totalrecord === this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  priviousPage() {
    if (this.recordStarting > 1) {
      this.Skip -= this.totalRowPerPage;
      this.Take = this.totalRowPerPage;

      // for page number
      // for page number
      if (this.Skip === 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }

      const totalrecord = this.Skip + this.Take;

      if (totalrecord === this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  lastPage() {
    const lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
    this.Skip = lastPage;
    this.Take = this.totalRowPerPage;

    // for the page number
    if (this.Skip === 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }

    const totalrecord = this.Skip + this.Take;

    if (totalrecord === this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }

  RegisterForCompetition() {
    this.router.navigateByUrl('school/Competition-initial');
  }
  AddCandidate() {
    if (this.gridData.length > 0) {
      if (this.gridData && this.gridData[0].RegisterStatus) {
        this.alert.error('Cannot Add Candidate after Registration');
        return;
      }
    }
    this.router.navigateByUrl('school/add-candidate');
  }
  pageInfoChange(event) {    
    this.pageInfo.SortOrder = event.SortBy;
    this.pageInfo.SortOrder = event.SortOrder;
    this.pageInfo.CurrentPage = event.CurrentPage;
    this.pageInfo.PageSize = event.PageSize;
    this.Take = event.PageSize;
    this.Skip = (event.CurrentPage - 1) * event.PageSize;
    this.refresh();
  }
  action(event) {

    if (this.gridData[0].RegisterStatus === true) {
      this.alert.error('Can not Edit/Delete Candidate after Registration');
      return;
    }
    if (event.ColumnName === 'Delete') {
      this.deleteId = event.value.Id;
      $('#deleteCandidate').modal('show');
    } else if (event.ColumnName === 'Edit') {
      this.data.changeMessage4(this.gridData[event.index]);
      this.url = `school/candidate-update/${event.index}/${event.value.Id}`;
      this.router.navigate([this.url]);
    }
  }
}
