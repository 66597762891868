export class candidateResultModel {
    constructor() {
        this.Skip = 0;
        this.Take = 10;
        this.Order = "Asc";
    }
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}

export class CandidateResultGridModel {
    Id: number;
      Name: string;
      Gender: string;
      EmailId: string;
      Score: number;
      State: string
}

export class FilterResultModel {
    constructor() {
        this.List = new Array<CandidateResultGridModel>();
    }
    List: CandidateResultGridModel[];
    TotalRecords: number;
}