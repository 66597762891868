import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-tn-c',
  templateUrl: './tn-c.component.html',
  styleUrls: ['./tn-c.component.css']
})
export class TnCComponent implements OnInit {
  countryId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    ) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.countryId = params['countryId'];
      });
     }

  ngOnInit() {
  }

}
