import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CompetitionInitialComponent } from "src/app/school/competition-initial/competition-initial.component";
import { CompetitionFinalComponent } from "src/app/school/competition-final/competition-final.component";
import { ExaminationSlipComponent } from "src/app/school/examination-slip/examination-slip.component";
import { AddCandidateComponent } from "src/app/school/add-candidate/add-candidate.component";
import { ViewAllCandidateComponent } from "src/app/school/view-all-candidate/view-all-candidate.component";
import { ViewPreviousCandidateComponent } from "src/app/school/view-previous-candidate/view-previous-candidate.component";
import { SchoolComponent } from "src/app/school/school/school.component";
import { SchoolHeaderComponent } from "src/app/school/school-header/school-header.component";
import { PageNotFoundComponent } from "src/app/home/page-not-found/page-not-found.component";
import { GuardSchoolService } from "src/app/school/authguard/guard-school.service";
import { DraftCandidateComponent } from "src/app/school/draft-candidate/draft-candidate.component";
import { RegistrationComponent } from "../home/registration/registration.component";

const routes: Routes = [
  {
    path: "school",
    component: SchoolComponent,
    canActivate: [GuardSchoolService],
    children: [
      { path: "", component: RegistrationComponent },
      { path: "profile", component: RegistrationComponent },
      { path: "Competition-initial", component: CompetitionInitialComponent },
      { path: "Competition-final", component: CompetitionFinalComponent },
      { path: "examination-slip", component: ExaminationSlipComponent },
      { path: "add-candidate", component: AddCandidateComponent },
      { path: "all-candidate", component: ViewAllCandidateComponent },
      { path: "privious-candidate", component: ViewPreviousCandidateComponent },
      { path: "candidate-update/:id/:uid", component: AddCandidateComponent },
      { path: "candidate-draft", component: DraftCandidateComponent },
      { path: "**", component: PageNotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchoolRoutingModule { }
