import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helper } from '../../_model/helper';
declare let $: any;

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit, AfterViewInit {
  header = false;
  constructor(router: Router) {
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        const url = document.location.pathname;
        if (
          url === '/add-exam-centre' ||
          url === '/all-exam-centre' ||
          url.includes('/update-exam-centre') ||
          url === '/faq-admin' ||
          url === '/migrate-exam-centre' ||
          url === '/season-operation' ||
          url === '/season-list' ||
          url === '/get-contactUs-details' ||
          url === '/get-school-list' ||
          url.includes('/school-profiles') ||
          url === '/contact-report' ||
          url === '/school-registration-report' ||
          url === '/candidate-registration-report' ||
          url === '/centre-utilization-report' ||
          url === '/centre-candidate-schedule' ||
          url === '/candidate-mangement' ||
          url.includes('update-candidate') ||
          url === '/partial-migrate' ||
          url === '/upload-past-question' ||
          url.includes('/editHomepage') ||
          url.includes('/location-list') ||
          url.includes('/create-location')
        ) {
          this.header = true;
        } else {
          this.header = false;
        }
      }
    });
  }

  ngOnInit() {}
  ngAfterViewInit() {
    Helper.dynamicHeader();
      $('.disabled').click(function() {
        return false;
      });
  }
  RemoveCred() {
    localStorage.clear();
  }
}
