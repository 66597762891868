export class CandidateRegReportFilterModel {
  Name: string;
  Gender: string;
  State: string;
  SchoolState: string;
  RegistrationStatus: string;
  RegistrationDate: Date;
  SchoolName: string;
  Order: string;
  OrderBy: string;
  Skip: number;
  Take: number;
}

export class CandidateRegReportGridModel {
  Name: string;
  Gender: string;
  State: string;
  SchoolState: string;
  RegistrationStatus: string;
  RegistrationDate: Date;
  SchoolName: string;
}
