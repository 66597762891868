import { Component, OnInit } from '@angular/core';
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../../service/baseLink";
import { Helper } from '../../../_model/helper';
import { LoaderService } from '../../../service/loader.service';
import { debounce } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.css']
})
export class FaqListComponent implements OnInit {
  list: any;
  solution: any;
  question: any;
  id:any;
  whichData: any;
  rForm: FormGroup;
  submittedError: boolean = false;
  Country: any;
  countryId: any = 0;

  constructor(   
     private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getAllCountries();
   this.rForm = this.formBuilder.group({
    Key: ['', Validators.required],
    Value: ['', Validators.required],
      Id:[null]
    })
  }
  getAllCountries(): any {
    this.loginService.getAllCountryList().subscribe(res => {
      if(res){
        this.Country = res;
        this.countryId = res[0].Value;
        this.refresh();

      }
    }, err => {})
  }


sendMyFaq(data) { 
    if (!data.valid) {
      // this.alert.error("fill the form", true)
      this.submittedError = true;
    }
    else {    
      // this.rForm.get("faq").setValue(true);
      this.loaderService.show();
      data.value['CountryId'] = this.countryId;
      this.loginService.sendAnswerFaq(data.value)
        .subscribe((res:any)=>{
          this.alert.success("FAQ added", true);
          this.refresh();
          this.rForm.reset();
          this.submittedError = false;
          
          this.loaderService.hide()          
        }, error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        });     
     
    }
  }


  openModel(item, checkEditDelete) {
    event.stopPropagation();
    this.whichData = item;
    this.question = this.whichData.Key;
    this.solution = this.whichData.Value;
    this.id = this.whichData.Id;
    if (checkEditDelete == 'edit') {
      $('#faqEdit').modal('show');
    }
    else if (checkEditDelete == 'delete') {
      $('#deleteModel').modal('show');
    }
   
  }


updateModel()
{
if(this.question === "" &&  this.solution ==="")
{
  this.alert.error("Please fill the form to update");
}
else if(this.question === "" ||  this.solution ==="")
{
this.alert.error("Please fill all the field in form to update");
}
else{
this.id;
this.question;
this.solution;
var data = {Id:this.id,Key:this.question,Value:this.solution, CountryId:this.countryId};
  
   this.loginService.sendAnswerFaq(data)
        .subscribe((res:any)=>{
          this.refresh();
          $('#faqEdit').modal('hide');
          this.alert.success("Changes Saved",true);
        }, error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        });
}
}

  deleteFaqNow() {
  
    this.loginService.deleteFaq(this.id)
      .subscribe((res:any)=>{       
         $('#deleteModel').modal('hide');
        this.refresh();
      }, error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      });
  }
  countrySelected(event){
    this.countryId = event.target.value;
    this.refresh();
  }

  refresh() {
    this.loaderService.show();
    this.loginService.getFaqAdmin(this.countryId)
      .subscribe((res:any)=>{
        this.list = res;
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      });
  }
}
