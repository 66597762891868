import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';



import { Helper } from '../../_model/helper';
declare let $: any;

@Component({
  selector: 'app-super-admin-header',
  templateUrl: './super-admin-header.component.html',
  styleUrls: ['./super-admin-header.component.css']
})
export class SuperAdminHeaderComponent implements AfterViewInit {
  header = false;

  ngAfterViewInit() {
    Helper.dynamicHeader();
    // $("#disabled").click(function() {
    //   return true;
    // });
  }

  constructor(router: Router) {
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        const url = document.location.pathname;
        if (
          url === '/setting' ||
          url === '/authority-list' ||
          url === '/create-authority' ||
          url.includes('/update-authority') ||
          // for the exam centre operation ⬇
          url === '/add-exam-centre' ||
          url === '/centre-list' ||
          url === '/migrate-exam-centre' ||
          url === '/partial-migrate' ||
          url.includes('/updates-exam-centres') ||
          // for the season operation ⬇
          url === '/add-season' ||
          url === '/season-list' ||
          // for the candidate and school operation ⬇
          url === '/school-list' ||
          url === '/candidate-list' ||
          url.includes('/update-school-profile') ||
          url.includes('/candidates-update') ||
          // for the report ⬇
          url === '/contact-reports' ||
          url === '/school-registration-reports' ||
          url === ('/candidate-registration-reports') ||
          url === ('/central-utilization-reports') ||
          url === ('/center-candidates-schedule') ||
          // for the contact us and faq ⬇
          url === '/faq-admin' ||
          url === '/get-contact-us' ||
          url === ('/upload-past-question')
        ) {
          this.header = true;
        } else {
          this.header = false;
        }
      }
    });
  }

  RemoveCred() {
    localStorage.clear();
  }
}
