import { Component, OnInit } from '@angular/core';
import { ApiServerService } from "src/app/service/api-server.service";
import { DataService } from "src/app/service/data.service";
import { FormBuilder } from "@angular/forms";
import { AlertService } from "src/app/_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Http, Headers } from "@angular/http";
import { Helper } from '../../../_model/helper';
@Component({
  selector: 'app-guidelines',
  templateUrl: './guidelines.component.html',
  styleUrls: ['./guidelines.component.css']
})
export class GuidelinesComponent implements OnInit {
guidelineList:any;
  countryId: any;
  constructor(
        private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.countryId = params['countryId'];
    })
   }

  ngOnInit() {
    this.getGuidelines();
  }
  getGuidelines() {
    if(this.countryId){
      this.loginService.getGuidelines(this.countryId).subscribe(
        (res:any)=>{
          this.guidelineList = res;
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
}
