import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../service/api-server.service';
import { DataService } from '../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as myapi from '../../service/baseLink';
import { Helper } from '../../_model/helper';
import { FilterChangeModel } from '../../_model/filter-change-model';
import {
  FilterFieldModel,
  FieldType
} from '../../_model/filter-field-model';
import {
  SchoolFilterModel,
  SchoolFilterResultModel
} from '../../_model/school-models';
import {
  GridColumnType,
  PropertyMetaModel,
  PaginationModel
} from 'src/app/_model/pagination-model';

import { LoaderService } from '../../service/loader.service';
@Component({
  selector: 'app-reg-schools',
  templateUrl: './reg-schools.component.html',
  styleUrls: ['./reg-schools.component.css']
})

export class RegSchoolsComponent implements OnInit {
  countryId: any;
  filter: SchoolFilterModel;
  schools: SchoolFilterResultModel = new SchoolFilterResultModel();
  schoolsKe:  SchoolFilterResultModel = new SchoolFilterResultModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public filterData: FilterChangeModel[] = new Array<FilterChangeModel>();
  public displayColumns: PropertyMetaModel[];
  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0 
  };

  constructor(
    private schoolService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.countryId = params['countryId'];
    });
    this.Fields = [
      {
        FieldName: 'Name',
        Label: 'School Name',
        Placeholder: 'Please Enter School Name',
        FieldType: FieldType.Text
      },

    ];
    this.displayColumns = [
      {
        PropertyName: 'Name',
        ColumnName: 'Name',
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: 'Type',
        ColumnName: 'School Type',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Category',
        ColumnName: 'Category',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Address',
        ColumnName: 'Address',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Phone',
        ColumnName: 'School Phone',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'State',
        ColumnName: 'State',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'Country',
        ColumnName: 'Country',
        PropertyType: GridColumnType.Text
      }
    ];
   }

  ngOnInit() {
    this.filter = new SchoolFilterModel();
    this.loadSchools();
    //this.loadSchoolsKe();
  }

  filterChange(event: any) {
    this.filter = new SchoolFilterModel();
    event.forEach(element => {
      this.filter[element.FieldName] = element.Value || null;
    });
    this.loadSchools();
    //this.loadSchoolsKe();
  }

  loadSchools(): any {
    this.loaderService.show();
    this.schools = new SchoolFilterResultModel();
    this.schoolService.getCompletedRegSchoolsNg(this.filter).subscribe(
      (res: any) => {
        this.schools.List = res.List;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.filter.Take;
        this.loaderService.hide();
      },
      error => {
        this.loaderService.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  loadSchoolsKe(): any {
    this.loaderService.show();
    this.schoolsKe = new SchoolFilterResultModel();
    this.schoolService.getCompletedRegSchoolsKe(this.filter).subscribe(
      (res: any) => {
        this.schoolsKe.List = res.List;
        this.pageInfo.TotalRecord = res.TotalRecords;
        this.pageInfo.PageSize = this.filter.Take;
        this.loaderService.hide();
      },
      error => {
        this.loaderService.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  pageInfoChange(event) {
    this.filter.OrderBy = event.SortBy;
    this.filter.Order = event.SortOrder;
    this.filter.Skip = (event.CurrentPage - 1) * event.PageSize;
    this.filter.Take = event.PageSize;
    //this.loadSchoolsKe();
    this.loadSchools();
  }

}
