import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SuperAdminRoutingModule } from "./super-admin-routing.module";
import { SuperAdminComponent } from "./super-admin/super-admin.component";
import { SuperAdminHeaderComponent } from "src/app/superAdmin/super-admin-header/super-admin-header.component";
import { SettingComponent } from "src/app/superAdmin/setting/setting.component";
import { AuthorityListComponent } from "src/app/superAdmin/authority-list/authority-list.component";
import { CreateAuthorityComponent } from "src/app/superAdmin/create-authority/create-authority.component";
import { AddCentreComponent } from "src/app/AdmAccess/examcentre/add-centre/add-centre.component";
import { CentreListComponent } from "src/app/AdmAccess/examcentre/centre-list/centre-list.component";
import { FullMigrateComponent } from "src/app/AdmAccess/examcentre/full-migrate/full-migrate.component";
import { PartialMigrateComponent } from "src/app/AdmAccess/examcentre/partial-migrate/partial-migrate.component";
import { AddSeasonComponent } from "src/app/AdmAccess/season/add-season/add-season.component";
import { SeasonListComponent } from "src/app/AdmAccess/season/season-list/season-list.component";
import { SchoolListComponent } from "src/app/AdmAccess/schoolAndCandidate/school-list/school-list.component";
import { CandidateListComponent } from "src/app/AdmAccess/schoolAndCandidate/candidate-list/candidate-list.component";
import { AddCandidateComponent } from "src/app/school/add-candidate/add-candidate.component";
import { ContactReportComponent } from "src/app/AdmAccess/report/contact-report/contact-report.component";
import { SchoolRegReportComponent } from "src/app/AdmAccess/report/school-reg-report/school-reg-report.component";
import { CandidateRegReportComponent } from "src/app/AdmAccess/report/candidate-reg-report/candidate-reg-report.component";
import { CentreUtilizationReportComponent } from "src/app/AdmAccess/report/centre-utilization-report/centre-utilization-report.component";
import { CentreCandidateScheduleComponent } from "src/app/AdmAccess/report/centre-candidate-schedule/centre-candidate-schedule.component";
import { FaqListComponent } from "src/app/AdmAccess/faqContact/faq-list/faq-list.component";
import { ContactUsComponent } from "src/app/AdmAccess/faqContact/contact-us/contact-us.component";
import { UploadQuestionComponent } from "src/app/AdmAccess/faqContact/upload-question/upload-question.component";
import { SchoolModule } from "src/app/school/school.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { BrowserModule } from "@angular/platform-browser";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgDatepickerModule } from "ng2-datepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SharedModule } from "../_shared/sharedmodule";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { SeasonManagementComponent } from "../AdmAccess/season/season-management/season-management.component";


@NgModule({
  imports: [
    CommonModule,
    SuperAdminRoutingModule,
    SchoolModule,
    BrowserModule,
    HttpModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgDatepickerModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    SuperAdminComponent,
    SuperAdminHeaderComponent,
    SettingComponent,
    AuthorityListComponent,
    CreateAuthorityComponent,
    AddCentreComponent,
    CentreListComponent,
    FullMigrateComponent,
    PartialMigrateComponent,
    AddSeasonComponent,
    SeasonManagementComponent,
    SeasonListComponent,
    SchoolListComponent,
    CandidateListComponent,
    ContactReportComponent,
    SchoolRegReportComponent,
    CandidateRegReportComponent,
    CentreUtilizationReportComponent,
    CentreCandidateScheduleComponent,
    FaqListComponent,
    ContactUsComponent,
    UploadQuestionComponent
  ],
})
export class SuperAdminModule {}
