import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ApiServerService } from 'src/app/service/api-server.service';
import { AlertService } from 'src/app/_service/alert.service';
import { FilterChangeModel } from 'src/app/_model/filter-change-model';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';
import { Helper } from '../../_model/helper';

@Component({
  selector: 'app-region-drop-down',
  templateUrl: './region-drop-down.component.html',
  styleUrls: ['./region-drop-down.component.css']
})
export class RegionDropDownComponent implements OnInit {
  public regions: any;
  public val = '';
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<FilterChangeModel> = new EventEmitter<FilterChangeModel>();
  constructor(
    private loginService: ApiServerService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.LoadRegions();
  }
  LoadRegions(): any {
    this.loginService.getAllRegions().subscribe(
      (res: any) => {
        this.regions = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  changeRegion(event, type) {
    const value = {
      FieldName: this.field.FieldName,
      Value: event.target.value,
      EventType: type
    };
    this.Changed.emit(value);
  }

  Reset() {
    this.val = '';
    this.field.Filter = null;
    //   this.LoadRegions();
  }
}
