import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as myapi from '../../../service/baseLink';
import { ExcelService } from '../../../_model/exceldownload.helper';
import { Helper } from '../../../_model/helper';
import { FieldType, FilterFieldModel } from 'src/app/_model/filter-field-model';
import { PaginationModel, PropertyMetaModel } from 'src/app/_model/pagination-model';
import { LoaderService } from '../../../service/loader.service';
import { pdfPotrait } from '../../../_model/pdfDownloadHelper';
@Component({
  selector: 'app-centre-utilization-report',
  templateUrl: './centre-utilization-report.component.html',
  styleUrls: ['./centre-utilization-report.component.css']
})
export class CentreUtilizationReportComponent implements OnInit {
  public displayColumns: PropertyMetaModel[];
  public filter: any = {};
  public pageInfo = new PaginationModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public gridData: any[] = [];
  public pdfColumns = [
    { title: "NAME", dataKey: "Name" },
    { title: "REGION", dataKey: "Region" },
    { title: "COUNTRY", dataKey: "Country" },
    { title: "STATE", dataKey: "State" },
    { title: "CAPACITY", dataKey: "Capacity" },
    { title: "REGISTERED_CANDIDATES", dataKey: "Registered_Candidates" },
    { title: "UTILIZATION", dataKey: "Utilization" }
  ]

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService,
    private loaderService: LoaderService) {
      this.Fields = [
        {
          FieldName: 'Name',
          Label: 'Name',
          Placeholder: 'Please enter center name',
          FieldType: FieldType.Text
        },
        {
          FieldName: 'RegionId',
          Label: 'Region',
          Placeholder: 'Please select a region',
          FieldType: FieldType.RegionDropdown
        },
        {
          FieldName: 'CountryId',
          Label: 'Country',
          Placeholder: 'Please select a region',
          FieldType: FieldType.CountryDropdown
        },
        {
          FieldName: 'StateId',
          Label: 'State',
          Placeholder: 'Please select a region',
          FieldType: FieldType.StateDropdown
        }
        // {
        //   FieldName: 'Email',
        //   Label: 'Email',
        //   Placeholder: 'Please enter email',
        //   FieldType: FieldType.Text
        // }
      ];
    }

    ngOnInit() {
      this.getAllCadidateReport();
    }

    getAllCadidateReport(): any {
      this.gridData = [];
      const page: any = {};
      page.Skip = (this.pageInfo.CurrentPage - 1) * this.pageInfo.PageSize;
      page.Take = this.pageInfo.PageSize;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loaderService.show();
      this.loginService.centralUtilizationReport(page, this.filter).subscribe(
        (res: any) => {
          this.gridData = res.List;
          this.pageInfo.TotalRecord = res.TotalRecords;
          this.pageInfo.PageSize = this.pageInfo.PageSize;
          this.loaderService.hide();
        },
        error => {
          this.loaderService.hide();
         }
      );
    }

    action(event: any) {
    }

    pageInfoChange(event: PaginationModel) {
      this.pageInfo.CurrentPage = event.CurrentPage;
      this.pageInfo.PageSize = event.PageSize;
      this.pageInfo.SortOrder = event.SortOrder;
      this.pageInfo.SortOrder = event.SortOrder;
      this.getAllCadidateReport();
    }

    filterChange(event: any) {
      if (event && event.length > 0) {
        event.forEach(element => {
          this.filter[element.FieldName] = element.Value;
        });
      } else {
        this.filter = {};
      }
      this.pageInfo.CurrentPage = 1;
      this.getAllCadidateReport();
    }

    download() {
      const page: any = {};
      page.Skip = 0;
      page.Take = 1000;
      this.filter.Take = 1000;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loginService.centralUtilizationReport(page, this.filter).subscribe(
        (res: any) => {
          this.downloadFile(res.List);
        },
        error => { }
      );
    }

    downloadFile(data: any) {
      this.excelService.exportAsExcelFile(data, 'Exam Center Utilization Report:');
    }

    downloadPDF() {
      const page: any = {};
      page.Skip = 0;
      page.Take = 1000000;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loginService.centralUtilizationReport(page, this.filter).subscribe(
        (res: any) => {
          if(res){
            pdfPotrait.exportPdfportrait(res, "CenterUtilizationReport:", this.pdfColumns, 'Center Utilization Report', 'p');
          }
        },
        error => { }
      );
    }

  }
