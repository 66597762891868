import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ExcelService } from '../../../_model/exceldownload.helper';
import { Helper } from '../../../_model/helper';
import { PropertyMetaModel, GridColumnType, PaginationModel } from '../../../_model/pagination-model';
import { SchoolRegGridModel, SchoolRegReportFilterModel } from './schoolregmodel';
import { FilterFieldModel, FieldType } from 'src/app/_model/filter-field-model';
import { LoaderService } from '../../../service/loader.service';
import { pdfPotrait } from '../../../_model/pdfDownloadHelper';

@Component({
  selector: 'app-school-reg-report',
  templateUrl: './school-reg-report.component.html',
  styleUrls: ['./school-reg-report.component.css']
})
export class SchoolRegReportComponent implements OnInit {
  public displayColumns: PropertyMetaModel[];
  public filter: SchoolRegReportFilterModel = new SchoolRegReportFilterModel();
  public pageInfo = new PaginationModel();
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  public gridData: SchoolRegGridModel[] = [];
  public pdfColumns = [
    { title: "Name", dataKey: "Name" },
    { title: "Category", dataKey: "Category" },
    { title: "Email", dataKey: "Email" },
    { title: "Region", dataKey: "Region" },
    { title: "Country", dataKey: "Country" },
    { title: "State", dataKey: "State" },
    { title: "RegistrationDate", dataKey: "RegistrationDate" }
  ]

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService,
    private loaderService: LoaderService) {
      this.displayColumns = [
        {
          PropertyName: 'Name',
          ColumnName: 'School Name',
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: 'Category',
          ColumnName: 'Category',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'Email',
          ColumnName: 'Email',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'Region',
          ColumnName: 'Region',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'Country',
          ColumnName: 'Country',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'State',
          ColumnName: 'State',
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: 'RegistrationDate',
          ColumnName: 'RegistrationDate',
          PropertyType: GridColumnType.Text
        }
      ];
      this.Fields = [
        {
          FieldName: 'Name',
          Label: 'School Name',
          Placeholder: 'Please enter school name',
          FieldType: FieldType.Text
        },
        {
          FieldName: 'Email',
          Label: 'Email',
          Placeholder: 'Please enter email',
          FieldType: FieldType.Text
        },
        {
          FieldName: 'Category',
          Label: 'Category',
          Placeholder: 'Please enter school name',
          FieldType: FieldType.SchoolCategoryDropdown
        },
        {
          FieldName: 'Region',
          Label: 'Region',
          Placeholder: 'Please enter',
          FieldType: FieldType.RegionDropdown
        },
        {
          FieldName: 'Country',
          Label: 'Country',
          Placeholder: 'Please Select',
          FieldType: FieldType.CountryDropdown
        },
        {
          FieldName: 'States',
          Label: 'State',
          Placeholder: 'Please Select',
          FieldType: FieldType.StateDropdown
        },
        {
          FieldName: 'RegistrationDateFrom',
          Label: 'Registration Date From',
          Placeholder: 'dd/mm/yyyy',
          FieldType: FieldType.RegistrationDateFrom
        },
        {
          FieldName: 'RegistrationDateTo',
          Label: 'Registration Date To',
          Placeholder: 'dd/mm/yyyy',
          FieldType: FieldType.RegistrationDateTo
        }
      ];
    }

    ngOnInit() {
      this.getAllCadidateReport();
    }

    getAllCadidateReport(): any {
      this.gridData = [];
      const page: any = {};
      this.filter.Skip = (this.pageInfo.CurrentPage - 1) * this.pageInfo.PageSize;
      this.filter.Take = this.pageInfo.PageSize;
      this.filter.OrderBy = this.pageInfo.SortBy;
      this.filter.Order = this.pageInfo.SortOrder;
      this.loaderService.show();
      this.loginService.schoolReport(page, this.filter).subscribe(
        (res: any) => {
          this.gridData = res.List;
          this.pageInfo.TotalRecord = res.TotalRecords;
          this.loaderService.hide();
        },
        error => {
          this.loaderService.hide();
         }
      );
    }

    action(event: any) {

    }

    pageInfoChange(event: PaginationModel) {      
      this.pageInfo.CurrentPage = event.CurrentPage;
      this.pageInfo.PageSize = event.PageSize;
      this.pageInfo.SortOrder = event.SortOrder;
      this.pageInfo.SortOrder = event.SortOrder;
      this.getAllCadidateReport();
    }

    filterChange(event: any) {
      if (event && event.length > 0) {
        event.forEach(element => {
          this.filter[element.FieldName] = element.Value;
        });
      } else {
        this.filter = new SchoolRegReportFilterModel();
      }
      this.pageInfo.CurrentPage = 1;
      this.getAllCadidateReport();
    }

    download() {
      const page: any = {};
      page.Skip = 0;
      page.Take = 100000;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loginService.schoolReport(page, {}).subscribe(
        (res: any) => {
          this.downloadFile(res.List);
        },
        error => { }
      );
    }

    downloadFile(data: any) {
      this.excelService.exportAsExcelFile(data, 'School Registration Report:');
    }
    downloadPDF() {
      const page: any = {};
      page.Skip = 0;
      page.Take = 1000000;
      page.OrderBy = this.pageInfo.SortBy;
      page.Order = this.pageInfo.SortOrder;
      this.loginService.schoolReport(page, {}).subscribe(
        (res: any) => {
          if(res){
            pdfPotrait.exportPdfportrait(res, "SchoolRegistrationReport:", this.pdfColumns, 'School Registration Report', 'p');
          }
        },
        error => { }
      );
    }

}
