import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router } from "@angular/router";
import { Helper } from "../../_model/helper";
import { LoaderService } from "../../service/loader.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
  shPasscheckboxselected: string = 'password';
loader:boolean;
  rForm: FormGroup;
  submittedError:boolean=false;
  patternMail: string = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  patternPhone: string = "[0-9]*";
  patternName: string = "[a-zA-Z ]*";

  cookies: boolean = false;
  cookiesUserName: string;
  cookiesPassword: string;
  ngOnInit() {
    this.rForm = this.formBuilder.group({
      UserName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.patternMail)
        ])
      ],
      Password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8)
        ])
      ]
    });
    if (sessionStorage.length > 0) {
      this.cookiesUserName = sessionStorage.getItem("UserName");
      this.cookiesPassword = sessionStorage.getItem("Password");
      this.rForm.get("UserName").setValue(this.cookiesUserName);
      this.rForm.get("Password").setValue(this.cookiesPassword);
    }
  }

  login(form) {
    this.loaderService.show();
    if (!form.valid) {
      this.submittedError=true;
    this.loaderService.hide();
    } else {
      if (this.cookies == true) {
        this.cookiesUserName = JSON.parse(JSON.stringify(form.value)).UserName;
        this.cookiesPassword = JSON.parse(JSON.stringify(form.value)).Password;
        sessionStorage.setItem("UserName", this.cookiesUserName);
        sessionStorage.setItem("Password", this.cookiesPassword);
      }
      this.loginService.login(form.value).subscribe(
        (res:any)=>{
        //  this.loader = false;
    this.loaderService.hide();

          this.alert.success("login successfull", true);
          localStorage.setItem("auth", JSON.stringify(res)); //store the information of user in local
          this.getRole();
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
          // this.loader = false;
    this.loaderService.hide();

        }
      );
    }
  }

  getRole(){
    var auth = JSON.parse(localStorage.getItem("auth"));
    this.loginService.getUserInfo().subscribe((response:any)=>{
      var x=response;      
      // var response = JSON.parse(res);
      localStorage.setItem("UserInfo", JSON.stringify(response));
      if (x.Role == "School") {
            this.router.navigate(["school/profile"]);
          } else if (x.Role == "Examiner") {
            this.router.navigate(["examiner"]);
          } else if (x.Role == "Admin") {
            this.router.navigate(["admin/centre-list"]);
          } else if (x.Role == "SuperAdmin") {
            this.router.navigate(["super-admin/setting"]);
          }
    }, err =>{});
  }

  remember(e) {
    if (e.target.checked) {
      this.cookies = true;
    } else {
      this.cookies = false;
    }
  }
  showPassword(event) {
    event.target.checked === true ? this.shPasscheckboxselected = 'text' : this.shPasscheckboxselected = 'password';
  }



}
