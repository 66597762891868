export class ContactUsFilterModel {
    constructor(){
        this.Skip = 0;
        this.Take = 10;
        this.Order = "Asc";
    }
    Name: string;
    Concern: string;
    Email: string;
    IsResolved: true;
    PhoneNumber: number;
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}

export class ContactUsGridModel {
    Id: number;
      Name: string;
      Email: string;
      PhoneNumber: number;
      Concern: string;
      Message: string;
      Answer: string;
      IssueSolved: false;
}

export class ContactUsGridResultModel {
    constructor() {
        this.List = new Array<ContactUsGridModel>();
    }
    List: ContactUsGridModel[];
    TotalRecords: number;
}
export class concernReplyModel {
    Id : number;
    Name: string;
    Message: string;
    Email: string;
    Answer : String;
}