import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../../service/api-server.service";
import { DataService } from "../../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as myapi from "../../../service/baseLink";
import { Helper } from "../../../_model/helper";
declare var $ : any;

@Component({
  selector: "app-upload-question",
  templateUrl: "./upload-question.component.html",
  styleUrls: ["./upload-question.component.css"]
})
export class UploadQuestionComponent implements OnInit {
  year: any;
  class: any;
  fileUploadQuestion: any; //question
  fileUploadAnswer: any; //answer
  rForm: FormGroup;
  submittedError: boolean;
  totalYearsCount = [];
  file1: string = "Upload question pdf";
  file2: string = "Upload answer pdf";
  Country: any;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getAllCountries();
    this.storeYears("1980", new Date().getFullYear());
    this.getClass();
    this.rForm = this.formBuilder.group({
      countryId:[null, Validators.required],
      Class: [null, Validators.required],
      Year: [null, Validators.required],
      File: ["", Validators.required],
      answer: ["", Validators.required]
    });
  }
  getAllCountries(): any {
    this.loginService.getAllCountryList().subscribe(res => {
      if(res){
        this.Country = res;
      }
    }, err => {})
  }

  getYear() {
    // this.loginService.getYear().subscribe(
    //   (res:any)=>{
    //     this.year = res;
    //   },
    //   error => {
    //     this.alert.error(Helper.getMessageFromError(error), true);
    //   }
    // );
  }

  questionUpload(event) {
    this.fileUploadQuestion = event;
    this.rForm.get("File").setValue(event.target.files[0]);
    if (event.target.files[0] == undefined) {
      this.file1 = "Upload question pdf";
    } else {
      this.file1 = event.target.files[0].name;
    }
  }
  answerUpload(event) {
    this.fileUploadAnswer = event;
    this.rForm.get("answer").setValue(event.target.files[0]);
    if (event.target.files[0] == undefined) {
      this.file2 = "Upload answer pdf";
    } else {
      this.file2 = event.target.files[0].name;
    }
  }

  getClass() {
    //getting the class list from the server side
    this.loginService.getclass().subscribe(
      (res:any)=>{
        this.class = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  SendData(data) {
    if (!data.valid) {
      // this.alert.error("fill the form", true);
      this.submittedError = true;
    } else {
      // pastYearQuestionPaper
      var a = JSON.stringify(data.value);
      var b = JSON.parse(a);

      var classData = b.Class;
      var yearData = b.Year;
      let countryId = b.countryId;
      var url = `${yearData}&ClassID=${classData}&CountryId=${countryId}`;

      var form_data = new FormData();
      form_data.append("File", this.fileUploadQuestion.target.files[0]);
      form_data.append("answer", this.fileUploadAnswer.target.files[0]);

      this.loginService.pastYearQuestionPaper(form_data, url).subscribe(
        (res:any)=>{
          this.alert.success("Past Question/Answer Uploaded successful", true);
          $("#que").val(null);
          $("#ans").val(null);
          this.file1 = "Upload question pdf";
          this.file2 = "Upload answer pdf";


          this.rForm.reset();
          const a : any = document.getElementById("que");
          a.value = "";
          const b : any =document.getElementById("ans");
          b.value = "";
          this.rForm.get("File").setValue("");
          this.rForm.get("answer").setValue("");
          this.submittedError = false;
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }

  storeYears(startYear, currentYear) {
    for (var i = startYear; i <= currentYear; i++) {
      this.totalYearsCount.push(i);
    }
  }
}
