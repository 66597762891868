import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FilterFieldModel } from 'src/app/_model/filter-field-model';
import { FilterChangeModel } from 'src/app/_model/filter-change-model';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent implements OnInit {
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<FilterChangeModel> = new EventEmitter<FilterChangeModel>();
  public val: string;

  constructor() { }

  ngOnInit() {
  }

  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !/[\d\s]/.test(input);
  }
  textChanged(event, type) {
    const value = { FieldName: this.field.FieldName, Value: event.target.value, EventType: type };
    this.Changed.emit(value);
  }

  Reset() {
    this.val = '';
  }
}
