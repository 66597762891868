import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { FilterFieldModel } from '../../_model/filter-field-model';
import { FilterChangeModel } from '../../_model/filter-change-model';

@Component({
  selector: 'app-registration-date-from',
  templateUrl: './registration-date-from.component.html',
  styleUrls: ['./registration-date-from.component.css']
})
export class RegistrationDateFromComponent implements OnInit {
  @Input() field: FilterFieldModel = new FilterFieldModel();
  @Output() Changed: EventEmitter<FilterChangeModel> = new EventEmitter<FilterChangeModel>();
  public val: string;

  constructor() { }

  ngOnInit() {
  }

  textChanged(event, type) {
    const value = { FieldName: this.field.FieldName, Value: event, EventType: type };
    this.Changed.emit(value);
  }

  Reset() {
    this.val = '';
  }
}