import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../service/api-server.service';
import { DataService } from '../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';
import { Router } from '@angular/router';
import { changeStatusExamCentre } from '../../service/baseLink';
import * as myapi from './../../../app/service/baseLink';
import { PasswordValidation } from '../../home/registration/PasswordValidation';
import { Helper } from '../../_model/helper';
declare var $: any;
@Component({
  selector: 'app-authority-list',
  templateUrl: './authority-list.component.html',
  styleUrls: ['./authority-list.component.css']
})
export class AuthorityListComponent implements OnInit {
  task = '';
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router
  ) { }
  list: any;
  uid: any;
  // For pagination purpose
  Skip = 0;
  Take = 10;
  TotalRecords: number;
  TotalPages: number;
  totalRowPerPage = 10;

  recordStarting: number;
  recordEnding: number;
  submittedError = false;
  selectedUserEmail = '';
  rForm: FormGroup;

  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  ngOnInit() {
    this.AuthorityList();
    this.rForm = this.formBuilder.group({
      Email: [
        '',
        Validators.compose([Validators.required, Validators.pattern(myapi.emailPattern)])
      ],

      NewPassword: [
        '',
        Validators.compose([Validators.required, , Validators.minLength(6)])
      ],
        ConfirmPassword: ['', Validators.compose([
        Validators.required])]
    }, {
      validator: PasswordValidation.MatchPassword // your validation method
    });
  }

  AuthorityList() {
    this.list = [];
    this.Skip = 0;
    const dataPage = { Skip: this.Skip, Take: this.Take };
    this.loginService.getAllAuthority(dataPage).subscribe(
      (res: any) => {
        if (res && res.List.length > 0) {
          this.list = res.List;
          this.TotalRecords = res.TotalRecords;
          this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
          this.recordStarting = 1;
          if (this.TotalRecords < this.Take) {
            this.recordEnding = this.TotalRecords;
          } else {
            this.recordEnding = this.Take;
          }
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  refresh() {
    this.list = [];
    const dataPage = { Skip: this.Skip, Take: this.Take };
    this.loginService.getAllAuthority(dataPage).subscribe(
      (res: any) => {
        this.list = res.List;
        this.TotalRecords = res.TotalRecords;
        this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  DeleteAuthority(item) {
    this.selectedUserEmail = item.Email;
    this.task = 'Delete';
    $('#activateDeactivateModel').modal('show');
  }
  changePasswordModelPopup(item) {
    this.selectedUserEmail = item.Email;
    this.rForm.get('Email').setValue(this.selectedUserEmail);
    $('#changePasswordModel').modal('show');
  }
  SendData(data) {
    if (!data.valid) {
      // this.alert.error("fill the form correctly", true);
      this.submittedError = true;
    }

    if (data.valid) {
      this.loginService.updatePassword(data.value).subscribe(
        (res: any) => {

          this.alert.success('SuccessFully Updated Password', true);
          $('#changePasswordModel').modal('hide');
          this.rForm.reset();
          // this.router.navigate(['super-admin/authority-list']);
         // this.rForm.reset();
        },
        error => {
          this.alert.error('Password Not Updated');
        }
      );
    }
  }

  deleteConfirm() {
    const data = {
      Email: this.selectedUserEmail,
    };
    this.loginService.deleteAuthority(data).subscribe(
      (res: any) => {
        this.alert.success('Deleted', true);
        this.AuthorityList();
        $('#activateDeactivateModel').modal('hide');
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  updateAuthority(id, uid) {
    // update-authority/:id/:uid
    this.data.changeMessage5(this.list[id]);
    const url = `super-admin/update-authority/${id}/${uid}`;
    this.router.navigate([url]);
  }
  changeStatusOfUserPopup(item) {
    this.task = item.IsActive ? 'DeActivate' : 'Activate';
    this.selectedUserEmail = item.Email;
    $('#activateDeactivateModel').modal('show');
  }

  changeStatus() {
    const data = {
      Email: this.selectedUserEmail,
    };
    this.loginService.statusChange(data).subscribe(
      (res: any) => {
        this.alert.success('Status Changed', true);
        this.AuthorityList();
        $('#activateDeactivateModel').modal('hide');
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  RowPerPage(e) {
    this.totalRowPerPage = Number(e.target.value);
    this.Take = this.totalRowPerPage;
    this.Skip = 0;
    this.TotalPages = Math.ceil(this.TotalRecords / this.Take);
    // page number
    if (this.Skip === 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }
    const totalrecord = this.Skip + this.Take;
    if (totalrecord === this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }

  firstPage() {
    this.Skip = 0;
    this.Take = this.totalRowPerPage;
    this.recordStarting = 1;
    if (this.TotalRecords < this.Take) {
      this.recordEnding = this.TotalRecords;
    } else {
      this.recordEnding = this.Take;
    }

    this.refresh();
  }
  nextPage() {

    if (this.recordEnding < this.TotalRecords) {
      this.Skip += this.totalRowPerPage;
      this.Take = this.totalRowPerPage;

      // for page number
      if (this.Skip === 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }
      const totalrecord = this.Skip + this.Take;

      if (totalrecord === this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  priviousPage() {
    if (this.recordStarting > 1) {
      this.Skip -= this.totalRowPerPage;
      this.Take = this.totalRowPerPage;

      // for page number
      // for page number
      if (this.Skip === 0) {
        this.recordStarting = 1;
      } else {
        this.recordStarting = this.Skip + 1;
      }

      const totalrecord = this.Skip + this.Take;

      if (totalrecord === this.TotalRecords) {
        this.recordEnding = totalrecord;
      } else if (totalrecord > this.TotalRecords) {
        this.recordEnding = this.TotalRecords;
      } else if (totalrecord < this.TotalRecords) {
        this.recordEnding = totalrecord;
      }

      this.refresh();
    }
  }
  lastPage() {
    const lastPage = (this.TotalPages - 1) * this.totalRowPerPage;
    this.Skip = lastPage;
    this.Take = this.totalRowPerPage;

    // for the page number
    if (this.Skip === 0) {
      this.recordStarting = 1;
    } else {
      this.recordStarting = this.Skip + 1;
    }

    const totalrecord = this.Skip + this.Take;

    if (totalrecord === this.TotalRecords) {
      this.recordEnding = totalrecord;
    } else if (totalrecord > this.TotalRecords) {
      this.recordEnding = this.TotalRecords;
    } else if (totalrecord < this.TotalRecords) {
      this.recordEnding = totalrecord;
    }
    this.refresh();
  }
}
