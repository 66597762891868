import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Helper } from '../../../_model/helper';
import {
  GridColumnType,
  PropertyMetaModel,
  PaginationModel
} from '../../../_model/pagination-model';
import { SeasonFilterModel, SeasonGridModel } from './seasongridmodel';
import { FilterFieldModel, FieldType } from 'src/app/_model/filter-field-model';
import { LoaderService } from '../../../service/loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-season-list',
  templateUrl: './season-list.component.html',
  styleUrls: ['./season-list.component.css'],
  providers: [DatePipe]
})
export class SeasonListComponent implements OnInit {
  public displayColumns: PropertyMetaModel[];
  public filter: SeasonFilterModel = new SeasonFilterModel();
  public pageInfo = new PaginationModel();
  public Name: string;
  public Fields: FilterFieldModel[] = new Array<FilterFieldModel>();
  rForm: FormGroup;

  gridData: SeasonGridModel[] = [];
  DeactivateToggle: any;
  completeSeasonId: any;
  toggleActiveId: any;
  submittedError: boolean;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private loaderservice: LoaderService
  ) {
    this.displayColumns = [
      {
        PropertyName: 'SeasonName',
        ColumnName: 'Season Name',
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: 'SeasonExamdate',
        ColumnName: 'Season Exam date',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'SeasonExamTime',
        ColumnName: 'Season Exam Time',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'MinNoOfGirlsInMix',
        ColumnName: 'Min No Of Girls In Mix',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'MaxSelectedCandidate',
        ColumnName: 'Max Selected Candidate',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'IsActive',
        ColumnName: 'Make Active',
        PropertyType: GridColumnType.Switch,
        DisplayText: 'IsActiveDisable'
      },
      {
        PropertyName: 'IsCompleted',
        ColumnName: 'Season Complete',
        PropertyType: GridColumnType.Switch,
        DisplayText: 'IsActiveDisable'
      },
    // {
    //   PropertyName: 'Id',
    //  ColumnName: 'Edit',
    //  PropertyType: GridColumnType.Icon,
    //  DisplayText: 'Edit',
    //  ClassName: 'mypointer'
    // },



    ];
    this.Fields = [
      { FieldName: 'Name', Label: 'Name', Placeholder: 'Please enter season name', FieldType: FieldType.Text }
    ];
  }

  ngOnInit() {
    // this.getClass();
    this.getallSeasons();
    this.rForm = this.formBuilder.group({
      SeasonName: ["", Validators.required],
      Id: [""]
    });
  }


  getallSeasons() {
    this.loaderservice.show();
    this.gridData = [];
    // const dataPage = { Skip: this.Skip, Take: this.Take };
    this.loginService.getAllSeasonList(this.filter, this.Name).subscribe(
      (res: any) => {
        if (res && res.List && res.List.length) {
          this.gridData = res.List;
          this.pageInfo.TotalRecord = res.TotalRecords;
          this.pageInfo.PageSize = this.filter.Take;
        } else {
          this.gridData = [];
          this.pageInfo.TotalRecord = 0;
          this.pageInfo = Object.assign({}, this.pageInfo);
        }
        this.loaderservice.hide();
      },
      error => {
      this.loaderservice.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  action(event: any) {
    if (event.ColumnName === 'Edit') {
      if (event.value.IsCompleted) {
        this.alert.error('Can not edit completed Season');
        return;
      }

      // this.data.changeMessage4(this.candidates.List[event.index]);
      const cUrl = document.location.href;
      let urlaparam = '';
      if (cUrl.includes('super')) {
        urlaparam = 'super-admin';
      } else {
        urlaparam = 'admin';
      }
      const url = '/' + urlaparam + `/add-season?uid=${event.value.Id}`;
      this.router.navigateByUrl(url);
    }

    if (event.action === 'IsCompleted') {
      this.validateComplete(event.value);
    }
    if (event.action === 'IsActive') {
      this.validateIsActive(event.value);
    }
  }
  validateIsActive(value: any): any {
    if (value.IsActive) {
      this.alert.error('You can not Deactivate the season');
      return;
    }
    value.IsActive ? this.DeactivateToggle = 'DeActivate' : this.DeactivateToggle = 'Activate';
    this.toggleActiveId = value.Id;
    $('#deleteModelDeactivate').modal('show');

  }


  validateComplete(value: any): any {
    if (value.IsCompleted) {
      this.alert.error('You Can Not Mark Season As Incomplete.');
      return;
    } else {
      this.completeSeasonId = value.Id;
      $('#seasonCompleteModel').modal('show');
    }
  }

  pageInfoChange(event: PaginationModel) {
    this.pageInfo.CurrentPage = event.CurrentPage;
    this.pageInfo.PageSize = event.PageSize;
    this.filter.OrderBy = event.SortBy;
    this.filter.Skip = (event.CurrentPage - 1) * event.PageSize;
    this.filter.Order = event.SortOrder;
    this.filter.Take = event.PageSize;

    this.getallSeasons();
  }

  filterChange(event: any) {
    if (event.length > 0) {
    event ? this.Name = event[0].Value : this.Name = null;
    } else { this.Name = ''; }
    this.getallSeasons();
  }
  actiDeactiConfirm() {
    if (this.DeactivateToggle = 'Activate') {
      this.activateSeason();
    } else if (this.DeactivateToggle = 'DeActivate') {
      //  this.deactivateConfirm();
    }
  }


  completeConfirm() {
    this.loginService.completeSeason(this.completeSeasonId).subscribe(res => {
      this.alert.success('Successfully Completed Season');
      $('#seasonCompleteModel').modal('hide');
      this.getallSeasons();
    }, err => {
      this.alert.error(Helper.getMessageFromError(err));
      $('#seasonCompleteModel').modal('hide');
    });
  }


  activateSeason() {
    this.loginService.activateSeason(this.toggleActiveId).subscribe(
      (res: any) => {
        this.alert.success(res, true);
        this.getallSeasons();
        $('#deleteModelDeactivate').modal('hide');

      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  SendData(data) {
    if (!data.valid) {
      this.submittedError = true;
    }
    if (data.valid) {
        this.addSeason(data);
    }
  }
  addSeason(data){
    this.loaderservice.show();
      this.loginService.addseason(data.value).subscribe(
        (res: any) => {
          this.alert.success("Season is added", true);
          this.rForm.reset();
          this.loaderservice.hide();
          this.getallSeasons();
        },
        error => {
          this.loaderservice.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
  }


  // deactivate season
  deactivate() {
    // this.id
    $('#deleteModelDeactivate').modal('show');
  }
  // to update
  updateSeason(i, item) {
    $('#editSeason').modal('show');
  }
  dateConvert(date) {
    const ConDate = this.datePipe.transform(date, 'MM-dd-yyyy');
    return ConDate;
  }
  priviousPage() { }



}
