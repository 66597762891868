import { Component, OnInit } from '@angular/core';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as myapi from '../../../service/baseLink';
import {Location} from '@angular/common';
import { Helper } from '../../../_model/helper';
import { LoaderService } from '../../../service/loader.service';
declare var $: any;
@Component({
  selector: 'app-add-centre',
  templateUrl: './add-centre.component.html',
  styleUrls: ['./add-centre.component.css']
})
export class AddCentreComponent implements OnInit {
  public loader = false;
  public mainloader = false;
  public phoneViewButton = false;
  public phoneViewInput = false;
  inputView = false;
  verifyEmail = '';
  otp: '';
  rForm: FormGroup;
  id = '';
  uid = '';
  list: any;
  buttonView = false;
  examcenternumber = '';
  totalYearsCount = [];
  state: any; // getting list of state
  Country: any; // getting list of country
  Region: any; // getting list of region
  GetSeason: any;
  countryId: any; // sore the coutry id value
  regionId: any; // store region id value
  stateId: any; // store state id
  public submittedError = false;

  dropdownCountry: boolean;
  dropdownState: boolean;

  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.storeYears('2015', new Date().getFullYear());
    this.getAllSeason();
    this.getAllRegion();
    this.rForm = this.formBuilder.group({
      Id: [''],
      Name: ['', Validators.required],
      CountryId: ['', Validators.required],
      RegionId: ['', Validators.required],
      StateId: ['', Validators.required],
      Address: ['', Validators.required],
      MaximumCapacity: ['', Validators.required]
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.id = params['id'];
      this.uid = params['uid'];
    });
    // this will execute only when updating the form.
    if (this.uid !== undefined) {
      this.loginService.getExamCentreWithId(this.uid).subscribe(
        (res: any) => {
           this.list = res;
          // this.countryId = this.list.CountryDetail.id;
           this.regionId = this.list.RegionId;
           this.stateId = this.list.StateId;
           this.getAllCountry(this.regionId);
          this.getAllState(this.stateId);
         this.setDataToFrontEnd();
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }

  }

  // comming from edit or view from authority
  setDataToFrontEnd() {
      this.getAllRegion();    
    this.rForm.get('RegionId').setValue(this.list.RegionId);
    this.rForm.get('Id').setValue(this.list.Id);
    this.rForm.get('Name').setValue(this.list.Name);
    this.rForm.get('StateId').setValue(this.list.StateId);
    this.rForm.get('Address').setValue(this.list.Address);
    this.rForm.get('MaximumCapacity').setValue(this.list.MaximumCapacity);
    this.rForm.get('CountryId').setValue(this.list.CountryId);
  }

  // api hit for add exam centre
  SendData(data) {
    if (!data.valid) {
      this.submittedError = true;
    }
    if (data.valid) {
      if (this.uid) {
        this.updateExamCentre(data.value);
      } else {
      this.addExamCenter(data.value);

      }
    }
  }
  addExamCenter(data) {
    if (isNaN(Number(data.MaximumCapacity))) {
      this.alert.error('Invalid capacity');
      return false;
    }
     this.loaderService.show();
     this.loginService.addExamCentre(data).subscribe(
       (res: any) => {
         this.alert.success('Successful submitted', true);
         this.mainloader = false;
         this.submittedError = false;
         this.rForm.reset();
         this.loaderService.hide();
         this.navigate();
       },
       error => {
         this.loaderService.hide();
         this.alert.error(Helper.getMessageFromError(error), true);
       }
     );
  }
  navigate(): any {
    const cUrl = document.location.href;
        let urlaparam = '';
        if (cUrl.includes('super')) {
          urlaparam = 'super-admin';
        } else {
          urlaparam = 'admin';
        }
        const url = '/' + urlaparam + `/centre-list`;
         this.router.navigateByUrl(url);
  }

  updateExamCentre(data) {
    if (isNaN(Number(data.MaximumCapacity))) {
      this.alert.error('Invalid capacity');
      return false;
    }
    this.loaderService.show();
    this.loginService.updateExamCentre(data).subscribe(
      (res: any) => {
        this.alert.success('Successfully updated', true);
         this.submittedError = false;
         this.rForm.reset();
           if (this.uid !== undefined) {
            this._location.back();
           }
         this.loaderService.hide();
      },
      error => {
        this.loaderService.hide();
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  storeYears(startYear, currentYear) {
    for (let i = startYear; i <= currentYear; i++) {
      this.totalYearsCount.push(i);
    }
  }

  getAllSeason() {
    this.loginService.getSeason().subscribe(
      (res: any) => {
        this.GetSeason = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // country selected event listener
  countrySelected(e) {
    this.countryId = e.target.value;
    this.getAllState(this.countryId);
  }
  // region selected event listener
  regionSelected(e) {
    this.regionId = e.target.value;
    this.getAllCountry(this.regionId);
  }

  // state list
  getAllState(countryId) {
    this.loginService.getallState(countryId).subscribe(
      (res: any) => {
        this.state = res;
        if (this.uid !== undefined) {
          this.rForm.get('StateId').setValue(this.list.StateId);
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  // country list
  getAllCountry(regionId) {
    this.loginService.getallCountry(regionId).subscribe(
      (res: any) => {
        this.Country = res;
        if (this.uid !== undefined) {
          this.rForm.get('CountryId').setValue(this.list.CountryId);
           this.getAllState(this.list.CountryId);
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  // region list
  getAllRegion() {
    this.loginService.getAllRegions().subscribe(
      (res: any) => {
        this.Region = res;
        if (this.id !== undefined) {
          this.rForm.get('RegionId').setValue(this.list.RegionDetail.id);
            this.getAllCountry(this.list.RegionDetail.id);
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }    
    return true;
  }
  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !/[\d\s]/.test(input);
  }
}
