import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import * as myapi from '../../../service/baseLink';
import { Helper } from '../../../_model/helper';

declare var $: any;
@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {
  class: any;
  year: any;
  ClassSet: any;
  YearSet: any;
  classDropdown: any = '';
  yearDropdown: any = '';
  PastQuestionData: any = '';
  response: any;
  header = false;
  noDocFound = false;
  isResultPublished = true;
  Country: any;
  countryId: any = 0;
  countryName: any = 'Select Country';
  regionId: any;  
  constructor(
    private router: Router,
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute,
  ) {
    // location.reload();
    let count = localStorage.getItem('country');
    count ? this.countryId = count : this.countryId = 0; 
    let name = localStorage.getItem('countryName');
    name ? this.countryName = name : this.countryName = 'Select Country'; 
    var loc: string = window.location.href;
    if(loc.includes("countryId=")){
      let id = loc.charAt(loc.indexOf("d=") + 2)
      localStorage.setItem("country",id);
    }
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        const url = document.location.pathname;
        if (
          // url === '/' ||
          url === '/guidelines' ||
          url === '/faq' ||
          url === '/contact-us' ||
          url === '/syllabus' ||
           url === '/result' ||
          url === '/top-school' ||
          url === '/result-report' ||
          url === '/top-candidates' ||
          url === '/registration' ||
          url === '/login' ||
          url === '/country' ||
          url === '/terms-n-conditions' ||
          url === '/registered-schools' ||
          url === '/news' ||
          url === '/school-rating-report'
        ) {
          this.header = true;
        } else {
          this.header = false;
        }
        this.activatedRoute.queryParams.subscribe((params: Params) => {
          if(params['cid']){            
          this.countryId = atob(params['cid'])}
          if(params['countryId']){            
            this.countryId = params['countryId']}
          if(params['rId']){            
              this.regionId = params['rId']}
        });
      }
    });

  }

  ngOnInit() {
    this.getAllCountries();
    this.getClass();
    // this.getYear();
    this.getResultStatus();
    $('#pastQuestion').on('hidden.bs.modal', function (e) {
      this.yearDropdown = '';
      this.classDropdown = '';
      this.noDocFound = false;
      this.PastQuestionData = '';
      $(this).removeData('bs.modal');
    });
  }
  close(){
    this.yearDropdown = '';
      this.classDropdown = '';
      this.noDocFound = false;
      this.PastQuestionData = null;
  }
  getAllCountries(): any {
    this.loginService.getAllCountryList().subscribe(res => {
      if(res){
        this.Country = res;
      }
    }, err => {})
  }
  method(event){    
  }

  getResultStatus() {
    this.loginService.getResultStatus(this.isResultPublished).subscribe((res: any) => {
      this.isResultPublished = res;
      localStorage.setItem('isResultPublished', this.isResultPublished + '');
    }, err => {});
  }

  pastquestion() {
    $('#pastQuestion').modal('show');
  }
  getClass() {
    // getting the class list from the server side
    this.loginService.getclass().subscribe(
      (res: any) => {
        this.class = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  getYear(classId) {
    this.loginService.getYear(classId).subscribe(
      (res: any) => {
        this.year = res;
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  setClass(event) {
    this.ClassSet = event.target.value;
    this.noDocFound = false;
    this.PastQuestionData = null;
    this.getYear(this.ClassSet);
  }
  setYear(event) {
    this.YearSet = event.target.value;
    this.noDocFound = false;
  }

  getPastQuestion() {
    this.PastQuestionData = null;
    if (this.ClassSet === undefined || this.ClassSet == null) {
      this.alert.error('Select the Class', true);
    } else if (this.YearSet === undefined || this.YearSet == null) {
      this.alert.error('Select the Year', true);
    } else {
      const url = `${this.YearSet}&clas=${this.ClassSet}&CountryId=${this.countryId}`;
      this.loginService.getPastQuestionList(url).subscribe(
        (res: any) => {
          if (res.length === 0) {
          this.noDocFound = true;
          } else {
            this.PastQuestionData = res;
            this.response = true;
          }

        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }

  downloadFile(i, filename) {
    const blob = new Blob([filename], { type: '.pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = myapi.BaseUrl + filename;
    window.open(a);
  }
  countrySelected(country){
    this.countryId = country.Value;
    this.countryName = country.DisplayName;
    localStorage.setItem('country', this.countryId);
    localStorage.setItem('countryName', this.countryName);
    this.router.navigate(['/'])
  }
  navigatetoCountryPage(){
    this.router.navigateByUrl(`country?cid=${btoa(this.countryId)}&rid=${this.regionId}`);
  }
  
}
