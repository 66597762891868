export class ExaminerFilterModel {
    constructor(){
        this.Skip = 0;
        this.Take = 10;
        this.Order = "Asc";
    }
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;   
}

export class ExaminerGridModel {
    Id: number;
      Name: string;
      RegistrationNumber: string;
      Class: string;
      ExamCenter: string;
      Score: number;
      Status: string;
      DOB: string;
}
export class ExaminerGridModelResult {
    constructor() {
        this.List = new Array<ExaminerGridModel>();
    }
    List: ExaminerGridModel[];
    TotalRecords: number;
}
