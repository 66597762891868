import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiServerService } from '../../service/api-server.service';
import { DataService } from '../../service/data.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {
  CountryId: any;
  countryId: any;
  Country: any;
  allData: any;
  mainHeading: any;
  firstStage: any;
  secondStage: any;
  youtubeLink: any;
  googlePlusLink: any;
  linkedinLink: any;
  facebookLink: any;
  twitterLink: any;
  firstPrize: any;
  secondPrize: any;
  thirdPrize: any;
  countryName: any;
  regionId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService
  ) { 
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.CountryId = atob(params['cid']);
      this.countryId = params['countryId'];
      this.regionId = params['rId'];
    });
   }

  ngOnInit() {    
    this.getAllCountries();
    this.getAllData(this.CountryId)
  }

  getAllData(id) {
    this.loginService.getAllEditedData(id).subscribe(
      (res:any)=>{
        this.allData = res;
        for (var _i = 0; _i < res.length; _i++) {
          var num = this.allData[_i];
          if (this.allData[_i].Name == "mainCountryWise") {
            this.mainHeading = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "firstStage") {
            this.firstStage = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "secondStage") {
            this.secondStage = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "youtubeLink") {
            this.youtubeLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "googlePlusLink") {
            this.googlePlusLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "linkedinLink") {
            this.linkedinLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "facebookLink") {
            this.facebookLink = this.allData[_i].Value;
          } else if (this.allData[_i].Name == "twitterLink") {
            this.twitterLink = this.allData[_i].Value;
          }
         else if (this.allData[_i].Name == "twitterLink") {
          this.twitterLink = this.allData[_i].Value;
        }
        else if (this.allData[_i].Name == "firstPrize") {
          this.firstPrize = this.allData[_i].Value;
        } else if (this.allData[_i].Name == "secondPrize") {
          this.secondPrize = this.allData[_i].Value;
        }
        else if (this.allData[_i].Name == "thirdPrize") {
          this.thirdPrize = this.allData[_i].Value;
        }
        }
      },
      error => {
       this.alert.error("something went wrong",true);
      }
    );
  }
  getAllCountries(): any {
    this.loginService.getAllCountryList().subscribe(res => {
      if(res){
        this.Country = res;
        var a = this.Country.filter(x=> x.Value == this.CountryId);
        this.countryName = a[0].DisplayName;
        if(!a){
          this.router.navigate(['']);
        }
      }
    }, err => {})
  }
  goToRegistrationPage() {    
    this.router.navigateByUrl(`registration?cid=${btoa(this.CountryId)}&rId=${this.regionId}`);
  }

}
