import { Component, OnInit } from "@angular/core";
import { ApiServerService } from "../../service/api-server.service";
import { DataService } from "../../service/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_service/alert.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { PasswordValidation } from './PasswordValidation';
import * as myapi from "./../../../app/service/baseLink";
import { Helper } from "../../_model/helper";
@Component({
  selector: "app-create-authority",
  templateUrl: "./create-authority.component.html",
  styleUrls: ["./create-authority.component.css"]
})
export class CreateAuthorityComponent implements OnInit {
  rForm: FormGroup;
  id: any = "";
  uid: any = "";
  list: any;
  submittedError: boolean = false;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params["id"];
      this.uid = params["uid"];
    });
    this.rForm = this.formBuilder.group({
      Id: [""],
      Email: [
        "",
        Validators.compose([Validators.required, Validators.pattern(myapi.emailPattern)])
      ],

      IsActive: ["", Validators.required],

      Role: ["", Validators.required],

      Password: [
        "",
        Validators.compose([Validators.required, , Validators.minLength(6)])
      ],
      ConfirmPassword: ['', Validators.compose([Validators.required])]},
      {
        validator: PasswordValidation.MatchPassword // your validation method
      });
    if (this.uid != undefined && this.id != undefined) {
      this.data.currentMessage5.subscribe(message => (this.list = message));
      this.rForm.get("Id").setValue(this.list.UserId);
      // this.rForm.get("FirstName").setValue(this.list.FirstName);
      // this.rForm.get("LastName").setValue(this.list.LastName);
      this.rForm.get("Email").setValue(this.list.Email);
      // this.rForm.get("Address").setValue(this.list.Address);
      this.rForm.get("IsActive").setValue(this.list.IsActive);
      // this.rForm.get("ContactNumber").setValue(this.list.ContactNumber);
      this.rForm.get("Role").setValue(this.list.UserType);
      this.rForm.get("Password").setValue(this.list.Password);
    }
  }

  SendData(data) {
    if (!data.valid) {
      // this.alert.error("fill the form correctly", true);
      this.submittedError = true;
    }

    if (data.valid) {
      this.loginService.addAuthority(data.value).subscribe(
        (res: any) => {

          this.alert.success("Created", true);
          this.router.navigate(['super-admin/authority-list']);
          // this.rForm.reset();
        },
        error => {
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
    }
  }
}
