import { Component, OnInit } from '@angular/core';
import { Helper } from '../../../_model/helper';
import { ApiServerService } from '../../../service/api-server.service';
import { DataService } from '../../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoaderService } from '../../../service/loader.service';
import { getActiveSeason } from '../../../service/baseLink';
import { PaginationModel, PropertyMetaModel, GridColumnType } from '../../../_model/pagination-model';
import { SeasonManagementModel, SeasonManagementFilterModel } from './seasonmanagementmodel';
declare var $;

@Component({
  selector: 'app-season-management',
  templateUrl: './season-management.component.html',
  styleUrls: ['./season-management.component.css'],
  providers: [DatePipe]

})
export class SeasonManagementComponent implements OnInit {
  minDate: Date; //season registration date start from initial today
  minRegEndDate: Date;
  gridData: SeasonManagementModel[];
  // public filter: SeasonManagementFilterModel = new SeasonManagementFilterModel();


  public displayColumns: PropertyMetaModel[];
  minExamDate: Date;
  heading: String = "Create"
  rForm: FormGroup;
  list: any;
  submittedError: boolean = false;
  uid: any;
  Region: any;
  public pageInfo = new PaginationModel();
  filter: SeasonManagementFilterModel = new SeasonManagementFilterModel();
  public regiondropdowndisabled: boolean =false;
  constructor(
    private loginService: ApiServerService,
    private data: DataService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private loaderService: LoaderService,

  ) {
    this.minDate = new Date(); //Season Registration Start Date
    this.minRegEndDate = new Date(); //season end date
    this.minExamDate = new Date(); //season exam date
    this.displayColumns = [
      {
        PropertyName: 'Season',
        ColumnName: 'Season Name',
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: 'Country',
        ColumnName: 'Country',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'RegistrationStartDate',
        ColumnName: 'Registration Start Date',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'RegistrationEndDate',
        ColumnName: 'Registration End Date',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'ExamTime',
        ColumnName: 'ExamTime',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'ExamDate',
        ColumnName: 'Exam Date',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'MinNoOfGirlsInMix',
        ColumnName: 'Min No Of Girls In Mix',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'MaxSelectedCandidate',
        ColumnName: 'Max Selected Candidate',
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: 'CountryId',
        ColumnName: 'Edit',
        PropertyType: GridColumnType.Icon,
        DisplayText: 'Edit',
        ClassName: 'mypointer'
      }
    ]
  }

  ngOnInit() {
    this.rForm = this.formBuilder.group({
      SeasonName: ["", Validators.required],
      Id: [""],
      RegionId: ["", Validators.required],
      RegistrationStartDate: ["", Validators.required],
      RegistrationEndDate: ["", Validators.required],
      ExamDate: ["", Validators.required],
      ExamTime: ['', Validators.required],
      MaxSelectedCandidate: ["", Validators.required],
      MinNoOfGirlsInMix: ["", Validators.required]
    });
    this.getActiveSeason();
    this.getAllRegion();
    this.getDataonAdding();

  }
  getActiveSeason(){
    this.loginService.getActiveSeason().subscribe(res => {
      res.SeasonName ? this.rForm.get('SeasonName').setValue(res.SeasonName) : this.alert.error("No Season Is Active Currently");
    }, err =>{})
  }
  getAllRegion() {
    this.loginService.getAllRegions().subscribe(
      (res: any) => {
        if(res){
          this.Region = res;
        }
      },
      error => {
        this.alert.error(Helper.getMessageFromError(error), true);
      }
    );
  }

  startDate(value: Date) {
    this.rForm.get("SeasonRegEndDate").setValue("");
    this.rForm.get("SeasonExamDate").setValue("");

    if (value) {
      this.minRegEndDate = value;
    }
  }
  endDate(value: Date) {
    if (value) {
      var date = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1)
      this.minExamDate = date;
    }
  }

  SendData(data) {
    if(data.value.Id){
      this.updateSeasonmanagement(data);

    }
    else {
      if (!data.valid) {
        this.submittedError = true;
      }
      else if(data.valid) {
        this.addSeasonmanagement(data);
      }
    }
  }

  updateSeasonmanagement(data) {
    data.value.ExamTime = data.value.ExamTime.toLocaleTimeString();
    this.loginService.updateSeasonmanagement(data.value).subscribe(res => {
      this.alert.success("Successfully Updated");
      this.getDataonAdding();
      this.rForm.reset();
      this.getActiveSeason();
    }, err => {})
  }
//   action(item: any) {
//     window.scroll(0,0);
//     if(item) {
//       this.loginService.getSeasonManagementDataById(item.Id).subscribe(res => {
//         if(res){
//           this.rForm.get('SeasonName').setValue(res.Season);
//           this.rForm.get('Id').setValue(res.Id);
//           this.rForm.get('RegionId').setValue(res.RegionId);
//           this.rForm.get('RegionId').disable();
//           this.rForm.get('RegistrationStartDate').setValue(new Date(res.RegistrationStartDate));
//           this.rForm.get('RegistrationEndDate').setValue(new Date(res.RegistrationEndDate));
//           this.rForm.get('ExamDate').setValue(new Date(res.ExamDate));
//           // this.rForm.get('ExamTime').setValue(new Date(res.ExamTime));
//           this.rForm.get('MaxSelectedCandidate').setValue(res.MaxSelectedCandidate);
//           this.rForm.get('MinNoOfGirlsInMix').setValue(res.MinNoOfGirlsInMix);
//           this.regiondropdowndisabled = true;
//           this.setExamTime(res.ExamTime);

//         }
//       }, err => {
//           this.alert.error(Helper.getMessageFromError(err), true);
//       })
//     }
// }

action(event){
  let navigationExtras: NavigationExtras = {
    queryParams: {
      'CountryId': `${event.value.CountryId}`,
      'Id': `${event.value.Id}`
  }
};
this.router.navigate(["admin/add-season"], navigationExtras);
}
  setExamTime(ExamTime: any): any {
    var s =ExamTime;
    var a = s.split(/[^0-9]/);
    var d=new Date (a[0],a[1]-1,a[2],a[3],a[4],a[5] );
    this.rForm.get('ExamTime').setValue(d);

  }
pageInfoChange(event: PaginationModel) {
  this.pageInfo.CurrentPage = event.CurrentPage;
  this.pageInfo.PageSize = event.PageSize;
}


  addSeasonmanagement(data){
    this.loaderService.show();
      this.loginService.addSeasonmanagement(data.value).subscribe(
        (res: any) => {
          this.alert.success("Info is added", true);
          this.rForm.reset();
          this.getDataonAdding();
          this.loaderService.hide();
        },
        error => {
          this.loaderService.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
  }
  getDataonAdding(): any {
    this.loginService.getallSeasonMAnagementData(this.filter).subscribe(res => {
      this.gridData = res.List;

    }, err => {
          this.alert.error(Helper.getMessageFromError(err), true);
    });
  }
  updateSeason(data){
    this.loaderService.show();
      this.loginService.updateSeason(data.value, this.uid).subscribe(
        (res: any) => {
          this.alert.success("Season is updated", true);
          this.rForm.reset();
          this.loaderService.hide();
        },
        error => {
          this.loaderService.hide();
          this.alert.error(Helper.getMessageFromError(error), true);
        }
      );
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !/[\d\s]/.test(input);
  }
}
