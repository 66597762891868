export class SchoolFilterModel {
    constructor() {
        this.Skip = 0;
        this.Take = 10;
        this.Order = 'Asc';
    }
    Id: number;
    Category: string;
    Type: string;
    Name: string;
    StateId: number;
    CountryId: number;
    Email: string;
    RegionId: number;
    Order: string;
    OrderBy: string;
    Skip: number;
    Take: number;
}

export class SchoolGridModel {
    Id: number;
    Category: string;
    Type: string;
    Name: string;
    PrimaryContactName: string;
    PrimaryContactNumber: number;
    PhoneNumber: number;
    Address: string;
    Email: string;
    State: string;
    Country: string;
    Region: string;
}

export class SchoolFilterResultModel {
    constructor() {
        this.List = new Array<SchoolGridModel>();
    }
    List: SchoolGridModel[];
    TotalRecords: number;
}
